import { Body1 } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import type {
    MedicationInfoFragment,
    ProductInfoFragment,
} from '../../graphql/generated/graphql';
import { useDosageForm } from '../../hooks/use-dosage-form';

type DrugDetailsProps = {
    product: ProductInfoFragment | MedicationInfoFragment;
    quantity?: number | null;
    daysSupply?: number | null;
};

const DrugDetails = ({ product, quantity, daysSupply }: DrugDetailsProps) => {
    const { t } = useTranslation();
    const isProduct = product.__typename === 'Product';
    const strength = `${isProduct ? product?.strengthText : ''}${
        product?.strengthUnit?.toLowerCase() ?? ''
    }`;
    const dosage = useDosageForm(
        isProduct ? product.formValue! : product.formCode!,
        quantity!
    );
    const daysSupplyText = daysSupply
        ? t('drugDetails.days', { count: daysSupply })
        : '';
    return (
        <Body1>
            {[strength, dosage, daysSupplyText].filter(Boolean).join(' • ')}
        </Body1>
    );
};

export default DrugDetails;
