import { Heading1, HorizontalDivider, Stack } from '@phx/design-system';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { MedicineCabinetLoader } from '../../components/cabinet/MedicineCabinetLoader';
import { ShoppingHeader } from '../../components/cabinet/shopping-header/ShoppingHeader';
import { ContactUsWidget } from '../../components/common/ContactUsWidget';
import { DrugSearchBar } from '../../components/drug-search/DrugSearchBar';
import { BenefitCards } from '../../components/home/BenefitCards';
import { CareCircleCard } from '../../components/home/CareCircleCard';
import { HomeMedCabCard } from '../../components/home/HomeMedCabCard';
import { MarketingCards } from '../../components/home/MarketingCards';
import { usePatientContext } from '../../hooks/use-patient-context';
import { getAbsoluteRoute } from '../../util';

const HomeRoute = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { patient, primaryPatientId } = usePatientContext();

    const handleDrugSearchFocus = useCallback(() => {
        navigate(getAbsoluteRoute('drug.search.root'));
    }, [navigate]);

    return (
        <Stack gap="lg">
            <ShoppingHeader>
                <Heading1>
                    {t('home.welcomeUser', { name: patient?.firstName })}
                </Heading1>
            </ShoppingHeader>
            <DrugSearchBar
                label={t('drugSearch.searchLabel')}
                autoFocus={false}
                onFocus={handleDrugSearchFocus}
            />
            <MarketingCards />
            <MedicineCabinetLoader
                viewDependentPrescriptions
                patientId={primaryPatientId}
                Component={HomeMedCabCard}
            />
            <BenefitCards />
            <CareCircleCard />
            <HorizontalDivider />
            <ContactUsWidget />
        </Stack>
    );
};

export default HomeRoute;
