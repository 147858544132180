import { jsx as _jsx } from "react/jsx-runtime";
import { useTelemetryContext } from '@phx/instrumentation/react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { createContext, useCallback, useEffect, useMemo, useState, } from 'react';
import { useFlagOverrides } from './use-flag-overrides';
export const FeatureFlagContext = createContext({
    overriddenFlags: {},
    setFlagOverride: (_flagState) => { },
    clearFlagOverrides: () => { },
    updateProviderContext: (_newContext) => { },
});
const FeatureFlagContextProvider = ({ children }) => {
    const client = useLDClient();
    const [isClientReady, setIsClientReady] = useState(false);
    const { telemetryInstance } = useTelemetryContext();
    const { logError } = telemetryInstance;
    useEffect(() => {
        if (client) {
            setIsClientReady(true);
        }
    }, [client]);
    const updateProviderContext = useCallback(async (newContext) => {
        if (!client || !isClientReady) {
            logError({
                source: 'FeatureFlagProvider',
                exception: new Error('LD client not instantiated'),
            });
            return;
        }
        try {
            await client.identify(newContext);
        }
        catch (e) {
            logError({
                source: 'FeatureFlagProvider',
                exception: e,
            });
            return;
        }
    }, [client, isClientReady, logError]);
    const { clearFlagOverride, clearFlagOverrides, overriddenFlags, setFlagOverride, } = useFlagOverrides();
    const values = useMemo(() => ({
        clearFlagOverride,
        clearFlagOverrides,
        overriddenFlags,
        setFlagOverride,
        updateProviderContext,
    }), [
        clearFlagOverride,
        clearFlagOverrides,
        overriddenFlags,
        setFlagOverride,
        updateProviderContext,
    ]);
    return (_jsx(FeatureFlagContext.Provider, { value: values, children: children }));
};
export const FeatureFlagProvider = ({ provider, children, }) => {
    const Provider = provider;
    return (_jsx(Provider, { children: _jsx(FeatureFlagContextProvider, { children: children }) }));
};
