import { readMedicationDescription } from './medication-description/read-medication-description';
import { readMedicationStrengthDisplayText } from './read-medication-strength-display-text';

export const medicationFields = {
    description: {
        read: readMedicationDescription,
    },
    strengthDisplayText: {
        read: readMedicationStrengthDisplayText,
    },
};
