import { Box, FullBleed, Image, Stack } from '@phx/design-system';
import { matchPath } from 'react-router-dom';

import { useFlags } from '../../hooks/use-flags';
import { useLoading } from '../../hooks/use-loading';
import { getAbsoluteRoute } from '../../util';

export const CobrandedFooter = () => {
    const { cobrandingEnabled } = useFlags();

    const { isLoading } = useLoading();

    const hiddenPaths: Parameters<typeof getAbsoluteRoute>[0][] = [
        'root',
        'home',
        'profile.root',
        'drug.search.root',
        'cabinet.root',
        'plan.summary',
        'cabinet.history',
        'claims.history',
        'profile.benefit',
    ];

    const isOnHiddenRoute = hiddenPaths.some((path) => {
        return matchPath(
            { path: getAbsoluteRoute(path), end: true },
            window.location.pathname
        );
    });

    if (!cobrandingEnabled || isOnHiddenRoute || isLoading) return null;

    return (
        <Stack mt="auto">
            <Box pt="xs">
                <FullBleed spacing="inline" color="body">
                    <Image
                        h="40px"
                        w="auto"
                        ml="auto"
                        mr="auto"
                        src="/static/img/myprescryptive-logo.png"
                    />
                </FullBleed>
            </Box>
        </Stack>
    );
};
