import {
    Avatar,
    Body1,
    Body2,
    Box,
    Flex,
    IconButton,
    IconMoreVert,
} from '@phx/design-system';
import { getInitials } from '@phx/design-system/framework';
import { useTranslation } from 'react-i18next';

import type { IndividualCareRecipient } from '../../../graphql/generated/graphql';

export type CareRecipientProps = {
    member: IndividualCareRecipient;
};

export const CareRecipient = ({ member }: CareRecipientProps) => {
    const { t } = useTranslation();

    return (
        <Flex gap="xs">
            <Avatar initials={getInitials(member.name)} />
            <Box flex={1}>
                <Body1 bold>{member.name}</Body1>
                <Body2>
                    {member.fullAccess
                        ? t('careCircle.access.full')
                        : t('careCircle.access.limited')}
                </Body2>
            </Box>
            <IconButton size="sm" color="gray" Icon={IconMoreVert} />
        </Flex>
    );
};
