import type { PriorAuthorizationInfoFragment } from '../graphql/generated/graphql';
import type { BasicPrescriberOrderFragment } from '../util/cabinet/prescriber-orders';

import { useFlags } from './use-flags';

export const usePriorAuthorizationDetails = (
    order: BasicPrescriberOrderFragment | PriorAuthorizationInfoFragment
) => {
    const { nonDrxPriorAuthorizationEnabled } = useFlags();

    const isPriorAuthorization =
        order.__typename === 'PriorAuthorization' &&
        nonDrxPriorAuthorizationEnabled;

    const priorAuthorizationDetails = isPriorAuthorization ? order : null;

    return {
        isPriorAuthorization,
        priorAuthorizationDetails,
    };
};
