import { Body1, Box } from '@phx/design-system';
import { Trans } from 'react-i18next';

export type GenericSwapPricingDescProps = {
    brandName: string;
    genericName: string;
    plural?: boolean;
};

export const GenericSwapPricingDesc = ({
    brandName,
    genericName,
    plural = false,
}: GenericSwapPricingDescProps) => {
    if (plural) {
        return (
            <Box>
                <Trans
                    i18nKey="genericSwap.pricing.showingAForBPlural"
                    values={{
                        brandName,
                        genericName,
                    }}
                    components={{
                        // @ts-expect-error -- i18next handles passing the `children` prop into the component
                        1: <Body1 bold inline />,
                    }}
                />
            </Box>
        );
    }

    return (
        <Box>
            <Trans
                i18nKey="genericSwap.pricing.showingAForB"
                values={{
                    brandName,
                    genericName,
                }}
                components={{
                    // @ts-expect-error -- i18next handles passing the `children` prop into the component
                    1: <Body1 bold inline />,
                }}
            />
        </Box>
    );
};
