import { Select } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import type { MedicationPackageInfoFragment } from '../../graphql/generated/graphql';

export const MedicationPackageSelect = ({
    packageOptions,
    selectedPackage,
    setSelectedPackage,
}: {
    packageOptions: MedicationPackageInfoFragment[];
    selectedPackage: string;
    setSelectedPackage: (value: string) => void;
}) => {
    const { t } = useTranslation(['myphx']);

    const data = packageOptions.map((option) => ({
        value: option.id,
        label: option.packageFormatDescription,
    }));

    return (
        <Select
            label={t('drugConfig.packagingForm')}
            value={selectedPackage}
            onChange={(value) => value && setSelectedPackage(value)}
            disabled={packageOptions.length <= 1}
            placeholder="-"
            data={data}
        />
    );
};
