import { Alert } from '@phx/design-system';

import { useAcceptInviteContext } from '../../../hooks/care-circle/use-accept-invite-context';

export const AcceptInviteError = () => {
    const { error } = useAcceptInviteContext();

    if (!error) {
        return null;
    }

    return <Alert severity="warning">{error}</Alert>;
};
