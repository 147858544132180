import { useAuthContext } from '@phx/auth/client';
import {
    Alert,
    Anchor,
    Body1,
    DividedText,
    Flex,
    IconButton,
    IconControlledSubstance,
    IconEdit,
    IconInfo,
    Stack,
    Subtitle1,
    TextButton,
} from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { useTelemetryContext } from '@phx/instrumentation/react';
import { t } from 'i18next';
import type React from 'react';
import { type PropsWithChildren, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import {
    ControlledSubstanceCode,
    type CoverageFlags,
    type MedicationInfoFragment,
} from '../../graphql/generated/graphql';
import { getAbsoluteRoute, getTotalQuantityDescriptionV2 } from '../../util';
import { ConditionalComponent } from '../utility/ConditionalComponent';

import { DrugCoverageAlerts } from './drug-coverage-alerts/DrugCoverageAlerts';
import { NotCoveredModal } from './drug-coverage-alerts/NotCoveredModal';
import { PARequiredModal } from './drug-coverage-alerts/PARequiredModal';

type DrugFamilyHeaderviewProps = {
    topNode?: React.ReactNode;
    medication: MedicationInfoFragment;
    coverageFlags?: CoverageFlags;
    editClicked?: () => void;
    controlledSubstanceCode?: ControlledSubstanceCode | null;
};

export const DrugFamilyHeaderV2 = ({
    topNode,
    medication,
    coverageFlags,
    editClicked,
    children,
    controlledSubstanceCode,
}: PropsWithChildren<DrugFamilyHeaderviewProps>) => {
    const { signIn, isAuthenticated } = useAuthContext();
    const { telemetryInstance } = useTelemetryContext();
    const [isPARequiredModalOpen, { toggle: togglePARequiredModal }] =
        useDisclosure(false);
    const [isNotCoveredModalOpen, { toggle: toggleNotCoveredModal }] =
        useDisclosure(false);
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    const chosenQuantity =
        searchParams.get('qInput') ?? searchParams.get('quantity');

    const quantity = chosenQuantity
        ? Number(chosenQuantity)
        : (medication?.defaultQuantity ?? 30);

    if (!medication) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    const {
        formCode,
        packageCode,
        packageQuantity,
        packageSize,
        packageSizeUnit,
        strengthDisplayText,
    } = medication;

    const formPackage = {
        formCode,
        packageCode,
        packageQuantity,
        packageSize,
        packageSizeUnit,
    };

    const quantityDesc = getTotalQuantityDescriptionV2(quantity, formPackage);

    const login = useCallback(
        () => signIn(location.pathname),
        [signIn, location.pathname]
    );

    const handleEditClick = () => {
        //Log telemetry event
        telemetryInstance.logEvent({
            name: 'Drugconfig open edit drug',
            source: 'DrugFamilyHeaderV2',
        });
        editClicked && editClicked();
    };

    const displayCoverageAlert =
        isAuthenticated &&
        coverageFlags &&
        (coverageFlags.requiresPriorAuthorization || !coverageFlags.isCovered);

    return (
        <>
            <Stack gap="xs" data-testid="drug-family-header">
                <ConditionalComponent
                    condition={!!controlledSubstanceCode && !isAuthenticated}
                >
                    <Alert IconOverride={IconInfo} severity="warning">
                        <Trans i18nKey="drugFamilyHeader.alert.controlledSubstances">
                            <Body1 color="warning">
                                <Anchor color="warning" onClick={login}>
                                    Sign in to view prices
                                </Anchor>{' '}
                                for controlled substances.
                            </Body1>
                        </Trans>
                    </Alert>
                </ConditionalComponent>
                {topNode && topNode}
                <Stack gap="xxs">
                    <Flex gap="xxs">
                        <Subtitle1 data-testid="search-product-details-header">
                            {medication.name}
                        </Subtitle1>
                        <ConditionalComponent
                            condition={!!controlledSubstanceCode}
                        >
                            <IconButton
                                Icon={IconControlledSubstance}
                                withStyles={false}
                                onClick={() =>
                                    navigate(
                                        getAbsoluteRoute(
                                            'helpCenter.medications'
                                        )
                                    )
                                }
                            />
                        </ConditionalComponent>
                    </Flex>

                    {displayCoverageAlert ? (
                        <ConditionalComponent
                            condition={!!displayCoverageAlert}
                        >
                            <Flex>
                                <DividedText
                                    entries={[
                                        strengthDisplayText ?? '',
                                        quantityDesc?.longText ?? '',
                                    ]}
                                />
                            </Flex>
                            <Flex
                                justify="space-between"
                                align="center"
                                gap="md"
                            >
                                <DrugCoverageAlerts
                                    coverageFlags={coverageFlags}
                                    toggleNotCoveredModal={
                                        toggleNotCoveredModal
                                    }
                                    togglePARequiredModal={
                                        togglePARequiredModal
                                    }
                                />
                                {editClicked && (
                                    <TextButton
                                        data-testid="edit-drug-configuration"
                                        size="sm"
                                        onClick={handleEditClick}
                                        LeftIcon={IconEdit}
                                    >
                                        {t('drugConfig.Edit')}
                                    </TextButton>
                                )}
                            </Flex>
                        </ConditionalComponent>
                    ) : (
                        <Flex justify="space-between" align="center" gap="md">
                            <DividedText
                                entries={[
                                    strengthDisplayText ?? '',
                                    quantityDesc?.longText ?? '',
                                ]}
                            />
                            {editClicked && (
                                <TextButton
                                    data-testid="edit-drug-configuration"
                                    size="sm"
                                    onClick={handleEditClick}
                                    LeftIcon={IconEdit}
                                >
                                    {t('drugConfig.Edit')}
                                </TextButton>
                            )}
                        </Flex>
                    )}
                </Stack>
                {children}
            </Stack>
            <PARequiredModal
                opened={isPARequiredModalOpen}
                close={togglePARequiredModal}
            />
            <NotCoveredModal
                opened={isNotCoveredModalOpen}
                close={toggleNotCoveredModal}
            />
        </>
    );
};
