import { Body1, Modal } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

export type CareCircleInfoDialogProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const CareCircleInfoDialog = ({
    isOpen,
    onClose,
}: CareCircleInfoDialogProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            opened={isOpen}
            onClose={onClose}
            title={t('careCircle.infoDialog.title')}
        >
            <Body1>{t('careCircle.infoDialog.content')}</Body1>
        </Modal>
    );
};
