import { Body1, Modal, Stack } from '@phx/design-system';
import { CoverageCard } from '@phx/myphx-lib';
import { useTranslation } from 'react-i18next';

import { useCoverageCardData } from '../../../hooks/use-coverage-card-data';
import { CobrandedLogo } from '../../cobranding/CobrandedLogo';

// Note: This is similar to the ShareWithPharmacy component but approved prior auth prescriptions don't have an offer to pass in to grab coverage details.
export const PriorAuthCoverageCard = ({
    onClose,
    opened,
}: {
    onClose: () => void;
    opened: boolean;
}) => {
    const { t } = useTranslation();
    const coverages = useCoverageCardData();

    return (
        <Modal
            title={t('orderConfirmation.coverageCard.subHeader')}
            opened={opened}
            onClose={onClose}
        >
            <Stack gap="sm">
                <Body1>
                    {t(
                        'orderConfirmation.coverageCard.mailOrderPhoneDescription'
                    )}
                </Body1>
                <CoverageCard
                    {...coverages[0]}
                    partnerLogo={<CobrandedLogo />}
                />
            </Stack>
        </Modal>
    );
};
