import { ClaimHistory } from '../../components/claim-history/ClaimHistory';
import { GetClaimHistoryDocument } from '../../graphql/generated/graphql';
import { useFlags } from '../../hooks/use-flags';
import { usePatientContext } from '../../hooks/use-patient-context';
import { QueryLoader } from '../../loaders';

import ClaimHistoryV2Route from './ClaimHistoryV2Route';

const ClaimHistoryRoute = () => {
    const { memberClaimHistoryEnabled } = useFlags();
    if (memberClaimHistoryEnabled) {
        return <ClaimHistoryV2Route />;
    }
    const { primaryPatientId: patientId } = usePatientContext();
    const Loader = QueryLoader<typeof GetClaimHistoryDocument>;

    return (
        <Loader
            query={GetClaimHistoryDocument}
            variables={{ patientId }}
            queryOptions={{ errorPolicy: 'all' }}
            component={ClaimHistory}
        />
    );
};

export default ClaimHistoryRoute;
