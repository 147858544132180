import { Accordion, Body1, Flex, IconNumbers } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import { getFragment } from '../../../graphql';
import type {
    CoverageFlags,
    DigitallyReceivedPrescriberOrderInfoFragment,
    PriorAuthorizationInfoFragment,
} from '../../../graphql/generated/graphql';
import { useFlags } from '../../../hooks/use-flags';
import { PrescriberOrderDetails } from '../../../routes/orders/details/PrescriberOrderDetails';
import { MissingBenefitsCard } from '../../prior-authorization/components/MissingBenefitsCard';

import { PatientProductDetailsHeader } from './PatientProductDetailsHeader';

export type PatientProductDetailsProps = {
    patientName?: string;
    productDescription?: string;
    quantity?: number | null;
    prescriberOrder?: DigitallyReceivedPrescriberOrderInfoFragment;
    priorAuth?: PriorAuthorizationInfoFragment;
    coverageFlags?: CoverageFlags;
    togglePARequiredModal?: () => void;
    toggleNotCoveredModal?: () => void;
};

export const PatientProductDetails = ({
    patientName,
    productDescription,
    quantity,
    prescriberOrder,
    priorAuth,
    togglePARequiredModal,
    toggleNotCoveredModal,
}: PatientProductDetailsProps) => {
    const { nonDrxPriorAuthorizationEnabled } = useFlags();
    const { t } = useTranslation();
    const header = (
        <PatientProductDetailsHeader
            patientName={patientName}
            productDescription={productDescription}
            quantity={quantity}
            prescriberOrder={prescriberOrder}
            togglePARequiredModal={togglePARequiredModal}
            toggleNotCoveredModal={toggleNotCoveredModal}
        />
    );

    const ndc = getFragment(priorAuth?.medication)?.id;

    const getAccordionItems = () => {
        if (prescriberOrder) {
            return [
                {
                    header,
                    key: 'patient-product-details',
                    children: (
                        <PrescriberOrderDetails
                            prescriberOrder={prescriberOrder}
                        />
                    ),
                },
            ];
        }

        // Note: Only the NDC is displayed for now, but when more details are to be displayed for non-drx PA's it should be broken out into it's own component similar to PrescriberOrderDetails above
        if (nonDrxPriorAuthorizationEnabled && ndc) {
            return [
                {
                    header,
                    key: 'prior-auth-product-details',
                    children: (
                        <Flex gap="xs">
                            <IconNumbers color="secondary" />
                            <Body1>{t('order.ndc', { ndc })}</Body1>
                        </Flex>
                    ),
                },
            ];
        }

        return [];
    };

    return (
        <>
            <Accordion items={getAccordionItems()} />
            {prescriberOrder ? null : nonDrxPriorAuthorizationEnabled ? (
                <MissingBenefitsCard />
            ) : null}
        </>
    );
};
