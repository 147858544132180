import {
    Alert,
    Anchor,
    Body1,
    Box,
    Button,
    Heading1,
    Heading2,
    HorizontalDivider,
    IconCheckCircle,
    Stack,
    Subtitle2,
} from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { type FragmentsOf, getFragment } from '../../graphql';
import {
    type DigitallyReceivedPrescriberOrderInfoFragment,
    type GetOfferQuery,
    PrescriberOrderStatus,
} from '../../graphql/generated/graphql';
import { getAbsoluteRoute } from '../../util';
import { ShoppingHeader } from '../cabinet/shopping-header/ShoppingHeader';
import { ContactUsWidget } from '../common/ContactUsWidget';
import { LocationInfo } from '../common/LocationInfo';
import { Pricing } from '../common/pricing';
import { Prescriber } from '../prescriber/Prescriber';
import { DAWModal } from '../preview-details/components/DAWModal';
import { ConditionalComponent } from '../utility/ConditionalComponent';

import { NextSteps } from './components/NextSteps';
import { ShareWithPharmacy } from './components/ShareWithPharmacy';
import { YourPrescription } from './components/YourPrescription';

export type OrderConfirmationProps = {
    offer: NonNullable<FragmentsOf<GetOfferQuery['offer']>>;
    prescriberOrder: DigitallyReceivedPrescriberOrderInfoFragment;
};

export const OrderConfirmation = ({
    offer,
    prescriberOrder,
}: OrderConfirmationProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [isDAWModalOpen, { close: closeDAWModal, toggle: toggleDAWModal }] =
        useDisclosure(false);

    const brand = searchParams.get('brand');
    const generic = searchParams.get('generic');
    const genericName = searchParams.get('genericName');
    const brandName = searchParams.get('brandName');

    const fillLocation = getFragment(offer.pharmacyProvider);
    const controlledSubstanceCode = getFragment(
        prescriberOrder.authorizedMedication
    ).controlledSubstanceCode;
    const isPending = prescriberOrder.status === PrescriberOrderStatus.Pending;

    // Temporary until VELA integration
    const isInstantRouting = false;

    const isDAW = prescriberOrder.dispenseAsWritten;

    return (
        <>
            <Stack gap="lg" pb="md">
                {controlledSubstanceCode && isPending && !isInstantRouting ? (
                    <Stack gap="sm">
                        <Heading1>
                            {t('orderConfirmation.inProgress.header')}
                        </Heading1>
                        <Body1>
                            {t('orderConfirmation.inProgress.description1')}
                        </Body1>
                        <Subtitle2>
                            {t('orderConfirmation.inProgress.description2')}
                        </Subtitle2>
                    </Stack>
                ) : (
                    <ShoppingHeader>
                        <Stack gap="md">
                            <Stack align="center" gap="xxs">
                                <IconCheckCircle
                                    filled
                                    variant="rounded"
                                    width="2em"
                                    height="2em"
                                    color="success"
                                />
                                <Heading1>
                                    {t('orderConfirmation.header')}
                                </Heading1>
                            </Stack>
                            <NextSteps location={fillLocation} />
                            {isDAW && (
                                <Alert severity="info">
                                    <Stack gap="xxs">
                                        <Subtitle2>
                                            {t(
                                                'genericSwap.orderConfirmation.daw.heading'
                                            )}
                                        </Subtitle2>
                                        <Box>
                                            <Trans i18nKey="genericSwap.orderConfirmation.daw.body">
                                                <Body1>
                                                    {
                                                        'Generic versions can often be cheaper than brand name drugs. However, the healthcare provider flagged this prescription as '
                                                    }
                                                    <Anchor
                                                        onClick={toggleDAWModal}
                                                    >
                                                        Dispense As Written
                                                    </Anchor>
                                                    {
                                                        ' for the brand. If the generic is preferred, contact the provider or pharmacy.'
                                                    }
                                                </Body1>
                                            </Trans>
                                        </Box>
                                    </Stack>
                                </Alert>
                            )}
                            {brand && !isDAW && (
                                <Alert severity="info">
                                    <Body1>
                                        {t(
                                            'genericSwap.orderConfirmation.askForBrand'
                                        )}
                                    </Body1>
                                </Alert>
                            )}
                            {generic && (
                                <Alert severity="info">
                                    <Body1>
                                        {t(
                                            'genericSwap.orderConfirmation.askForGeneric'
                                        )}
                                    </Body1>
                                </Alert>
                            )}
                        </Stack>
                    </ShoppingHeader>
                )}

                <HorizontalDivider variant="full" />

                <Stack gap="xxl">
                    <Stack gap="sm">
                        <Heading2>
                            {t('orderConfirmation.pharmacy.subHeader')}
                        </Heading2>
                        <ConditionalComponent condition={!!fillLocation}>
                            <LocationInfo location={fillLocation} />
                        </ConditionalComponent>
                    </Stack>
                    <ConditionalComponent
                        condition={
                            isInstantRouting ||
                            !isPending ||
                            !controlledSubstanceCode
                        }
                    >
                        <ShareWithPharmacy
                            offer={offer}
                            prescriberOrder={prescriberOrder}
                        />
                    </ConditionalComponent>

                    <YourPrescription
                        controlledSubstanceCode={controlledSubstanceCode}
                        isInstantRouting={isInstantRouting}
                        offer={offer}
                        prescriberOrder={prescriberOrder}
                    />

                    <Pricing
                        offer={offer}
                        genericSwap={
                            generic
                                ? {
                                      genericName: genericName!,
                                      brandName: brandName!,
                                  }
                                : null
                        }
                    />

                    <Stack gap="sm">
                        <Heading2>
                            {t('orderConfirmation.prescriber.subHeader')}
                        </Heading2>
                        <Prescriber
                            prescriber={getFragment(prescriberOrder.prescriber)}
                        />
                    </Stack>
                </Stack>

                <HorizontalDivider variant="full" />

                <ContactUsWidget />

                <Button
                    onClick={() => {
                        navigate(getAbsoluteRoute('home'));
                    }}
                >
                    {t('common.button.returnToHome')}
                </Button>
            </Stack>
            <DAWModal close={closeDAWModal} isOpen={isDAWModalOpen} />
        </>
    );
};

export default OrderConfirmation;
