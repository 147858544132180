import { AuthorizedOutlet } from '@phx/auth/client';
import { Outlet, type RouteObject } from 'react-router-dom';

import { ProfileProvider } from '../../contexts/ProfileProvider';
import routes from '../app-routes';

import NonDrxPriorAuthorizationOrderDetailsRoute from './NonDrxPriorAuthorizationOrderDetailsRoute';
import NonDrxPriorAuthorizationStatusRoute from './NonDrxPriorAuthorizationStatus';

const priorAuthorizationRoutes: RouteObject[] = [
    {
        path: routes.priorAuthorization.root,
        element: <AuthorizedOutlet />,
        children: [
            {
                path: routes.priorAuthorization.priorAuthorizationId.root,
                element: (
                    <ProfileProvider>
                        <Outlet />
                    </ProfileProvider>
                ),
                children: [
                    {
                        path: routes.priorAuthorization.priorAuthorizationId
                            .details,
                        element: <NonDrxPriorAuthorizationOrderDetailsRoute />,
                    },
                    {
                        path: routes.priorAuthorization.priorAuthorizationId
                            .status,
                        element: <NonDrxPriorAuthorizationStatusRoute />,
                    },
                ],
            },
        ],
    },
];

export default priorAuthorizationRoutes;
