import { Button } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import { useAcceptInviteContext } from '../../hooks/care-circle/use-accept-invite-context';
import { useRouteChange } from '../../hooks/use-route-change';

export type AcceptInviteButtonProps = {
    disabled: boolean;
};

export const AcceptInviteButton = ({ disabled }: AcceptInviteButtonProps) => {
    const { t } = useTranslation();
    const { accept, clearError, isAccepting, isDeclining } =
        useAcceptInviteContext();

    useRouteChange(clearError);

    return (
        <Button
            loading={isAccepting || isDeclining}
            disabled={disabled || isAccepting || isDeclining}
            onClick={accept}
        >
            {t('careCircle.invite.respond.buttons.accept')}
        </Button>
    );
};
