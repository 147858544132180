import {
    Body1,
    Body2,
    Box,
    Flex,
    HorizontalDivider,
    IconChevronRight,
    OutlinedCard,
    Stack,
    Subtitle1,
    Subtitle2,
    TextButton,
} from '@phx/design-system';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ConditionalComponent } from '../../../components/utility/ConditionalComponent';
import { getFragment } from '../../../graphql';
import { getLocationInfoFragment } from '../../../graphql/client/helpers/get-location-fragment';
import {
    type DigitallyReceivedPrescriberOrderInfoFragment,
    PrescriberOrderStatus,
    type PriceQuoteInfoFragment,
} from '../../../graphql/generated/graphql';
import {
    formatToLocalizedDate,
    getAbsoluteRoute,
    titleCase,
} from '../../../util';

export type LastOrderActivityProps = {
    priceQuote?: PriceQuoteInfoFragment;
    prescriberOrder: DigitallyReceivedPrescriberOrderInfoFragment;
};

export const LastOrderActivity = ({
    priceQuote,
    prescriberOrder,
}: LastOrderActivityProps) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const pharmacy = priceQuote
        ? getLocationInfoFragment(priceQuote?.location)
        : undefined;
    const pharmacyName = getFragment(pharmacy)?.name;

    const hasOrderDate = !!prescriberOrder.latestSelectedPriceQuoteTimestamp;

    const navigateToOrderConfirmation = useCallback(() => {
        navigate(
            getAbsoluteRoute('orders.prescriberOrderId.confirmation', {
                prescriberOrderId: prescriberOrder.id,
            })
        );
    }, [navigate]);

    const isPrescriberOrderCancelled =
        prescriberOrder.status === PrescriberOrderStatus.Cancelled;

    // Temporary until VELA integration
    const isRouted = undefined;

    const isInProgress =
        prescriberOrder.status === PrescriberOrderStatus.Pending;

    const lastActivityStatus =
        isRouted === false && isInProgress
            ? t('orderDetails.inProgress')
            : isPrescriberOrderCancelled
              ? t('orderDetails.cancelled')
              : t('orderDetails.sentToPharmacy');
    const sentToPharmacyName = isPrescriberOrderCancelled
        ? t('orderDetails.cancelledDescription')
        : pharmacyName
          ? titleCase(pharmacyName)
          : null;

    return (
        <ConditionalComponent
            condition={
                !!prescriberOrder.latestSelectedOffer ||
                !!prescriberOrder.latestSelectedPriceQuote
            }
        >
            <OutlinedCard>
                <Stack gap="xs">
                    <Subtitle1>{t('orderDetails.lastActivity')}</Subtitle1>
                    <HorizontalDivider />
                    <Stack gap="xs">
                        <Stack gap="xxxs">
                            <Flex gap="xs" justify="space-between">
                                <Subtitle2>{lastActivityStatus}</Subtitle2>
                                {hasOrderDate ? (
                                    <Body2>
                                        {formatToLocalizedDate(
                                            new Date(
                                                prescriberOrder.latestSelectedPriceQuoteTimestamp!
                                            ),
                                            { dateStyle: 'medium' },
                                            i18n.language
                                        )}
                                    </Body2>
                                ) : null}
                            </Flex>
                            <Body1>{sentToPharmacyName}</Body1>
                        </Stack>
                        <ConditionalComponent
                            condition={!isPrescriberOrderCancelled}
                        >
                            <Box>
                                <TextButton
                                    RightIcon={IconChevronRight}
                                    onClick={navigateToOrderConfirmation}
                                >
                                    {isRouted === false && isInProgress
                                        ? t('orderDetails.viewSendRequest')
                                        : t('orderDetails.viewOrder')}
                                </TextButton>
                            </Box>
                        </ConditionalComponent>
                    </Stack>
                </Stack>
            </OutlinedCard>
        </ConditionalComponent>
    );
};
