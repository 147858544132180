import i18n from '../../../i18n';

export type SortableMember = {
    relationship: {
        relationshipType: string;
    };
    patient: {
        dateOfBirth: string;
    };
};

export function displayCoveredMembers<T extends SortableMember>(
    members: T[]
): T[] {
    return [...members].sort((a, b) => {
        const primary = i18n.t('member.relationship.primary');
        const self = i18n.t('member.relationship.self');
        const spouse = i18n.t('member.relationship.spouse');
        const priorityMap = {
            [primary]: 0,
            [self]: 1,
            [spouse]: 2,
        };

        const priorityA =
            priorityMap[a.relationship.relationshipType] ?? Infinity;
        const priorityB =
            priorityMap[b.relationship.relationshipType] ?? Infinity;

        if (priorityA !== priorityB) {
            return priorityA - priorityB;
        }

        return (
            new Date(b.patient.dateOfBirth).getTime() -
            new Date(a.patient.dateOfBirth).getTime()
        );
    });
}
