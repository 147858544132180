import type { FieldReadFunction } from '@apollo/client';
import { getPackageRelevance } from '@phx/rx-common';

import {
    getComplexPackageFormatDescription,
    getSimplePackageFormatDescription,
} from '../../../util';

export const readPackageFormatDescriptionV2: FieldReadFunction = (
    _,
    { readField }
) => {
    const formCode = readField<string>('formCode') ?? '';
    const packageCode = readField<string>('packageCode') ?? '';
    const packageSize = readField<number>('packageSize') ?? 1;
    const packageSizeUnit = readField<string>('packageSizeUnit') ?? '';
    const packageQuantity = readField<number>('packageQuantity') ?? 1;

    const packageRelevance = getPackageRelevance({
        formCode,
        packageCode,
        packageSizeUnit,
        packageQuantity,
    });

    // //if we could not determine relevance then we cannot show options
    if (!packageRelevance) {
        return '-';
    }
    const { isSimpleQuantity, isPackageRelevant } = packageRelevance;

    const packageInfo = {
        formCode,
        packageCode,
        packageQuantity,
        packageSize,
        packageSizeUnit,
    };

    if (isSimpleQuantity) {
        return getSimplePackageFormatDescription(
            packageInfo,
            isPackageRelevant
        );
    }

    return getComplexPackageFormatDescription(packageInfo);
};
