import { useQuery } from '@apollo/client';
import {
    Body1,
    DotIndicator,
    Flex,
    Heading1,
    IconButton,
    IconFilter,
    IconInfo,
    Modal,
    Stack,
    useConditionalBackOverride,
} from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getFragment } from '../../graphql';
import {
    GetPatientPriorAuthorizationsDocument,
    GetPrescriberOrdersDocument,
    PrescriberOrderStatus,
} from '../../graphql/generated/graphql';
import { usePatientContext } from '../../hooks/use-patient-context';
import {
    type PatientSelectorOption,
    VIEW_ALL,
    usePatientSelectorOptions,
} from '../../hooks/use-patient-selector-options';
import { Loader } from '../../loaders';
import { isBasicPrescriberOrder } from '../../util/orders';

import { HistorySearch } from './components/HistorySearch';
import { PatientSelector } from './components/PatientSelector';

export const CabinetHistoryRoute = () => {
    const { t } = useTranslation();
    const { scopedPatientId, primaryPatientId, includeDependents } =
        usePatientContext();
    const [patientIdFilter, setPatientIdFilter] = useState<string>('');
    const [isModalOpen, { open: openModal, close: closeModal }] =
        useDisclosure();

    const [isSelectorOpen, { open: openSelector, close: closeSelector }] =
        useDisclosure();

    const {
        loading: patientsLoading,
        options,
        getOptionLabel,
    } = usePatientSelectorOptions();

    useConditionalBackOverride(closeSelector, isSelectorOpen);

    const { data, loading } = useQuery(GetPrescriberOrdersDocument, {
        errorPolicy: 'all',
        variables: {
            patientId: primaryPatientId,
            input: {
                statusFilter: [
                    PrescriberOrderStatus.Filled,
                    PrescriberOrderStatus.SentToPharmacy,
                    PrescriberOrderStatus.Cancelled,
                ],
            },
        },
    });

    const {
        data: priorAuthorizationsData,
        loading: priorAuthorizationsLoading,
    } = useQuery(GetPatientPriorAuthorizationsDocument, {
        errorPolicy: 'all',
        variables: {
            patientId: primaryPatientId,
            input: {
                nonDrx: true,
            },
        },
    });

    useEffect(() => {
        const initialSelectedId = includeDependents
            ? VIEW_ALL
            : scopedPatientId || primaryPatientId;
        setPatientIdFilter(initialSelectedId);
    }, []);

    const prescriptions = useMemo(() => {
        if (loading || !data) return [];
        if (priorAuthorizationsLoading) return [];

        const primaryPrescriptions =
            data?.patient?.prescriberOrdersConnection.edges.map((edge) =>
                getFragment(getFragment(edge.node))
            ) ?? [];

        const consentedPrescriptions =
            data?.patient?.consentedPatientsConnection.edges
                .map((edge) => {
                    return edge.node.prescriberOrdersConnection.edges.map(
                        (edge) => getFragment(getFragment(edge.node))
                    );
                })
                .flat() ?? [];

        const nonDrxPriorAuthorizations =
            getFragment(
                priorAuthorizationsData?.patient?.priorAuthorizationsConnection
            )
                ?.edges.map((edge) => getFragment(edge.node))
                .flat() ?? [];

        if (!nonDrxPriorAuthorizations) return [];

        const merged = [
            ...primaryPrescriptions,
            ...consentedPrescriptions,
            ...nonDrxPriorAuthorizations,
        ].filter((prescription) => {
            if (
                prescription.__typename !==
                    'DigitallyReceivedPrescriberOrder' &&
                prescription.__typename !== 'PriorAuthorization'
            ) {
                return false;
            }

            const priorAuthorization = isBasicPrescriberOrder(prescription)
                ? prescription.applicablePriorAuthorization
                : prescription;

            return (
                !priorAuthorization ||
                prescription.status !== PrescriberOrderStatus.Pending
            );
        });

        if (patientIdFilter === VIEW_ALL) return merged;

        return merged.filter((p) =>
            isBasicPrescriberOrder(p)
                ? p.patient.id
                : p.patientId === (patientIdFilter || scopedPatientId)
        );
    }, [
        data,
        loading,
        patientIdFilter,
        priorAuthorizationsData,
        priorAuthorizationsLoading,
    ]);

    const patientOptions: PatientSelectorOption[] = useMemo(() => {
        const viewAll: PatientSelectorOption = {
            id: VIEW_ALL,
            name: t('patientSelector.viewAll'),
        };

        const merged = [viewAll, ...options];

        return merged.map((o) => ({ ...o, name: getOptionLabel(o) }));
    }, [patientsLoading, options, getOptionLabel]);

    if (loading || patientsLoading) return <Loader />;

    if (isSelectorOpen) {
        return (
            <PatientSelector
                onChange={(o) => setPatientIdFilter(o.id)}
                initialSelectedId={patientIdFilter}
                onClose={closeSelector}
                loading={patientsLoading}
                options={patientOptions}
            />
        );
    }

    return (
        <>
            <Modal
                opened={isModalOpen}
                title={t('cabinet.history.infoModalHeader')}
                onClose={closeModal}
            >
                <Body1>{t('cabinet.history.infoModalBody')}</Body1>
            </Modal>
            <Stack gap="sm" style={{ height: '100%' }}>
                <Flex align="center" justify="space-between">
                    <Flex align="center" gap="xxs">
                        <Heading1>{t('cabinet.history.heading')}</Heading1>
                        <IconButton
                            Icon={IconInfo}
                            onClick={openModal}
                            alt="history-info-button"
                            withStyles={false}
                        />
                    </Flex>
                    <Flex align="center">
                        <DotIndicator
                            color="error"
                            disabled={patientIdFilter === VIEW_ALL}
                            offset={5}
                        >
                            <IconButton
                                Icon={IconFilter}
                                size="sm"
                                onClick={openSelector}
                                alt="cabinet-history-patient-selector-btn"
                            />
                        </DotIndicator>
                    </Flex>
                </Flex>
                <HistorySearch prescriptions={prescriptions} />
            </Stack>
        </>
    );
};
