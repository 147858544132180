import { Accordion, Anchor, Body1, Stack, Subtitle2 } from '@phx/design-system';
import { Trans, useTranslation } from 'react-i18next';

import externalRoutes from '../../../../external-routes';
import { formatToLocalizedDate } from '../../../../util';
import { ConditionalComponent } from '../../../utility/ConditionalComponent';

export const DeniedDescription = ({
    trackingNumber,
    appealBeforeDate,
    rationale,
}: {
    trackingNumber?: string | null;
    appealBeforeDate?: string | null;
    rationale?: string | null;
}) => {
    const { t } = useTranslation();

    return (
        <Stack gap="sm">
            <Stack gap="xxs">
                <Body1>
                    {t('priorAuthorization.statusPage.denied.description')}
                </Body1>
                {rationale ? (
                    <Stack gap="xxxs">
                        <Subtitle2>
                            {t(
                                'priorAuthorization.statusPage.denied.rationale'
                            )}
                        </Subtitle2>
                        <Body1>{rationale}</Body1>
                    </Stack>
                ) : null}
                <Accordion
                    items={[
                        {
                            header: t(
                                'priorAuthorization.statusPage.denied.otherOptions'
                            ),
                            key: 'denied-other-options',
                            children: (
                                <Stack>
                                    <Stack gap="sm">
                                        <Body1>
                                            {t(
                                                'priorAuthorization.statusPage.denied.stepOne'
                                            )}
                                        </Body1>
                                        {appealBeforeDate ? (
                                            <Body1>
                                                <Trans
                                                    i18nKey={
                                                        'priorAuthorization.statusPage.denied.stepTwo'
                                                    }
                                                    components={{
                                                        1: (
                                                            <Anchor
                                                                target="_blank"
                                                                href={
                                                                    externalRoutes.appealRequestForm
                                                                }
                                                            />
                                                        ),
                                                    }}
                                                    values={{
                                                        date: formatToLocalizedDate(
                                                            new Date(
                                                                appealBeforeDate
                                                            ),
                                                            {
                                                                dateStyle:
                                                                    'medium',
                                                            }
                                                        ),
                                                    }}
                                                />
                                            </Body1>
                                        ) : null}
                                        <Body1>
                                            {t(
                                                'priorAuthorization.statusPage.denied.stepThree',
                                                {
                                                    stepNumber: appealBeforeDate
                                                        ? '3'
                                                        : '2',
                                                }
                                            )}
                                        </Body1>
                                        <Stack gap={0}>
                                            <ConditionalComponent
                                                condition={!!trackingNumber}
                                            >
                                                <Subtitle2>
                                                    {t(
                                                        'priorAuthorization.statusPage.denied.tracking',
                                                        { trackingNumber }
                                                    )}
                                                </Subtitle2>
                                            </ConditionalComponent>
                                            <Subtitle2>
                                                {t(
                                                    'priorAuthorization.statusPage.denied.fax'
                                                )}
                                            </Subtitle2>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            ),
                        },
                    ]}
                />
                <Body1>{t('priorAuth.decisionInfo.footer')}</Body1>
            </Stack>
        </Stack>
    );
};
