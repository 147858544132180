import { useContext } from 'react';

import {
    LoadingContext,
    type LoadingContextType,
} from '../contexts/LoadingProvider';

export const useLoading = (): LoadingContextType => {
    const ctx = useContext(LoadingContext);

    if (ctx === null) {
        throw new Error(
            'useLoading() cannot be called outside a LoadingProvider'
        );
    }

    return ctx;
};
