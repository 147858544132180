import {
    Flex,
    FullBleed,
    Heading1,
    RoundedSkeleton,
    Stack,
    useNavBar,
} from '@phx/design-system';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CareCircleInfoButton } from '../../components/care-circle/CareCircleInfoButton';
import { CareCircleNotification } from '../../components/care-circle/CareCircleNotification';
import { ConditionalComponent } from '../../components/utility/ConditionalComponent';
import { useCareCircle } from '../../hooks/use-care-circle';
import { getAbsoluteRoute } from '../../util';

import { CareCircle } from './components/CareCircle';

export const CareCircleRoute = () => {
    const { t } = useTranslation();
    const { network, loading } = useCareCircle();
    const navigate = useNavigate();
    const { setBackOverrideState } = useNavBar();

    const backToHome = useCallback(() => {
        navigate(getAbsoluteRoute('home'));
    }, []);

    useEffect(() => {
        setBackOverrideState({ overrideFn: backToHome });

        return () => {
            setBackOverrideState({ overrideFn: null });
        };
    }, []);

    return (
        <Stack gap="lg" flex={1}>
            <Flex gap="xs" align="center">
                <Heading1>{t('careCircle.heading')}</Heading1>
                <CareCircleInfoButton />
            </Flex>
            <FullBleed flex>
                <Stack gap="lg">
                    <ConditionalComponent condition={loading && !network}>
                        <RoundedSkeleton visible height="150px" width="100%" />
                        <RoundedSkeleton visible height="150px" width="100%" />
                    </ConditionalComponent>
                    <ConditionalComponent condition={!loading}>
                        <CareCircle network={network} />
                    </ConditionalComponent>
                    <CareCircleNotification />
                </Stack>
            </FullBleed>
        </Stack>
    );
};
