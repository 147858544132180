import { Heading2, Stack } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import type {
    ChainPharmacyInfoFragment,
    MailOrderProviderInfoFragment,
    PharmacyInfoFragment,
    PharmacyProviderInfoFragment,
} from '../../../graphql/generated/graphql';
import { titleCase } from '../../../util';

import { DeliveryStep } from './DeliveryStep';
import { MailOrderNextSteps } from './MailOrderNextSteps';
import type { LocationInfo } from './shared';

type NextStepsProps = {
    location:
        | ChainPharmacyInfoFragment
        | PharmacyInfoFragment
        | PharmacyProviderInfoFragment
        | MailOrderProviderInfoFragment;
};

export const NextSteps = ({ location }: NextStepsProps) => {
    const { t } = useTranslation();

    let locationInfo: LocationInfo = {
        name: titleCase(location?.name),
        phone: location?.phoneNumber,
    };

    if (location.__typename === 'MailOrderProvider') {
        locationInfo = {
            ...locationInfo,
            url: location?.url,
            mailOrder: true,
        };
    }

    return (
        <Stack gap="sm">
            <Heading2>{t('orderConfirmation.subHeader')}</Heading2>
            {locationInfo.mailOrder ? (
                <MailOrderNextSteps locationInfo={locationInfo} />
            ) : (
                <DeliveryStep
                    stepTexts={[
                        t('orderConfirmation.pickUp.stepOne'),
                        t('orderConfirmation.pickUp.stepTwo'),
                    ]}
                />
            )}
        </Stack>
    );
};
