import { ConditionalComponent } from '../../../components/utility/ConditionalComponent';
import type { PatientNetwork } from '../../../graphql/generated/graphql';

import { CareCircleFooter } from './CareCircleFooter';
import { CaregiverList } from './CaregiverList';
import { CareRecipientList } from './CareRecipientList';
import { EmptyCareCircle } from './EmptyCareCircle';

export type CareCircleProps = {
    network?: PatientNetwork | null;
};

export const CareCircle = ({ network }: CareCircleProps) => {
    if (!network) {
        return null;
    }

    const isNetworkEmpty =
        network.grants.length === 0 && network.pendingGrants.length === 0;

    return (
        <>
            <ConditionalComponent condition={isNetworkEmpty}>
                <EmptyCareCircle />
            </ConditionalComponent>
            <ConditionalComponent condition={!isNetworkEmpty}>
                <CaregiverList network={network!} />
                <CareRecipientList network={network!} />
                <CareCircleFooter />
            </ConditionalComponent>
        </>
    );
};
