import type { PlanMemberListItemProps } from '../../components/plan-members/PlanMemberListItem';

export const sortPlanMemberList = (
    dep1: PlanMemberListItemProps,
    dep2: PlanMemberListItemProps
): number => {
    // Primary account holder should always be first
    if (
        dep1.relationshipType === 'PRIMARY' &&
        dep2.relationshipType !== 'PRIMARY'
    ) {
        return -1;
    }

    if (
        dep2.relationshipType === 'PRIMARY' &&
        dep1.relationshipType !== 'PRIMARY'
    ) {
        return 1;
    }

    // Account holder's spouse should be above all other dependents
    if (
        dep1.relationshipType === 'SPOUSE' &&
        dep2.relationshipType !== 'SPOUSE'
    ) {
        return -1;
    }

    if (
        dep2.relationshipType === 'SPOUSE' &&
        dep1.relationshipType !== 'SPOUSE'
    ) {
        return 1;
    }

    // Otherwise, fall back to sorting by DOB
    return dep1.patient.dateOfBirth.localeCompare(dep2.patient.dateOfBirth);
};
