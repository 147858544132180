import { useQuery } from '@apollo/client';
import { Navigate, useParams } from 'react-router-dom';

import { Loader } from '../../../components/common/Loader';
import { PreviewDetails } from '../../../components/preview-details/PreviewDetails';
import { getFragment } from '../../../graphql';
import { GetProviderOffersDocument } from '../../../graphql/generated/graphql';
import { getAbsoluteRoute } from '../../../util';

const PreviewDetailsRoute = () => {
    const { providerOffersId, offerId, prescriptionId } = useParams();

    const { data, loading } = useQuery(GetProviderOffersDocument, {
        variables: { id: providerOffersId ?? '' },
        skip: !providerOffersId || !prescriptionId,
    });

    if (!providerOffersId || !prescriptionId) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    if (loading) {
        return <Loader />;
    }

    const providerOffer = getFragment(data?.providerOffers);
    const selectedOffer = getFragment(
        providerOffer?.offers.find((x) => getFragment(x).id === offerId)
    );
    const prescriberOrder = getFragment(providerOffer?.prescriberOrder);

    if (!selectedOffer || !prescriberOrder) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    return (
        <PreviewDetails
            offer={selectedOffer}
            prescriberOrder={prescriberOrder}
        />
    );
};

export default PreviewDetailsRoute;
