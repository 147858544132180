import { Anchor, Body1, Heading2, Stack } from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { PharmacyInfo } from '@phx/myphx-lib';
import { Trans, useTranslation } from 'react-i18next';

import type {
    MailOrderProviderInfoFragment,
    PharmacyProviderInfoFragment,
} from '../../../graphql/generated/graphql';
import { usePatientContext } from '../../../hooks/use-patient-context';

import { PriorAuthCoverageCard } from './PriorAuthCoverageCard';

type PriorAuthLocationDetailsProps = {
    location: PharmacyProviderInfoFragment | MailOrderProviderInfoFragment;
    isApproved: boolean;
    isNonDrxSpecialtyPriorAuth: boolean;
};

export const PriorAuthLocationDetails = ({
    location,
    isApproved,
    isNonDrxSpecialtyPriorAuth,
}: PriorAuthLocationDetailsProps) => {
    const { t } = useTranslation();
    const [openedInsuranceinformation, { toggle: toggleInsuranceinformation }] =
        useDisclosure();
    const { preferences } = usePatientContext();
    const { favoritePharmacies } = preferences;
    const { checkIsFavorite, toggleFavorite } = favoritePharmacies;

    return (
        <Stack gap="sm">
            <Stack gap="xxs">
                <Heading2>{t('previewDetails.heading')}</Heading2>
                {isNonDrxSpecialtyPriorAuth ? (
                    <Body1>
                        <Trans
                            i18nKey="priorAuthorization.statusPage.sentPrescription.nonDrx"
                            components={{
                                1: (
                                    <Anchor
                                        onClick={toggleInsuranceinformation}
                                    />
                                ),
                            }}
                        />
                    </Body1>
                ) : (
                    <Body1>
                        <Trans
                            i18nKey="priorAuthorization.statusPage.sentPrescription.dRx"
                            components={{
                                1: (
                                    <Anchor
                                        onClick={toggleInsuranceinformation}
                                    />
                                ),
                            }}
                        />
                    </Body1>
                )}
                <PriorAuthCoverageCard
                    opened={openedInsuranceinformation}
                    onClose={toggleInsuranceinformation}
                />
            </Stack>
            <PharmacyInfo
                name={location.name}
                phone={location.phoneNumber}
                favorite={checkIsFavorite(location.id)}
                toggleFavorite={() => toggleFavorite(location.id)}
                shippingTime={
                    location.__typename === 'MailOrderProvider' && isApproved
                        ? location.deliveryTimeDisplay
                        : undefined
                }
            />
        </Stack>
    );
};
