import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PriorAuthorizationStatus } from '../graphql/generated/graphql';

export const usePriorAuthorizationStatus = (status?: string) => {
    const { t } = useTranslation();

    const { priorAuthLinkText, statusText } = useMemo(() => {
        let actionText = '';
        let paStatusText = '';

        switch (status) {
            case PriorAuthorizationStatus.New:
                actionText = t('priorAuthorization.status.trackStatus');
                paStatusText = t('priorAuthorization.status.New');
                break;
            case PriorAuthorizationStatus.Received:
                actionText = t('priorAuthorization.status.trackStatus');
                paStatusText = t('priorAuthorization.status.Received');
                break;
            case PriorAuthorizationStatus.InReview:
                actionText = t('priorAuthorization.status.trackStatus');
                paStatusText = t('priorAuthorization.status.InReview');
                break;
            case PriorAuthorizationStatus.Redetermination:
                actionText = t('priorAuthorization.status.trackStatus');
                paStatusText = t('priorAuthorization.status.Redetermination');
                break;
            case PriorAuthorizationStatus.Appealed:
                actionText = t('priorAuthorization.status.trackStatus');
                paStatusText = t('priorAuthorization.status.Appealed');
                break;
            case PriorAuthorizationStatus.Approved:
                actionText = t('priorAuthorization.status.viewDetails');
                paStatusText = t('priorAuthorization.status.Approved');
                break;
            case PriorAuthorizationStatus.Denied:
                actionText = t('priorAuthorization.status.viewDetails');
                paStatusText = t('priorAuthorization.status.Denied');
                break;
            case PriorAuthorizationStatus.AppealApproved:
                actionText = t('priorAuthorization.status.viewDetails');
                paStatusText = t('priorAuthorization.status.AppealApproved');
                break;
            case PriorAuthorizationStatus.AppealDenied:
                actionText = t('priorAuthorization.status.viewDetails');
                paStatusText = t('priorAuthorization.status.AppealDenied');
                break;
            case PriorAuthorizationStatus.Cancelled:
                actionText = t('priorAuthorization.status.viewDetails');
                paStatusText = t('priorAuthorization.status.Cancelled');
                break;
            default:
                actionText = '';
                paStatusText = '';
        }

        return { priorAuthLinkText: actionText, statusText: paStatusText };
    }, [status]);

    return { priorAuthLinkText, statusText };
};
