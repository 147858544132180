import { isNetworkRequestSettled, useMutation, useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { VerifyPatient } from '../../../components/cabinet/prescription/verify-patient/VerifyPatient';
import { getFragment } from '../../../graphql';
import {
    ClaimPrescriberOrderForPatientDocument,
    GetPrescriberOrdersByPatientDocument,
    GetPrescriberOrdersDocument,
    GetUnclaimedPrescriberOrderDocument,
    type PatientInfoFragment,
    PrescriberOrderStatus,
} from '../../../graphql/generated/graphql';
import { usePatientContext } from '../../../hooks/use-patient-context';
import { QueryLoader } from '../../../loaders';
import { getAbsoluteRoute } from '../../../util';
import routes from '../../app-routes';

export const VerifyPatientRoute = () => {
    const { prescriptionId } = useParams();
    if (prescriptionId === undefined) {
        // TODO: Better validation
        throw Error('Prescription ID is required');
    }

    const [executeFollowUpQueries, setExecuteFollowUpQueries] = useState(false);
    const { primaryPatientId, scopedPatientId } = usePatientContext();
    const navigate = useNavigate();

    /*
     * These cannot be executed under the mutation's `refetchQueries` option,
     * because the latter does not allow you to specify an error policy on the
     * queries to be refetched. In other places across our app where these are
     * called, we use `errorPolicy: 'all'`, but we should not be passing that to
     * the mutation, because there's no concept of a partial success when
     * verifying a patient.
     */
    const {
        called: prescriberOrdersCalled,
        networkStatus: prescriberOrdersNetworkStatus,
    } = useQuery(GetPrescriberOrdersDocument, {
        skip: !executeFollowUpQueries,
        errorPolicy: 'all',
        variables: { patientId: primaryPatientId },
    });
    const {
        called: prescriberOrdersByPatientCalled,
        networkStatus: prescriberOrdersByPatientNetworkStatus,
    } = useQuery(GetPrescriberOrdersByPatientDocument, {
        skip: !executeFollowUpQueries,
        errorPolicy: 'all',
        variables: {
            patientId: scopedPatientId,
            input: { statusFilter: [PrescriberOrderStatus.New] },
        },
    });

    /*
     * Apollo has deprecated all callbacks like `onCompleted` in `useQuery` and
     * `useLazyQuery`, instead recommending that applications track the network
     * status. This effect keeps track of both queries, waiting for them to
     * complete before redirecting the user.
     *
     * See:
     * https://github.com/apollographql/apollo-client/issues/12352
     * https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose
     */
    useEffect(() => {
        const prescriberOrdersComplete = isNetworkRequestSettled(
            prescriberOrdersNetworkStatus
        );
        const prescriberOrdersByPatientComplete = isNetworkRequestSettled(
            prescriberOrdersByPatientNetworkStatus
        );

        if (!prescriberOrdersCalled || !prescriberOrdersByPatientCalled) {
            return;
        }

        if (prescriberOrdersComplete && prescriberOrdersByPatientComplete) {
            navigate(getAbsoluteRoute('cabinet.rxId.root', { prescriptionId }));
        }
    }, [
        navigate,
        prescriberOrdersByPatientCalled,
        prescriberOrdersByPatientNetworkStatus,
        prescriberOrdersCalled,
        prescriberOrdersNetworkStatus,
        prescriptionId,
    ]);

    const [ClaimPrescriberOrderForPatient] = useMutation(
        ClaimPrescriberOrderForPatientDocument
    );
    const handleClaimPrescription = useCallback(
        async (patient: PatientInfoFragment) => {
            const result = await ClaimPrescriberOrderForPatient({
                variables: {
                    patientId: patient.id,
                    prescriberOrderId: prescriptionId,
                },
            });

            if (result.data?.claimPrescriberOrderForPatient.success) {
                setExecuteFollowUpQueries(true);
            }
        },
        [
            ClaimPrescriberOrderForPatient,
            prescriptionId,
            setExecuteFollowUpQueries,
        ]
    );

    const Loader = QueryLoader<typeof GetUnclaimedPrescriberOrderDocument>;

    // @TODO: replace with `GetPrescriberOrderV2`
    return (
        <Loader
            query={GetUnclaimedPrescriberOrderDocument}
            variables={{ prescriberOrderId: prescriptionId }}
            render={(data) => {
                if (data.unclaimedPrescriberOrder) {
                    const prescriberOrder = getFragment(
                        data.unclaimedPrescriberOrder
                    );
                    if (
                        !prescriberOrder.matchingPatientsConnection?.edges
                            .length
                    ) {
                        return (
                            <Navigate
                                to={getAbsoluteRoute('cabinet.rxId.verify', {
                                    prescriptionId,
                                })}
                            />
                        );
                    }
                    return (
                        <VerifyPatient
                            prescriberOrder={prescriberOrder}
                            claimPrescription={handleClaimPrescription}
                        />
                    );
                }
                return <Navigate to={routes.home} />;
            }}
        />
    );
};
