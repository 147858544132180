import { createBrowserRouter, redirect } from 'react-router-dom';

import { getAbsoluteRoute } from '../util';

import maintenanceRoot from './MaintenanceRoot';
import root from './Root';

// TODO: These are weird and we need to figure out how to get rid of these
// all this is doing is redirecting these to kebabe case routes
const redirects = {
    '/pharmacy/inNetwork': getAbsoluteRoute('pharmacy.inNetwork'),
    '/startsaving': getAbsoluteRoute('startSaving'),
};

export const router = createBrowserRouter([
    root,
    ...Object.entries(redirects).map(([oldPath, newPath]) => ({
        path: oldPath,
        loader: () => redirect(newPath),
    })),
]);

export const maintenanceRouter = createBrowserRouter([
    maintenanceRoot,
    ...Object.entries(redirects).map(([oldPath, newPath]) => ({
        path: oldPath,
        loader: () => redirect(newPath),
    })),
]);
