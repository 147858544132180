import {
    Avatar,
    Body1,
    Body2,
    Box,
    Flex,
    IconButton,
    IconMoreVert,
} from '@phx/design-system';
import { getInitials } from '@phx/design-system/framework';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PendingCareRecipientDropdown } from '../../../components/care-circle/PendingCareRecipientDropdown';
import type { PendingCareRecipientInvite } from '../../../graphql/generated/graphql';
import { getAbsoluteRoute } from '../../../util';

export type PendingCareRecipientProps = {
    invite: PendingCareRecipientInvite;
};

export const PendingCareRecipient = ({ invite }: PendingCareRecipientProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const respondToInvite = useCallback(() => {
        navigate(
            getAbsoluteRoute('careCircle.inviteById', { inviteId: invite.id })
        );
    }, [invite.id]);

    if (invite.isIncoming) {
        return (
            <Flex gap="xs">
                <Avatar initials={getInitials(invite.careRecipientName)} />
                <Box flex={1}>
                    <Body1 bold>{invite.careRecipientName}</Body1>
                    <Body2>{t('careCircle.access.invitationReceived')}</Body2>
                </Box>
                <IconButton
                    size="sm"
                    color="gray"
                    Icon={IconMoreVert}
                    onClick={respondToInvite}
                />
            </Flex>
        );
    }

    return (
        <Flex gap="xs">
            <Avatar initials={getInitials(invite.careRecipientName)} />
            <Box flex={1}>
                <Body1 bold>{invite.careRecipientName}</Body1>
                <Body2>{t('careCircle.access.requestSent')}</Body2>
            </Box>
            <PendingCareRecipientDropdown />
        </Flex>
    );
};
