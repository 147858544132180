// noinspection t,DuplicatedCode

import { useConditionalBackOverride } from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { useTelemetryContext } from '@phx/instrumentation/react';
import { useGeolocation } from '@phx/location-utils';
import { useRef, useState } from 'react';
import {
    Navigate,
    createSearchParams,
    useNavigate,
    useParams,
} from 'react-router-dom';

import { PatientProductDetailsV2 } from '../../../components/preview-details/components/PatientProductDetailsV2';
import { ProviderSelection } from '../../../components/provider-offers';
import { ControlledSubstanceModal } from '../../../components/provider-offers/modals';
import { getFragment, getFragments } from '../../../graphql';
import {
    ControlledSubstanceCode,
    GetPrescriptionOffersDocument,
    type GetPrescriptionOffersQuery,
    type MedicationInfoFragment,
    type ProviderOffersInfoFragment,
    ProviderOffersSortBy,
} from '../../../graphql/generated/graphql';
import { useNumericSearchParam } from '../../../hooks/use-numeric-search-param';
import { usePatientContext } from '../../../hooks/use-patient-context';
import { usePrescriptionSubstitution } from '../../../hooks/use-prescription-substitution';
import { type DataHandlerType, QueryLoader } from '../../../loaders';
import { getAbsoluteRoute } from '../../../util';
import { getFeaturedProviderOffers } from '../../../util/alternatives/get-featured-provider-offers';
import { getOffersByProviderId } from '../../../util/offers/get-offers-by-provider-id';
import {
    getPrimaryProvider,
    getProviderOffersByType,
} from '../../../util/provider-offers/provider-utils';

import { LeavePrescriptionWarningModal } from './LeavePrescriptionWarningModal';
import { usePrescriptionInputVariables } from './use-prescription-variables';

type SelectedOffer = {
    pathname: string;
    search: string;
    prescriber?: string;
    prescriberNumber?: string | null;
    provider?: string;
    providerAddress?: string | null;
    controlledSubstanceCode?: ControlledSubstanceCode | null;
    isInstantRouting: boolean;
};

export const PrescriptionRouteV2 = () => {
    const Loader = QueryLoader<typeof GetPrescriptionOffersDocument>;
    const { prescriptionId } = useParams();
    const { scopedPatientId } = usePatientContext();

    const distance = useNumericSearchParam('within');
    const input = usePrescriptionInputVariables(distance, scopedPatientId);

    if (!prescriptionId) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    return (
        <Loader
            query={GetPrescriptionOffersDocument}
            queryOptions={{ errorPolicy: 'all' }}
            component={DataHandler}
            variables={{
                prescriberOrderId: prescriptionId,
                authorizedMedicationInput: {
                    coordinatesInput: input.coordinatesInput,
                    sortBy: ProviderOffersSortBy.PatientCost,
                },
                prescribedMedicationInput: {
                    coordinatesInput: input.coordinatesInput,
                    sortBy: ProviderOffersSortBy.PatientCost,
                },
            }}
        />
    );
};

const DataHandler: DataHandlerType<typeof GetPrescriptionOffersDocument> = ({
    data,
}: {
    data: GetPrescriptionOffersQuery;
}) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedOffer, setSelectedOffer] = useState<SelectedOffer | null>(
        null
    );
    const [overrideOfferList, setOverrideOfferList] = useState<
        'authorized' | 'prescribed' | 'default'
    >('default');

    const navigate = useNavigate();
    const { prescriptionId } = useParams();
    const { telemetryInstance } = useTelemetryContext();
    const [isBackWarnOpen, { open, close }] = useDisclosure(false);
    const { geolocation } = useGeolocation();
    const {
        authorizedMedicationConnection,
        prescribedMedicationConnection,
        authorizedMedicationSavings,
    } = usePrescriptionSubstitution(data);
    const checkForInitialRedirect = useRef(true);

    useConditionalBackOverride(open, true);

    if (data.prescriberOrderV2?.__typename === 'UnclaimedPrescriberOrder') {
        return (
            <Navigate
                to={getAbsoluteRoute('cabinet.rxId.verifyPatient', {
                    prescriptionId: prescriptionId!,
                })}
            />
        );
    }

    if (
        data.prescriberOrderV2?.__typename !==
        'DigitallyReceivedPrescriberOrder'
    ) {
        telemetryInstance.logError({
            source: 'prescription-route',
            error: new Error(
                'Cannot get offers for a non-digital prescription'
            ),
        });

        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    const prescriberOrder = getFragment(data.prescriberOrderV2);

    let offersConnection, showAuthorizedOffersChecked;
    if (
        !prescriberOrder.dispenseAsWritten &&
        authorizedMedicationSavings > 0 &&
        overrideOfferList !== 'prescribed'
    ) {
        offersConnection = authorizedMedicationConnection;
        showAuthorizedOffersChecked = true;
    } else {
        offersConnection = prescribedMedicationConnection;
        showAuthorizedOffersChecked = false;
    }

    const providerOffers: ProviderOffersInfoFragment[] =
        (offersConnection?.edges.map((offer) => getFragment(offer)) ??
            []) as ProviderOffersInfoFragment[];

    const patient = getFragment(prescriberOrder.patient);
    const prescriber = getFragment(prescriberOrder.prescriber);
    const controlledSubstanceCode = getFragment(
        prescriberOrder.authorizedMedication
    ).controlledSubstanceCode;

    // Temporary until VELA integration
    const tempIsInstantRouting = false;

    const authorizedMed = getFragment(prescriberOrder.authorizedMedication);
    const prescribedMed = getFragment(prescriberOrder.prescribedMedication);

    const onSelect = (providerOffersId: string, providerId?: string) => {
        const selectedProviderOffers = providerOffers.find(
            (offer) => offer.id === providerOffersId
        );

        const searchParams = providerId
            ? createSearchParams({ providerId }).toString()
            : showAuthorizedOffersChecked
              ? createSearchParams({
                    generic: 'true',
                    genericName: authorizedMed.name,
                    // Is it possible to get into this situation if the prescribed med is unknown, or is it safe to cast here?
                    brandName: (prescribedMed as MedicationInfoFragment).name,
                }).toString()
              : createSearchParams({ brand: 'true' }).toString();

        if (selectedProviderOffers) {
            const primaryProvider = getPrimaryProvider(
                selectedProviderOffers,
                geolocation
            );

            if (
                selectedProviderOffers?.provider.__typename === 'ChainProvider'
            ) {
                const selectedProviderId = providerId ?? primaryProvider?.id;

                if (selectedProviderId) {
                    const offers = getFragments(selectedProviderOffers.offers);

                    const offersForSelected = getOffersByProviderId(
                        offers,
                        selectedProviderId
                    );

                    if (offersForSelected.length <= 1) {
                        const offer = offersForSelected[0];
                        const isInstantRouting = tempIsInstantRouting;

                        if (
                            !controlledSubstanceCode ||
                            (controlledSubstanceCode && isInstantRouting)
                        ) {
                            navigate({
                                pathname: getAbsoluteRoute(
                                    'cabinet.rxId.providerOffersId.offer.root',
                                    {
                                        prescriptionId:
                                            prescriberOrder.drxId ??
                                            prescriberOrder.id,
                                        providerOffersId,
                                        offerId: offer.id,
                                    }
                                ),
                                search: searchParams,
                            });
                            return;
                        }

                        setSelectedOffer({
                            pathname: getAbsoluteRoute(
                                'cabinet.rxId.providerOffersId.offer.root',
                                {
                                    prescriptionId:
                                        prescriberOrder.drxId ??
                                        prescriberOrder.id,
                                    providerOffersId,
                                    offerId: offersForSelected[0].id,
                                }
                            ),
                            search: searchParams,
                            prescriber: prescriber.fullNameWithTitle,
                            prescriberNumber: prescriber.phoneNumber,
                            provider: primaryProvider?.name,
                            providerAddress: primaryProvider?.description,
                            controlledSubstanceCode,
                            isInstantRouting,
                        });
                        setIsModalOpen(true);
                        return;
                    }
                }
            }

            if (selectedProviderOffers?.offers.length <= 1) {
                const offerFragment = getFragment(
                    selectedProviderOffers.offers[0]
                );
                const isInstantRouting = tempIsInstantRouting;

                if (
                    !controlledSubstanceCode ||
                    (controlledSubstanceCode && isInstantRouting)
                ) {
                    navigate({
                        pathname: getAbsoluteRoute(
                            'cabinet.rxId.providerOffersId.offer.root',
                            {
                                prescriptionId:
                                    prescriberOrder.drxId ?? prescriberOrder.id,
                                providerOffersId,
                                offerId: offerFragment?.id ?? 'unknown',
                            }
                        ),
                        search: searchParams,
                    });
                    return;
                }

                setSelectedOffer({
                    pathname: getAbsoluteRoute(
                        'cabinet.rxId.providerOffersId.offer.root',
                        {
                            prescriptionId:
                                prescriberOrder.drxId ?? prescriberOrder.id,
                            providerOffersId,
                            offerId: offerFragment?.id ?? 'unknown',
                        }
                    ),
                    search: searchParams,
                    prescriber: prescriber.fullNameWithTitle,
                    prescriberNumber: prescriber.phoneNumber,
                    provider: primaryProvider?.name,
                    providerAddress: primaryProvider?.description,
                    controlledSubstanceCode,
                    isInstantRouting,
                });
                setIsModalOpen(true);
                return;
            }
        }

        const isInstantRouting = tempIsInstantRouting;

        if (
            !controlledSubstanceCode ||
            (controlledSubstanceCode && isInstantRouting)
        ) {
            navigate({
                pathname: getAbsoluteRoute(
                    'cabinet.rxId.providerOffersId.root',
                    {
                        prescriptionId:
                            prescriberOrder.drxId ?? prescriberOrder.id,
                        providerOffersId,
                    }
                ),
                search: searchParams,
            });
            return;
        }

        setSelectedOffer({
            pathname: getAbsoluteRoute('cabinet.rxId.providerOffersId.root', {
                prescriptionId: prescriberOrder.drxId ?? prescriberOrder.id,
                providerOffersId,
            }),
            search: searchParams,
            prescriber: getFragment(prescriberOrder.prescriber)
                .fullNameWithTitle,
            prescriberNumber: getFragment(prescriberOrder.prescriber)
                .phoneNumber,
            controlledSubstanceCode,
            isInstantRouting,
            // TODO
            // provider: ,
            // providerAddress: ,
        });
        setIsModalOpen(true);
    };

    const featuredProvider = getFeaturedProviderOffers(offersConnection);
    const coverageFlags = offersConnection?.coverageFlags?.find(
        (flag) => flag.groupCode === 'ROOT'
    );

    const providerOfferTypes = getProviderOffersByType(
        offersConnection,
        featuredProvider?.providerOffers.id
    );

    if (checkForInitialRedirect.current) {
        checkForInitialRedirect.current = false;

        if (
            prescriberOrder.latestSelectedPriceQuote ||
            prescriberOrder.latestSelectedOffer
        ) {
            return (
                <Navigate
                    to={getAbsoluteRoute('orders.prescriberOrderId.details', {
                        prescriberOrderId: prescriptionId!,
                    })}
                    replace
                />
            );
        }
    }

    const handleModalContinue = () => {
        if (selectedOffer) {
            navigate(selectedOffer);
        }
        setIsModalOpen(false);
    };

    const authorizedMedRecommendations =
        getFragment(
            data.prescriberOrderV2.authorizedMedication.offersConnection
        )?.recommendations ?? [];

    return (
        <>
            {selectedOffer?.controlledSubstanceCode &&
            !selectedOffer.isInstantRouting ? (
                <ControlledSubstanceModal
                    opened={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    handleContinue={handleModalContinue}
                    prescriber={selectedOffer?.prescriber}
                    prescriberNumber={selectedOffer?.prescriberNumber}
                    provider={selectedOffer?.provider}
                    providerAddress={selectedOffer?.providerAddress}
                    hasInstantRouting={false}
                />
            ) : null}
            <LeavePrescriptionWarningModal
                isOpen={isBackWarnOpen}
                close={close}
                onLeaveClick={() => navigate(-1)}
            />
            <ProviderSelection
                key={`${overrideOfferList}-offers`}
                providerOffersByType={providerOfferTypes}
                onSelect={onSelect}
                header={
                    <PatientProductDetailsV2
                        patientName={patient.fullName}
                        productDescription={prescriberOrder.productDescription}
                        quantity={prescriberOrder.quantity}
                        prescriberOrder={prescriberOrder}
                        authorizedMed={authorizedMed}
                        prescribedMed={prescribedMed}
                        authorizedMedicationSavings={
                            authorizedMedicationSavings
                        }
                        setOverrideOfferList={setOverrideOfferList}
                        showAuthorizedOffersChecked={
                            showAuthorizedOffersChecked
                        }
                        coverageFlags={coverageFlags}
                    />
                }
                featuredProvider={featuredProvider}
                prescriber={prescriber}
                hasInstantRouting={false}
                recommendations={authorizedMedRecommendations}
            />
        </>
    );
};
