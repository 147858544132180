import { useAuthContext } from '@phx/auth/client';
import {
    Avatar,
    Drawer,
    Flex,
    HorizontalDivider,
    IconLogout,
    NavLink,
    SideNavLink,
    Stack,
} from '@phx/design-system';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useSidebarContext } from '../../contexts/SidebarProvider';
import { usePatientContext } from '../../hooks/use-patient-context';
import { getAbsoluteRoute } from '../../util';
import { AppVersion } from '../sidebar/AppVersion';
import { GettingStartedSection } from '../sidebar/sections/GettingStartedSection';
import { HelpAndSettingsSection } from '../sidebar/sections/HelpAndSettingsSection';
import { MyAccountSection } from '../sidebar/sections/MyAccountSection';
import { useMaintenanceStatus } from '../unauth-home/hooks/use-maintenance-status';
import { ConditionalComponent } from '../utility/ConditionalComponent';

export const AppSidebar = () => {
    const { close, isOpen } = useSidebarContext();
    const { isAuthenticated, signIn } = useAuthContext();
    const { t } = useTranslation();
    const location = useLocation();
    const { patient } = usePatientContext();
    const navigate = useNavigate();
    const { isMaintenanceActive } = useMaintenanceStatus();

    const login = useCallback(() => signIn(location.pathname), [signIn]);
    const logout = useCallback(
        () => navigate(getAbsoluteRoute('logout')),
        [navigate]
    );

    useEffect(() => {
        /*
         * If the route changes, we should ensure that the sidebar is closed if
         * it isn't already. Note that there is not a check for `isOpen` here
         * due to conflicts that happen when the user tries to deliberately
         * open the sidebar.
         *
         * However, since we only trigger a close when the location changes, and
         * users aren't in the habit of navigating around in rapid succession,
         * there's no real performance issue calling `close` even if the sidebar
         * is already closed.
         */
        close();
    }, [location.pathname, location.key, close]);

    const drawerHeader = (
        <Flex gap="xs" role="header">
            <Avatar size="xl" initials={patient?.initials} />
            <ConditionalComponent condition={isAuthenticated}>
                <NavLink
                    onClick={() => navigate(getAbsoluteRoute('profile.root'))}
                    label={patient?.fullName}
                    variant="subtle"
                />
            </ConditionalComponent>
            <ConditionalComponent condition={!isAuthenticated}>
                <NavLink
                    disabled={isMaintenanceActive}
                    onClick={login}
                    label={t('sidebar.button.signIn')}
                    variant="subtle"
                />
            </ConditionalComponent>
        </Flex>
    );

    return (
        <Drawer opened={isOpen} onClose={close} header={drawerHeader}>
            <Stack mih="100%" gap="xs" justify="space-between">
                <Stack gap="xs" flex={1}>
                    <GettingStartedSection />
                    <MyAccountSection />
                    <HelpAndSettingsSection />
                    <ConditionalComponent condition={isAuthenticated}>
                        <HorizontalDivider variant="middle" />
                        <SideNavLink
                            label={t('sidebar.button.signOut')}
                            Icon={IconLogout}
                            onClick={logout}
                        />
                    </ConditionalComponent>
                </Stack>
                <AppVersion />
            </Stack>
        </Drawer>
    );
};
