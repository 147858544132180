import { RouterProvider } from 'react-router-dom';

import { useMaintenanceStatus } from './components/unauth-home/hooks/use-maintenance-status';
import { maintenanceRouter, router } from './routes';

export const AppRouter = () => {
    const { isMaintenanceActive } = useMaintenanceStatus();

    if (isMaintenanceActive) {
        return <RouterProvider router={maintenanceRouter} />;
    }

    return <RouterProvider router={router} />;
};
