import {
    Heading1,
    Heading2,
    HorizontalDivider,
    NavLink,
    Stack,
    Subtitle2,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import { ContactUsWidget } from '../../components/common/ContactUsWidget';
import { PlanMemberList } from '../../components/plan-members/PlanMemberList';
import { CareCircleLink } from '../../components/profile/CareCircleLink';
import { UserProfile } from '../../components/profile/UserProfile';
import { getFragment } from '../../graphql';
import { useFlags } from '../../hooks/use-flags';
import { useProfile } from '../../hooks/use-profile';
import { getCurrentUserRelationship } from '../../util/patient/get-current-user-relationship';

const ProfileRoute = () => {
    const { t } = useTranslation();
    const { profile } = useProfile();
    const { changePasswordEnabled } = useFlags();

    const patient = getFragment(profile);

    return (
        <Stack gap="lg">
            <Stack gap="sm">
                <Heading1>{t('profile.heading')}</Heading1>
                <Stack gap="xxxs">
                    <Heading2 data-testid="primary-user-full-name">
                        {patient.fullName}
                    </Heading2>
                    <Subtitle2 data-testid="patient-relationship">
                        {getCurrentUserRelationship(profile, patient.id)}
                    </Subtitle2>
                </Stack>
                <UserProfile />
            </Stack>
            <HorizontalDivider />
            {changePasswordEnabled ? (
                <>
                    <NavLink
                        label={t('profile.changePassword')}
                        // @TODO: implement this when change password exists
                        onClick={() => {}}
                        variant="subtle"
                    />
                    <HorizontalDivider />
                </>
            ) : null}
            <PlanMemberList />
            <CareCircleLink />
            <ContactUsWidget />
        </Stack>
    );
};

export default ProfileRoute;
