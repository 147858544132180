import { type TimelineStep } from '@phx/design-system';
import type { TFunction } from 'i18next';
import { type ReactNode } from 'react';

import {
    type ConsolidatedPriorAuthorizationHistory,
    type PriorAuthorizationInfoFragment,
    PriorAuthorizationSource,
    PriorAuthorizationStatus,
} from '../../../graphql/generated/graphql';
import { formatDateString } from '../../../util';

import { getStatusTranslation } from './PriorAuthStatusDescriptions';

type RawStep = {
    title: string;
    status?: PriorAuthorizationStatus;
    subtitle?: ReactNode;
    description?: string;
};

export const generateSteps = (
    priorAuthInfo: PriorAuthorizationInfoFragment,
    t: TFunction,
    cancelledSubtitle: ReactNode | null,
    locale?: string
): { steps: TimelineStep[]; activeStepIndex: number } => {
    const isNonDrxPa =
        priorAuthInfo.requestOrigin ===
        PriorAuthorizationSource.VendorInitiated;

    const {
        status: currentStatus,
        consolidatedHistory: history,
        estimatedCompletionDate,
        createdAt,
    } = priorAuthInfo;

    //Initial steps: The PA timeline will always have these statuses (NEW, RECEIVED, IN_REVIEW)
    const initialSteps: TimelineStep[] = [
        createStep(
            t('priorAuthorization.status.New'),
            PriorAuthorizationStatus.New,
            getUpdatedAtDate(history, PriorAuthorizationStatus.New)
        ),
        createStep(
            t('priorAuthorization.status.Received'),
            PriorAuthorizationStatus.Received,
            isNonDrxPa
                ? createdAt
                : getUpdatedAtDate(history, PriorAuthorizationStatus.Received)
        ),
        createStep(
            t('priorAuthorization.status.InReview'),
            PriorAuthorizationStatus.InReview,
            getUpdatedAtDate(history, PriorAuthorizationStatus.InReview)
        ),
    ];

    const estimatedComplete = getCompletionText(
        t,
        estimatedCompletionDate,
        locale
    );

    // We only need to add a complete step here
    if (isNewReceivedInReview(currentStatus)) {
        const complete = createStep(
            t('priorAuthorization.status.Complete'),
            undefined,
            undefined,
            cancelledSubtitle ? null : estimatedComplete
        );

        return parseTimeline([...initialSteps, complete], currentStatus);
    }

    // No additional completion steps required
    if (isDeniedApproved(currentStatus)) {
        return parseTimeline(
            mapToCurrentStatus(initialSteps, history),
            currentStatus
        );
    }

    // Here, we will also always add a specific remediation/appealed complete step
    if (isAppealedRedetermined(currentStatus)) {
        const title =
            currentStatus === PriorAuthorizationStatus.Appealed
                ? t('priorAuthorization.status.AppealComplete')
                : t('priorAuthorization.status.RedeterminationComplete');
        const mappedSteps = mapToCurrentStatus(initialSteps, history);
        const nextStep = createStep(
            title,
            currentStatus,
            undefined,
            estimatedComplete
        );

        return parseTimeline([...mappedSteps, nextStep], currentStatus);
    }

    if (currentStatus === PriorAuthorizationStatus.Cancelled) {
        const mappedSteps = mapToCurrentStatus(initialSteps, history);

        return handleCancelledStatus(
            mappedSteps,
            history,
            cancelledSubtitle,
            currentStatus,
            t
        );
    }

    return parseTimeline(
        mapToCurrentStatus(initialSteps, history),
        currentStatus
    );
};

const parseTimeline = (steps: RawStep[], status?: PriorAuthorizationStatus) => {
    const activeStepIndex = getActiveSteps(steps, status);
    return { steps, activeStepIndex };
};

const getActiveSteps = (
    steps: TimelineStep[],
    currentStatus?: PriorAuthorizationStatus
) => {
    for (let i = steps.length - 1; i >= 0; i--) {
        if (steps[i].title === getStatusTranslation(currentStatus)) return i;
    }
    return steps.length - 1;
};

const isNewReceivedInReview = (currentStatus?: PriorAuthorizationStatus) => {
    return (
        currentStatus === PriorAuthorizationStatus.New ||
        currentStatus === PriorAuthorizationStatus.Received ||
        currentStatus === PriorAuthorizationStatus.InReview
    );
};

const isDeniedApproved = (currentStatus: PriorAuthorizationStatus) => {
    return (
        currentStatus === PriorAuthorizationStatus.Denied ||
        currentStatus === PriorAuthorizationStatus.Approved ||
        currentStatus === PriorAuthorizationStatus.AppealApproved ||
        currentStatus === PriorAuthorizationStatus.AppealDenied
    );
};

const isAppealedRedetermined = (currentStatus: PriorAuthorizationStatus) => {
    return (
        currentStatus === PriorAuthorizationStatus.Appealed ||
        currentStatus === PriorAuthorizationStatus.Redetermination
    );
};

const getCompletionText = (
    t: TFunction,
    estimatedCompletionDate?: string | null,
    locale?: string
) => {
    return estimatedCompletionDate
        ? t('priorAuthorization.status.estimated', {
              date: formatDateString(estimatedCompletionDate, locale, {
                  dateStyle: 'medium',
              }),
          })
        : t('priorAuthorization.status.completionDateTBD');
};

const mapToCurrentStatus = (
    initialSteps: RawStep[],
    history: ConsolidatedPriorAuthorizationHistory[]
) => {
    // We never want to map and display the cancelled status on the timeline
    const filteredHistory = history.filter(
        ({ status }) => status !== PriorAuthorizationStatus.Cancelled
    );
    filteredHistory.forEach(({ status, updatedAt }) => {
        if (
            ![
                PriorAuthorizationStatus.New,
                PriorAuthorizationStatus.Received,
                PriorAuthorizationStatus.InReview,
            ].includes(status)
        ) {
            initialSteps.push(
                createStep(getStatusTranslation(status), status, updatedAt)
            );
        }
    });

    return initialSteps;
};

const createStep = (
    title: string,
    status?: PriorAuthorizationStatus,
    description?: string,
    subtitle?: string | ReactNode,
    locale?: string
): RawStep => ({
    title,
    status,
    description: description
        ? formatDateString(description, locale, { dateStyle: 'medium' })
        : undefined,
    subtitle,
});

const getUpdatedAtDate = (
    history: ConsolidatedPriorAuthorizationHistory[],
    status: PriorAuthorizationStatus
) => {
    return history.find((entry) => entry.status === status)?.updatedAt;
};

const handleCancelledStatus = (
    mappedSteps: RawStep[],
    history: ConsolidatedPriorAuthorizationHistory[],
    cancelledSubtitle: ReactNode,
    currentStatus: PriorAuthorizationStatus,
    t: TFunction
) => {
    // This finds the index of the status in priorAuthInfo.consolidatedHistory before the cancelled status
    const actualStatusIndex = history[history.length - 2]
        ? mappedSteps.findLastIndex(
              (step) =>
                  step.title ===
                  getStatusTranslation(history[history.length - 2].status)
          )
        : -1;

    if (
        actualStatusIndex !== -1 &&
        isNewReceivedInReview(mappedSteps[actualStatusIndex!].status) &&
        cancelledSubtitle
    ) {
        const complete = createStep(t('priorAuthorization.status.Complete'));
        mappedSteps[actualStatusIndex!].subtitle = cancelledSubtitle;

        return parseTimeline(
            [...mappedSteps, complete],
            mappedSteps[actualStatusIndex!].status
        );
    }

    if (actualStatusIndex !== -1 && cancelledSubtitle) {
        mappedSteps[actualStatusIndex!].subtitle = cancelledSubtitle;
    }

    //Handles edge case for only receiving a cancelled status in consolidatedHistory array
    if (actualStatusIndex === -1 && cancelledSubtitle) {
        mappedSteps[mappedSteps.length - 1].subtitle = cancelledSubtitle;
    }

    return parseTimeline(mappedSteps, currentStatus);
};
