import i18n from '../../../../i18n';
import type { Medication } from '../../../generated/graphql';

export const formatMedicationDescription = ({
    formCode,
    name,
    packageQuantity,
    strengthValue,
    strengthUnit,
}: Pick<
    Medication,
    'formCode' | 'name' | 'packageQuantity' | 'strengthValue' | 'strengthUnit'
>) => {
    const strength =
        strengthValue && strengthUnit ? ' ' + strengthValue + strengthUnit : '';

    const formText = i18n.t(formCode, {
        ns: 'code-locales/form-code-mapping',
        count: packageQuantity ?? 1,
    });

    return `${name}${strength} ${formText}`;
};
