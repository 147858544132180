import {
    Anchor,
    BaseCard,
    Body1,
    Box,
    Checkbox,
    Collapse,
    Currency,
    FilledCard,
    Flex,
    IconKeyboardArrowDown,
    Modal,
    Stack,
} from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type {
    AuthorizedMedicationInfoFragment,
    CoverageFlags,
    DigitallyReceivedPrescriberOrderInfoFragment,
    MedicationInfoFragment,
    UnknownMedicationInfoFragment,
} from '../../../graphql/generated/graphql';
import { PrescriberOrderDetails } from '../../../routes/orders/details/PrescriberOrderDetails';
import { NotCoveredModal } from '../../common/drug-coverage-alerts/NotCoveredModal';
import { PARequiredModal } from '../../common/drug-coverage-alerts/PARequiredModal';
import { MissingBenefitsCard } from '../../prior-authorization/components/MissingBenefitsCard';

import { DAWModal } from './DAWModal';
import classes from './demo-temp/collapsible-card.module.css';
import { PatientProductDetailsHeader } from './PatientProductDetailsHeader';

export type PatientProductDetailsV2Props = {
    patientName?: string;
    productDescription?: string;
    quantity?: number | null;
    prescriberOrder?: DigitallyReceivedPrescriberOrderInfoFragment;
    authorizedMedicationSavings: number;
    authorizedMed: AuthorizedMedicationInfoFragment;
    prescribedMed: MedicationInfoFragment | UnknownMedicationInfoFragment;
    setOverrideOfferList: (
        override: 'authorized' | 'prescribed' | 'default'
    ) => void;
    showAuthorizedOffersChecked: boolean;
    coverageFlags?: CoverageFlags;
};

export const PatientProductDetailsV2 = ({
    patientName,
    productDescription,
    quantity,
    prescriberOrder,
    authorizedMedicationSavings,
    authorizedMed,
    prescribedMed,
    setOverrideOfferList,
    showAuthorizedOffersChecked,
    coverageFlags,
}: PatientProductDetailsV2Props) => {
    const { t } = useTranslation();
    const [isOpen, { toggle }] = useDisclosure(false);
    const [
        isGenericModalOpen,
        { close: closeGenericModal, toggle: toggleGenericModal },
    ] = useDisclosure(false);
    const [isPARequiredModalOpen, { toggle: togglePARequiredModal }] =
        useDisclosure(false);
    const [isNotCoveredModalOpen, { toggle: toggleNotCoveredModal }] =
        useDisclosure(false);
    const [isDAWModalOpen, { close: closeDAWModal, toggle: toggleDAWModal }] =
        useDisclosure(false);

    const isSwapped = authorizedMed.id !== prescribedMed.id;

    if (!prescriberOrder) {
        return <MissingBenefitsCard />;
    }

    const CardHeader = (
        <Flex
            component="a"
            onClick={toggle}
            justify="space-between"
            align="center"
            style={{
                cursor: 'pointer',
                width: '100%',
            }}
            p="sm"
            data-testid="collapsible-card-expand"
        >
            <Box style={{ flex: 1 }}>
                <PatientProductDetailsHeader
                    patientName={patientName}
                    productDescription={productDescription}
                    quantity={quantity}
                    prescriberOrder={prescriberOrder}
                    coverageFlags={coverageFlags}
                    togglePARequiredModal={togglePARequiredModal}
                    toggleNotCoveredModal={toggleNotCoveredModal}
                />
            </Box>
            <Box style={{ alignSelf: 'start' }}>
                <IconKeyboardArrowDown
                    style={{
                        transition: 'transform 200ms ease',
                        transform: isOpen ? 'rotate(180deg)' : '',
                    }}
                />
            </Box>
        </Flex>
    );

    const toggleCheckbox = useCallback(() => {
        if (showAuthorizedOffersChecked) {
            setOverrideOfferList('prescribed');
        } else {
            setOverrideOfferList('authorized');
        }
    }, [showAuthorizedOffersChecked, setOverrideOfferList]);

    return (
        <>
            <BaseCard
                withBorder
                padding={0}
                shadow="xs"
                variant="filled"
                radius="sm"
                header={CardHeader}
                style={{
                    borderColor: `var(--mantine-color-gray-2)`,
                }}
            >
                <Stack gap="sm">
                    <Collapse
                        data-state={isOpen ? 'open' : 'closed'}
                        className={classes.collapse}
                        px="sm"
                        in={isOpen}
                    >
                        <PrescriberOrderDetails
                            prescriberOrder={prescriberOrder}
                        />
                    </Collapse>
                    {isSwapped && (
                        <FilledCard
                            color={
                                showAuthorizedOffersChecked ? 'success' : 'gray'
                            }
                            sharp
                        >
                            <Flex>
                                <Box flex={1}>
                                    {showAuthorizedOffersChecked ? (
                                        <Trans
                                            i18nKey="drx.product.details.savingWithGeneric"
                                            values={{
                                                genericName: authorizedMed.name,
                                            }}
                                        >
                                            <Stack gap="xs">
                                                <Body1 bold>
                                                    {'Saving up to '}
                                                    <Currency
                                                        amount={
                                                            authorizedMedicationSavings
                                                        }
                                                    />
                                                    {' with '}
                                                    <Anchor
                                                        onClick={
                                                            toggleGenericModal
                                                        }
                                                    >
                                                        generic
                                                    </Anchor>
                                                </Body1>
                                                <Body1>
                                                    {'Prices shown for '}
                                                    {authorizedMed.name}
                                                </Body1>
                                            </Stack>
                                        </Trans>
                                    ) : (
                                        <Trans i18nKey="drx.product.details.saveWithGeneric">
                                            <Stack gap="xs">
                                                <Body1 bold>
                                                    {'Save up to '}
                                                    <Currency
                                                        amount={
                                                            authorizedMedicationSavings
                                                        }
                                                    />
                                                    {' with '}
                                                    <Anchor
                                                        onClick={
                                                            toggleGenericModal
                                                        }
                                                    >
                                                        generic
                                                    </Anchor>
                                                </Body1>
                                            </Stack>
                                        </Trans>
                                    )}
                                </Box>
                                <Box>
                                    <Checkbox
                                        checked={showAuthorizedOffersChecked}
                                        onChange={toggleCheckbox}
                                    />
                                </Box>
                            </Flex>
                        </FilledCard>
                    )}
                    {prescriberOrder.dispenseAsWritten && (
                        <FilledCard color="gray" sharp>
                            <Trans i18nKey="drx.product.details.daw.unknownSavings">
                                <Body1>
                                    {'The healthcare provider indicated "'}
                                    <Anchor onClick={toggleDAWModal}>
                                        Dispense As Written
                                    </Anchor>
                                    {
                                        '" for the brand. Consult the provider about switching to the '
                                    }
                                    <Anchor onClick={toggleGenericModal}>
                                        generic
                                    </Anchor>
                                    {' to save.'}
                                </Body1>
                            </Trans>
                        </FilledCard>
                    )}
                </Stack>
            </BaseCard>
            <Modal
                opened={isGenericModalOpen}
                onClose={closeGenericModal}
                title={t('drx.product.details.modals.generic.title')}
            >
                <Body1>{t('drx.product.details.modals.generic.body')}</Body1>
            </Modal>
            <DAWModal close={closeDAWModal} isOpen={isDAWModalOpen} />
            <PARequiredModal
                opened={isPARequiredModalOpen}
                close={togglePARequiredModal}
            />
            <NotCoveredModal
                opened={isNotCoveredModalOpen}
                close={toggleNotCoveredModal}
            />
        </>
    );
};
