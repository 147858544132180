import { useAuthContext } from '@phx/auth/client';
import {
    Body1,
    Box,
    Button,
    Caption,
    Flex,
    FullBleed,
    Heading1,
    Image,
    Stack,
} from '@phx/design-system';
import { constants, useTheme } from '@phx/design-system/framework';
import { useIsMobile } from '@phx/design-system/hooks';
import { useTranslation } from 'react-i18next';

import { useMaintenanceStatus } from './hooks/use-maintenance-status';
import classes from './join-employer-plan.module.css';

export const JoinEmployerPlan = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const isMobile = useIsMobile('md');
    const { signUp } = useAuthContext();
    const { isMaintenanceActive } = useMaintenanceStatus();

    return (
        <FullBleed breakpoint="md" spacing={isMobile ? 'all' : 'none'}>
            <Flex
                gap="xl"
                classNames={{
                    root: isMobile
                        ? classes['mobile-wrapper']
                        : classes['desktop-wrapper'],
                }}
            >
                <Box>
                    <picture>
                        <source
                            srcSet="/static/img/unauth-home-page-card-lg.png"
                            media={`(min-width: ${theme.breakpoints.md})`}
                        />
                        <Image
                            src="/static/img/unauth-home-page-card.jpg"
                            alt={t('unauth.joinEmployer.altImage')}
                            fit="contain"
                            classNames={{
                                root: isMobile
                                    ? classes['mobile-image']
                                    : classes['desktop-image'],
                            }}
                        />
                    </picture>
                </Box>
                <Box>
                    <Stack pt={{ md: 'xxl' }} pb={{ md: 'xxxl' }} gap="md">
                        <Heading1>{t('unauth.joinEmployer.header')}</Heading1>
                        <Body1>
                            {t('unauth.joinEmployer.subtitle', {
                                tm: constants.unicode.TRADEMARK,
                            })}
                        </Body1>
                        <Stack gap="xs">
                            <Button
                                disabled={isMaintenanceActive}
                                size="lg"
                                onClick={() => signUp()}
                                data-testid="join-employer-sign-up-button"
                            >
                                {t('unauth.joinEmployer.signUp')}
                            </Button>
                            <Caption>
                                {t('unauth.joinEmployer.disclaimer')}
                            </Caption>
                        </Stack>
                    </Stack>
                </Box>
            </Flex>
        </FullBleed>
    );
};
