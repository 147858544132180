import { CAPSULE_AND_TABLET_FORM_CODES } from '@phx/rx-common';

import type {
    MedicationInfoFragment,
    MedicationPackageInfoFragment,
} from '../../graphql/generated/graphql';
import i18n from '../../i18n';

export const PACK_CODE_V2 = 'PA';
export const QUANTITY_OPTION_COUNT_V2 = 3;

function getPackUnitTotalTextV2(
    chosenQuantity: number,
    formPackage: Pick<
        MedicationPackageInfoFragment,
        | 'formCode'
        | 'packageCode'
        | 'packageQuantity'
        | 'packageSize'
        | 'packageSizeUnit'
    >
) {
    const { packageCode, packageQuantity, packageSize, formCode } = formPackage;

    const packText = i18n.t(PACK_CODE_V2, {
        ns: 'code-locales/package-code-mapping',
        count: chosenQuantity,
    });
    const packageType = i18n.t(packageCode, {
        ns: 'code-locales/package-code-mapping',
        count: packageQuantity,
    });

    const formText = i18n.t(formCode, {
        ns: 'code-locales/form-code-mapping',
        count: packageQuantity,
    });

    const total = packageSize * packageQuantity * chosenQuantity;

    const longText = `${chosenQuantity} ${packText} ${i18n.t('productConfig.of')} ${packageQuantity} ${packageType} (${total} ${formText})`;
    const shortText = `${total} ${formText}`;
    const formulaText = `${chosenQuantity} ${packText} * ${packageQuantity} ${packageType} * ${packageSize} ${formText} = ${total} ${formText}`;

    return {
        longText,
        shortText,
        formulaText,
        value: total,
        quantityDescriptor: packText,
    };
}

function getTotalPackageIrrelevantV2(
    chosenQuantity: number,
    formPackage: Pick<
        MedicationPackageInfoFragment,
        | 'formCode'
        | 'packageCode'
        | 'packageQuantity'
        | 'packageSize'
        | 'packageSizeUnit'
    >
) {
    const { formCode, packageQuantity } = formPackage;

    if (packageQuantity && packageQuantity > 1) {
        //Pack Units
        return getPackUnitTotalTextV2(chosenQuantity, formPackage);
    }

    //Individual unit
    const formDescription = i18n.t(formCode, {
        ns: 'code-locales/form-code-mapping',
        count: chosenQuantity,
    });
    const totalText = `${chosenQuantity} ${formDescription}`;

    return {
        longText: totalText,
        shortText: totalText,
        formulaText: totalText,
        quantityDescriptor: formDescription,
        value: chosenQuantity,
    };
}

function getTotalPackageRelevantV2(
    chosenQuantity: number,
    formPackage: Pick<
        MedicationPackageInfoFragment,
        | 'formCode'
        | 'packageCode'
        | 'packageQuantity'
        | 'packageSize'
        | 'packageSizeUnit'
    >
) {
    const {
        packageCode,
        packageQuantity,
        packageSize = 1,
        formCode,
    } = formPackage;

    if (packageQuantity && packageQuantity > 1) {
        //pack units
        return getPackUnitTotalTextV2(chosenQuantity, formPackage);
    }

    const packageDescription = i18n.t(packageCode, {
        ns: 'code-locales/package-code-mapping',
        count: chosenQuantity,
    });
    const total = packageSize * chosenQuantity;
    const formDescription = i18n.t(formCode, {
        ns: 'code-locales/form-code-mapping',
        count: total,
    });

    const longText = `${chosenQuantity} ${packageDescription} (${total} ${formDescription})`;
    const shortText = `${total} ${formDescription}`;
    const formulaText = `${chosenQuantity} ${packageDescription} * ${packageSize} ${formDescription} = ${total} ${formDescription}`;

    return {
        longText,
        shortText,
        formulaText,
        quantityDescriptor: packageDescription,
        value: total,
    };
}

function getComplexTotalPackUnitV2(
    chosenQuantity: number,
    formPackage: Pick<
        MedicationPackageInfoFragment,
        | 'formCode'
        | 'packageCode'
        | 'packageQuantity'
        | 'packageSize'
        | 'packageSizeUnit'
    >
) {
    const {
        packageCode,
        packageQuantity = 1,
        packageSize = 1,
        packageSizeUnit = '',
        formCode,
    } = formPackage;

    const packText = i18n.t(PACK_CODE_V2, {
        ns: 'code-locales/package-code-mapping',
        count: chosenQuantity,
    });

    const packageDesc = i18n.t(packageCode, {
        ns: 'code-locales/package-code-mapping',
        count: packageQuantity,
    });

    const total = packageSize * packageQuantity * chosenQuantity;

    const formDesc = i18n.t(formCode, {
        ns: 'code-locales/form-code-mapping',
        count: 1,
    });

    const of = i18n.t('productConfig.of');

    const longText = `${chosenQuantity} ${packText} ${of} ${packageQuantity} ${packageDesc} ${of} ${formDesc} (${total}${packageSizeUnit})`;
    const shortText = `${total}${packageSizeUnit}`;
    const formulaText = `${chosenQuantity} ${packText} * ${packageQuantity} ${packageDesc} * ${packageSize}${packageSizeUnit} = ${total}${packageSizeUnit} `;

    return {
        longText,
        shortText,
        formulaText,
        value: total,
        quantityDescriptor: packText,
    };
}

export function getTotalQuantityDescriptionV2(
    chosenQuantity: number,
    formPackage: Pick<
        MedicationPackageInfoFragment,
        | 'formCode'
        | 'packageCode'
        | 'packageQuantity'
        | 'packageSize'
        | 'packageSizeUnit'
    >
): {
    longText: string;
    shortText: string;
    formulaText: string;
    value: number;
    quantityDescriptor: string;
} {
    const {
        formCode,
        packageCode,
        packageQuantity,
        packageSize,
        packageSizeUnit,
    } = formPackage;

    const formattedStrengthUnit = packageSizeUnit
        ? packageSizeUnit.toLowerCase()
        : '';

    //Simply Quantity
    if (formattedStrengthUnit === 'ea') {
        //Package not relevant
        if (
            CAPSULE_AND_TABLET_FORM_CODES.has(formCode) &&
            packageQuantity === 1 &&
            packageCode !== 'DP'
        ) {
            return getTotalPackageIrrelevantV2(chosenQuantity, formPackage);
        }
        return getTotalPackageRelevantV2(chosenQuantity, formPackage);
    }

    //Complex Quantity
    else if (formattedStrengthUnit === 'ml' || formattedStrengthUnit === 'gm') {
        if (packageQuantity && packageQuantity > 1) {
            return getComplexTotalPackUnitV2(chosenQuantity, formPackage);
        }

        const total = packageSize * chosenQuantity;

        const packageDescription = i18n.t(packageCode, {
            ns: 'code-locales/package-code-mapping',
            count: chosenQuantity,
        });

        const formDescription = i18n.t(formCode, {
            ns: 'code-locales/form-code-mapping',
            count: 1,
        });

        const longText = i18n.t(
            'productConfig.totalQuantityDescriptions.longText',
            {
                chosenQuantity,
                packageDescription,
                formDescription,
                total,
                packageSizeUnit,
            }
        );
        const shortText = `${total}${packageSizeUnit}`;
        const formulaText = `${chosenQuantity} ${packageDescription} * ${packageSize}${packageSizeUnit} = ${total}${packageSizeUnit}`;

        return {
            longText,
            shortText,
            formulaText,
            value: total,
            quantityDescriptor: packageDescription,
        };
    }

    return {
        longText: chosenQuantity.toString(),
        shortText: chosenQuantity.toString(),
        formulaText: chosenQuantity.toString(),
        quantityDescriptor: i18n.t('productConfig.quantity'),
        value: chosenQuantity,
    };
}

export function getPackageCodeQuantitiesV2(
    formPackage: Pick<
        MedicationInfoFragment,
        'packageCode' | 'defaultDaysSupply'
    >
) {
    const results = [];
    const modeDaysSuply = getPackageDaysSupplyV2(formPackage);

    for (let i = 1; i <= QUANTITY_OPTION_COUNT_V2; i++) {
        results.push({
            displayText: `${i} ${i18n.t(formPackage.packageCode, {
                ns: 'code-locales/package-code-mapping',
                count: i,
            })}`,
            value: i.toString(),
            daysSupply: modeDaysSuply * i,
        });
    }
    return results;
}

export function getPackageDaysSupplyV2(
    formPackage?: Pick<MedicationInfoFragment, 'defaultDaysSupply'>
) {
    return formPackage?.defaultDaysSupply && formPackage.defaultDaysSupply > 0
        ? formPackage.defaultDaysSupply
        : 30;
}

export function getPackUnitQuantitiesV2(
    formPackage?: Pick<MedicationInfoFragment, 'defaultDaysSupply'>
) {
    const results = [];
    const modeDaysSuply = getPackageDaysSupplyV2(formPackage);

    for (let i = 1; i <= QUANTITY_OPTION_COUNT_V2; i++) {
        results.push({
            displayText: `${i} ${i18n.t(PACK_CODE_V2, { ns: 'code-locales/package-code-mapping', count: i })}`,
            value: i.toString(),
            daysSupply: modeDaysSuply * i,
        });
    }
    return results;
}

export function getIndividualFormQuantitiesV2(
    formPackage: Pick<
        MedicationInfoFragment,
        'defaultQuantity' | 'defaultDaysSupply' | 'formCode'
    >
) {
    const { defaultQuantity, formCode } = formPackage;
    const results = [];
    const modeDaysSuply = getPackageDaysSupplyV2(formPackage);

    const modeQuantity =
        defaultQuantity && defaultQuantity > 0 ? defaultQuantity : 30;

    for (let i = 1; i <= QUANTITY_OPTION_COUNT_V2; i++) {
        const quantity = modeQuantity * i;
        results.push({
            displayText: `${quantity} ${i18n.t(formCode, {
                ns: 'code-locales/form-code-mapping',
                count: modeQuantity,
            })}`,
            value: quantity.toString(),
            daysSupply: (i * modeDaysSuply).toString(),
        });
    }
    return results;
}

/**
 * Get the quantity selection options to display for a given drug package
 */
export function getComplexQuantityOptionsV2(
    packageInfo: Pick<
        MedicationPackageInfoFragment,
        | 'defaultQuantity'
        | 'defaultDaysSupply'
        | 'packageQuantity'
        | 'packageSize'
        | 'packageCode'
    >
) {
    const {
        packageQuantity,
        packageCode,
        packageSize,
        defaultDaysSupply,
        defaultQuantity,
    } = packageInfo;

    const results = [];
    const quantityDescriptorCode =
        packageQuantity && packageQuantity > 1 ? PACK_CODE_V2 : packageCode;

    let baseQuantity = Math.ceil(
        (defaultQuantity ?? 1) / (packageSize ?? 1) / (packageQuantity ?? 1)
    );

    if (baseQuantity < 1) {
        baseQuantity = 1;
    }

    for (let i = 1; i <= QUANTITY_OPTION_COUNT_V2; i++) {
        const quantity = baseQuantity * i;
        results.push({
            displayText: `${quantity} ${i18n.t(quantityDescriptorCode, {
                ns: ['code-locales/package-code-mapping'],
                count: quantity,
            })}`,
            value: quantity.toString(),
            daysSupply: ((defaultDaysSupply ?? 1) * i).toString(),
        });
    }

    return results;
}

export function computeDaysSupplyV2(
    packageInfo: Pick<MedicationPackageInfoFragment, 'quantityOptions'>,
    chosenQuantity: number
) {
    const { quantityOptions } = packageInfo;

    const matchingQuantityOption = quantityOptions.find(
        (option) => Number(option.value) === chosenQuantity
    );

    if (matchingQuantityOption && !!matchingQuantityOption.daysSupply) {
        return Number(matchingQuantityOption.daysSupply);
    } else if (quantityOptions.length) {
        //Find which index the chosenValue would fall into if inserted in order
        const values = quantityOptions.map((option) => Number(option.value));
        let index = values.findIndex((current) => chosenQuantity <= current);
        if (index === -1) {
            index = values.length;
        }

        if (index >= values.length) {
            return 90; // caps out 90
        }

        return quantityOptions[index]?.daysSupply
            ? Number(quantityOptions[index].daysSupply)
            : index * 30;
    }

    return 30; //fallback days supply
}
