import type { ReactNode } from 'react';

import { MailOrderPricing } from './MailOrderPricing';
import { PickupPricing } from './PickupPricing';
import type { PricingProps } from './shared';

type PharmacyTypes =
    | 'MailOrderProvider'
    | 'PharmacyProvider'
    | 'UnknownProvider';
const PricingByType: Record<
    PharmacyTypes,
    ({ offer }: PricingProps) => ReactNode | null
> = {
    MailOrderProvider: MailOrderPricing,
    PharmacyProvider: PickupPricing,
    UnknownProvider: () => null,
};

export const Pricing = ({ offer, genericSwap }: PricingProps) => {
    if (!offer.pharmacyProvider.__typename) {
        return null;
    }

    const Component = PricingByType[offer.pharmacyProvider.__typename];
    return <Component offer={offer} genericSwap={genericSwap} />;
};
