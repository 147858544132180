import {
    IconAssignment,
    IconGenericPharmacy,
    IconHelpCenter,
    IconPrivacyTip,
    IconSupportAgent,
    IconTranslate,
    SideNavLink,
} from '@phx/design-system';
import { constants } from '@phx/design-system/framework';
import { openTalkativeUI } from '@phx/talkative';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useSidebarContext } from '../../../contexts/SidebarProvider';
import externalRoutes from '../../../external-routes';
import { useFlags } from '../../../hooks/use-flags';
import { useUserPreferences } from '../../../hooks/use-user-preferences';
import { getAbsoluteRoute } from '../../../util/get-absolute-route';
import { getDisplayLanguage } from '../../../util/i18n';
import { useMaintenanceStatus } from '../../unauth-home/hooks/use-maintenance-status';
import { ConditionalComponent } from '../../utility/ConditionalComponent';
import { AddToContactsMenuItem } from '../menu-items/AddToContactsMenuItem';
import { AddToHomeMenuItem } from '../menu-items/AddToHomeMenuItem';
import { MenuSection } from '../MenuSection';

export const HelpAndSettingsSection = () => {
    const { t } = useTranslation();
    const { helpCenterEnabled } = useFlags();
    const { close } = useSidebarContext();
    const { language } = useUserPreferences();
    const navigate = useNavigate();
    const { isMaintenanceActive } = useMaintenanceStatus();

    return (
        <MenuSection label={t('sidebar.helpAndSettings.heading')}>
            <ConditionalComponent condition={helpCenterEnabled}>
                <SideNavLink
                    label={t('sidebar.helpAndSettings.helpCenter')}
                    Icon={IconHelpCenter}
                    onClick={() =>
                        navigate(getAbsoluteRoute('helpCenter.root'))
                    }
                />
            </ConditionalComponent>
            <AddToHomeMenuItem />
            <AddToContactsMenuItem />
            <SideNavLink
                disabled={isMaintenanceActive}
                label={t('sidebar.helpAndSettings.inNetworkPharmacies')}
                Icon={IconGenericPharmacy}
                onClick={() => navigate(getAbsoluteRoute('pharmacy.inNetwork'))}
            />
            <SideNavLink
                label={t('sidebar.helpAndSettings.support')}
                Icon={IconSupportAgent}
                onClick={() => {
                    openTalkativeUI();
                    close();
                }}
            />
            <SideNavLink
                label={t('sidebar.helpAndSettings.language', {
                    language: getDisplayLanguage(language).native,
                    bull: constants.unicode.BULLET,
                })}
                Icon={IconTranslate}
                onClick={() => navigate(getAbsoluteRoute('language'))}
            />
            <SideNavLink
                label={t('sidebar.helpAndSettings.privacyPolicy')}
                Icon={IconPrivacyTip}
                onClick={() =>
                    window.open(externalRoutes.privacyPolicy, '_blank')
                }
            />
            <SideNavLink
                label={t('sidebar.helpAndSettings.termsAndConditions')}
                Icon={IconAssignment}
                onClick={() =>
                    window.open(externalRoutes.termsConditions, '_blank')
                }
            />
        </MenuSection>
    );
};
