import { useAuthContext } from '@phx/auth/client';
import {
    Alert,
    Anchor,
    Body1,
    DividedText,
    Flex,
    IconButton,
    IconControlledSubstance,
    IconEdit,
    IconInfo,
    LoadingPlaceholder,
    Stack,
    Subtitle2,
    TextButton,
} from '@phx/design-system';
import { useTelemetryContext } from '@phx/instrumentation/react';
import type React from 'react';
import { type PropsWithChildren, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { getFragment, getFragments } from '../../graphql';
import {
    ControlledSubstanceCode,
    DrugFamilyDocument,
    type DrugFamilyInfoFragment,
} from '../../graphql/generated/graphql';
import { QueryLoader } from '../../loaders';
import { getAbsoluteRoute, getTotalQuantityDescription } from '../../util';
import { ConditionalComponent } from '../utility/ConditionalComponent';

type DrugFamilyHeaderProps = {
    topNode?: React.ReactNode;
    drugId: string;
    editClicked?: () => void;
    controlledSubstanceCode?: ControlledSubstanceCode | null;
};

export const DrugFamilyHeader = ({
    topNode,
    drugId,
    editClicked,
    children,
    controlledSubstanceCode,
}: PropsWithChildren<DrugFamilyHeaderProps>) => {
    const Loader = QueryLoader<typeof DrugFamilyDocument>;
    return (
        <Loader
            query={DrugFamilyDocument}
            variables={{ id: drugId }}
            loadingPlaceholderComponent={<LoadingPlaceholder rows={2} />}
            render={(data) => {
                if (data?.drugFamily) {
                    const drugFamily = getFragment(data.drugFamily);

                    return (
                        <DrugFamilyHeaderView
                            drugFamily={drugFamily}
                            editClicked={editClicked}
                            topNode={topNode}
                            controlledSubstanceCode={controlledSubstanceCode}
                        >
                            {children}
                        </DrugFamilyHeaderView>
                    );
                }

                return children ? <>{children}</> : null;
            }}
        />
    );
};

type DrugFamilyHeaderviewProps = {
    topNode?: React.ReactNode;
    drugFamily: DrugFamilyInfoFragment;
    editClicked?: () => void;
    controlledSubstanceCode?: ControlledSubstanceCode | null;
};

export const DrugFamilyHeaderView = ({
    topNode,
    drugFamily,
    editClicked,
    children,
    controlledSubstanceCode,
}: PropsWithChildren<DrugFamilyHeaderviewProps>) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { telemetryInstance } = useTelemetryContext();
    const [searchParams] = useSearchParams();
    const { signIn, isAuthenticated } = useAuthContext();

    const chosenQuantity =
        searchParams.get('qInput') ?? searchParams.get('quantity');
    const product = getFragment(drugFamily.defaultProduct);
    const quantity = chosenQuantity
        ? Number(chosenQuantity)
        : (product?.modeQuantity ?? 30);

    const form = getFragments(drugFamily.forms).find(
        (form) => form.defaultNdc === drugFamily.defaultNdc
    );

    const matchingPackage =
        form &&
        getFragments(form.packages).find(
            (packageInfo) => packageInfo.id === drugFamily.defaultNdc
        );

    const quantityDesc =
        matchingPackage &&
        getTotalQuantityDescription(quantity, form, matchingPackage);

    const handleEditClick = () => {
        //Log telemetry event
        telemetryInstance.logEvent({
            name: 'Drugconfig open edit drug',
            source: 'DrugFamilyHeader',
        });
        editClicked && editClicked();
    };

    const login = useCallback(
        () => signIn(location.pathname),
        [signIn, location.pathname]
    );

    return (
        <Stack gap="xs" data-testid="drug-family-header">
            <ConditionalComponent
                condition={!!controlledSubstanceCode && !isAuthenticated}
            >
                <Alert IconOverride={IconInfo} severity="warning">
                    <Trans i18nKey="drugFamilyHeader.alert.controlledSubstances">
                        <Body1 color="warning">
                            <Anchor color="warning" onClick={login}>
                                Sign in to view prices
                            </Anchor>{' '}
                            for controlled substances.
                        </Body1>
                    </Trans>
                </Alert>
            </ConditionalComponent>
            {topNode && topNode}
            <Stack gap="xxs">
                <Flex gap="xxs">
                    <Subtitle2 data-testid="search-product-details-header">
                        {product.name}
                    </Subtitle2>
                    {controlledSubstanceCode ? (
                        <IconButton
                            Icon={IconControlledSubstance}
                            withStyles={false}
                            onClick={() =>
                                navigate(
                                    getAbsoluteRoute('helpCenter.medications')
                                )
                            }
                        />
                    ) : null}
                </Flex>
                <Flex justify="space-between" align="center" gap="md">
                    <DividedText
                        entries={[
                            product.strengthDisplayText ?? '',
                            quantityDesc?.longText ?? '',
                        ]}
                    />
                    {editClicked && (
                        <TextButton
                            data-testid="edit-drug-configuration"
                            size="sm"
                            onClick={handleEditClick}
                            LeftIcon={IconEdit}
                        >
                            {t('drugConfig.Edit')}
                        </TextButton>
                    )}
                </Flex>
            </Stack>
            {children}
        </Stack>
    );
};
