import { Select } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import type { MedicationStrengthInfoFragment } from '../../graphql/generated/graphql';

export const MedicationStrengthSelect = ({
    strengths,
    selectedStrength,
    setSelectedStrength,
}: {
    strengths?: MedicationStrengthInfoFragment[];
    selectedStrength: string;
    setSelectedStrength: (value: string) => void;
}) => {
    const { t } = useTranslation(['myphx']);

    const data = strengths?.map((strength) => ({
        value: strength?.strengthValue || '-',
        label: `${strength?.strengthValue}${strength?.strengthUnit}` || '-',
    })) || [
        {
            value: '-',
            label: '-',
        },
    ];

    return (
        <Select
            placeholder="-"
            disabled={(strengths?.length ?? 0) <= 1}
            label={t('drugConfig.strength')}
            value={selectedStrength === '' ? '-' : selectedStrength}
            onChange={(value) => value && setSelectedStrength(value)}
            data={data}
        />
    );
};
