import { IconButton, IconMoreVert, Popover } from '@phx/design-system';

export const PendingCaregiverDropdown = () => {
    return (
        <Popover
            withOverlay
            overlayProps={{ zIndex: 10000, blur: '2px' }}
            position="bottom-end"
            width="320px"
            zIndex={10001}
        >
            <Popover.Target>
                <IconButton size="sm" color="gray" Icon={IconMoreVert} />
            </Popover.Target>
            <Popover.Dropdown>
                <div />
            </Popover.Dropdown>
        </Popover>
    );
};
