import { Body1, Button, Flex, Heading1, Stack } from '@phx/design-system';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PendingCareCircleMemberCard } from '../../components/care-circle/PendingCareCircleMemberCard';
import type { PlanMemberListItemProps } from '../../components/plan-members/PlanMemberListItem';
import { getFragment } from '../../graphql';
import { useCareCircle } from '../../hooks/use-care-circle';
import {
    type UsePlanMembersOptions,
    usePlanMembers,
} from '../../hooks/use-plan-members';
import { getAbsoluteRoute } from '../../util';

export const InviteCaregiverRoute = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { network, loading } = useCareCircle();

    const getEligibleCaregivers = useCallback<
        NonNullable<UsePlanMembersOptions['filter']>
    >(
        (relationship) => {
            if (loading || !network) {
                return true;
            }

            const { grants, pendingGrants } = network;
            const member = getFragment(relationship.node);

            const hasExistingGrant = grants.some(
                (grant) =>
                    grant.__typename === 'IndividualCaregiver' &&
                    grant.name === member.fullName
            );

            const hasPendingGrant = pendingGrants.some(
                (grant) => grant.caregiverName === member.fullName
            );

            // If the member is already a caregiver, or has a pending invite, they can't be invited again
            return !(hasExistingGrant || hasPendingGrant);
        },
        [network, loading]
    );

    const dependents: PlanMemberListItemProps[] = usePlanMembers({
        filter: getEligibleCaregivers,
    });

    const selectCaregiver = useCallback(
        (patient: PlanMemberListItemProps['patient']) => {
            const caregiverParams = {
                caregiverId: patient.id,
                firstName: patient.firstName,
                lastName: patient.lastName,
                phoneNumber: patient.phoneNumber ?? '',
            };

            navigate({
                pathname: getAbsoluteRoute('careCircle.invite.send.root'),
                search: `?${new URLSearchParams(caregiverParams).toString()}`,
            });
        },
        []
    );

    return (
        <Stack gap="lg" flex={1}>
            <Flex gap="xs" align="center">
                <Heading1>{t('careCircle.invite.heading')}</Heading1>
            </Flex>
            <Stack gap="xs">
                <Body1>{t('careCircle.invite.body1')}</Body1>
                <Body1>{t('careCircle.invite.body2')}</Body1>
            </Stack>
            <Stack gap="md">
                {dependents.map((dependent) => (
                    <PendingCareCircleMemberCard
                        key={dependent.memberId}
                        onClick={selectCaregiver}
                        {...dependent}
                    />
                ))}
            </Stack>
            <Button variant="outline">
                {t('careCircle.invite.button.someoneElse')}
            </Button>
        </Stack>
    );
};
