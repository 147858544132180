import { IconPaid, IconSavings, NavLink } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import { getFragment } from '../../../graphql';
import {
    type OffersConnectionInfoFragment,
    RecommendationKind,
} from '../../../graphql/generated/graphql';
import { formatToLocalizedCurrency } from '../../../util';

type RecommendationsLinkProps = {
    onAltsClick: () => void;
    recommendations: OffersConnectionInfoFragment['recommendations'];
};

export const RecommendationsLink = (props: RecommendationsLinkProps) => {
    const { onAltsClick, recommendations } = props;
    const { t } = useTranslation();

    const newProducts = [];
    let bestPatientSavings = 0;
    let bestPlanSavings = 0;
    recommendations?.forEach((recommendation) => {
        const rec = getFragment(recommendation);
        if (
            rec.kind === RecommendationKind.AlternativePatientSavings &&
            rec.savings > bestPatientSavings
        ) {
            bestPatientSavings = rec.savings;
        } else if (
            rec.kind === RecommendationKind.AlternativePlanSavings &&
            rec.savings > bestPlanSavings
        ) {
            bestPlanSavings = rec.savings;
        }
        newProducts.push(rec);
    });

    const lowPatientSavings = bestPatientSavings <= 1;
    const planSavingsThreshold = 20;

    if (
        recommendations?.length === 0 ||
        (lowPatientSavings && bestPlanSavings < planSavingsThreshold)
    ) {
        return null;
    }

    const savePlanWithAlternative =
        lowPatientSavings && bestPlanSavings >= planSavingsThreshold;

    const { savingsText, Icon } = savePlanWithAlternative
        ? {
              savingsText: t('pickYourPharmacy.altPlanSavings', {
                  value: formatToLocalizedCurrency(bestPlanSavings, {
                      maximumFractionDigits: 0,
                  }),
              }),
              Icon: IconPaid,
          }
        : {
              savingsText: t('pickYourPharmacy.altSavings', {
                  value: formatToLocalizedCurrency(bestPatientSavings, {
                      maximumFractionDigits: 0,
                  }),
              }),
              Icon: IconSavings,
          };
    return (
        <NavLink
            active
            label={savingsText}
            color={savePlanWithAlternative ? 'info' : 'saving'}
            variant="light"
            Icon={Icon}
            onClick={onAltsClick}
        />
    );
};
