import {
    Body1,
    Flex,
    Image,
    List,
    ListItem,
    Modal,
    Stack,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';

export type CareCircleLearnMoreDialogProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const CareCircleLearnMoreDialog = ({
    isOpen,
    onClose,
}: CareCircleLearnMoreDialogProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            opened={isOpen}
            onClose={onClose}
            title={t('careCircle.learnMoreDialog.title')}
        >
            <Stack gap="md">
                <List type="ordered">
                    <ListItem>
                        <Body1>
                            {t(
                                'careCircle.learnMoreDialog.content.instructions.step1'
                            )}
                        </Body1>
                    </ListItem>
                    <ListItem>
                        <Body1>
                            {t(
                                'careCircle.learnMoreDialog.content.instructions.step2'
                            )}
                        </Body1>
                    </ListItem>
                    <ListItem>
                        <Body1>
                            {t(
                                'careCircle.learnMoreDialog.content.instructions.step3'
                            )}
                        </Body1>
                    </ListItem>
                </List>
                <Image
                    src="/static/img/invite-caregivers.jpg"
                    alt={t('careCircle.learnMoreDialog.screenshot.alt')}
                    width="1146"
                    height="600"
                    fit="contain"
                    radius="sm"
                />
                <Stack gap="xs">
                    <Body1>
                        {t(
                            'careCircle.learnMoreDialog.content.accessTypes.desc'
                        )}
                    </Body1>
                    <List>
                        <ListItem>
                            <Flex gap="xxs">
                                <Body1>
                                    <strong>
                                        {t(
                                            'careCircle.learnMoreDialog.content.accessTypes.full.name'
                                        )}
                                    </strong>{' '}
                                    {t(
                                        'careCircle.learnMoreDialog.content.accessTypes.full.desc'
                                    )}
                                </Body1>
                            </Flex>
                        </ListItem>
                        <ListItem>
                            <Flex gap="xxs">
                                <Body1>
                                    <strong>
                                        {t(
                                            'careCircle.learnMoreDialog.content.accessTypes.limited.name'
                                        )}
                                    </strong>{' '}
                                    {t(
                                        'careCircle.learnMoreDialog.content.accessTypes.limited.desc'
                                    )}
                                </Body1>
                            </Flex>
                        </ListItem>
                    </List>
                </Stack>
                <Body1>
                    {t('careCircle.learnMoreDialog.content.minorDisclaimer')}
                </Body1>
            </Stack>
        </Modal>
    );
};
