import { Anchor, Body1, Flex, Stack } from '@phx/design-system';
import { Trans, useTranslation } from 'react-i18next';

import { DeliveryStep } from './DeliveryStep';
import type { LocationInfo } from './shared';

export const MailOrderNextSteps = ({
    locationInfo,
}: {
    locationInfo: LocationInfo;
}) => {
    const { t } = useTranslation();

    return (
        <Stack gap="sm">
            {locationInfo.url ? (
                <Stack gap={0}>
                    <DeliveryStep
                        stepTexts={[
                            {
                                key: 'orderConfirmation.mailOrderUrl.stepOne',
                                value: (
                                    <Flex align="start" py="xxs">
                                        <Trans
                                            i18nKey="orderConfirmation.mailOrderUrl.stepOne"
                                            values={{
                                                locationName: locationInfo.name,
                                            }}
                                        >
                                            <Body1>
                                                1. Sign in or sign up to{' '}
                                                <Anchor
                                                    href={locationInfo.url}
                                                    target="_blank"
                                                >
                                                    {locationInfo.name}
                                                </Anchor>
                                            </Body1>
                                        </Trans>
                                    </Flex>
                                ),
                            },
                            t('orderConfirmation.mailOrderUrl.stepTwo'),
                        ]}
                    />
                </Stack>
            ) : (
                <Stack gap={0}>
                    <DeliveryStep
                        stepTexts={[
                            t('orderConfirmation.mailOrderPhone.stepOne'),
                            t('orderConfirmation.mailOrderPhone.stepTwo'),
                        ]}
                    />
                </Stack>
            )}
        </Stack>
    );
};
