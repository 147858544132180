import type { PlanMemberRelationshipType } from '../components/plan-members/PlanMemberListItem';
import type { ProfileContextType } from '../contexts/ProfileProvider';
import { getFragment } from '../graphql';
import { getMemberId } from '../util/patient/get-member-id';
import { getRelationshipDisplay } from '../util/patient/get-relationship-display';
import { sortPlanMemberList } from '../util/patient/sort-plan-member-list';

import { useProfile } from './use-profile';

export type UsePlanMembersOptions = {
    filter?: (
        relationship: ProfileContextType['profile']['patientRelationshipConnection']['edges'][number]
    ) => boolean;
};

export const usePlanMembers = ({ filter }: UsePlanMembersOptions = {}) => {
    const { patient: currentPatient, profile } = useProfile();

    return profile.patientRelationshipConnection.edges
        .filter((edge) => filter?.(edge) ?? true)
        .map((edge) => {
            const { relationshipType, relatedAccountId } = edge.relationship;
            const dependentType =
                relationshipType as PlanMemberRelationshipType;
            const member = getFragment(edge.node);
            const memberId = getMemberId(member);

            if (relatedAccountId === currentPatient.id) {
                return {
                    memberId,
                    relationshipType: 'PRIMARY' as const,
                    relationshipTypeName: getRelationshipDisplay('PRIMARY'),
                    patient: member,
                };
            }

            return {
                memberId,
                relationshipType: dependentType,
                relationshipTypeName: getRelationshipDisplay(dependentType),
                patient: member,
            };
        })
        .filter(({ memberId }) => Boolean(memberId))
        .toSorted(sortPlanMemberList);
};
