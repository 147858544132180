import {
    Body1,
    Box,
    Highlight,
    IconSearch,
    Stack,
    TextButton,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import type { DrugResult } from './DrugSearchV2';
import { NoSearchResultsV2 } from './NoSearchResultsV2';

type DrugSearchResultProps = {
    drug: DrugResult;
    searchInput: string;
    onDrugSelect: (drugId: string, searchParams?: string) => void;
};

const DrugSearchResultV2 = ({
    drug,
    searchInput,
    onDrugSelect,
}: DrugSearchResultProps) => {
    return (
        <TextButton
            __vars={{ '--button-justify': 'left' }}
            justify="left"
            data-testid="drug-menu-item-button"
            onClick={() => onDrugSelect(drug.id, searchInput)}
            LeftIcon={IconSearch}
            fullWidth
            color="gray"
        >
            <Highlight highlight={searchInput}>{drug.displayName}</Highlight>
        </TextButton>
    );
};

type DrugSearchResultsProps = {
    results: DrugResult[];
    searchInput: string;
    onDrugSelect: (drugId: string, searchParams?: string) => void;
};

export const DrugSearchResultsV2 = ({
    results,
    searchInput,
    onDrugSelect,
}: DrugSearchResultsProps) => {
    const { t } = useTranslation();

    if (!results.length) {
        return (
            <Box>
                <NoSearchResultsV2 message={t('drugSearch.noResults')} />
            </Box>
        );
    }

    return (
        <Stack gap="md" align="stretch" justify="flex-start">
            <Body1>
                {t('drugSearch.searchResults', { length: results.length })}
            </Body1>
            <Stack gap="lg">
                {results.map((result, index) => (
                    <DrugSearchResultV2
                        key={`${result.name}-${index}`}
                        drug={result}
                        onDrugSelect={onDrugSelect}
                        searchInput={searchInput}
                    />
                ))}
            </Stack>
        </Stack>
    );
};
