import { useQuery } from '@apollo/client';
import { type ComponentType } from 'react';
import { Navigate } from 'react-router-dom';

import {
    GetBasicPrescriberOrdersByPatientDocument,
    type GetBasicPrescriberOrdersByPatientQuery,
    GetBasicPrescriberOrdersDocument,
    type GetBasicPrescriberOrdersQuery,
    GetPatientPriorAuthorizationsDocument,
    type GetPatientPriorAuthorizationsQuery,
    PrescriberOrderStatus,
} from '../../graphql/generated/graphql';
import { getAbsoluteRoute } from '../../util';

import { MedicineCabinetPlaceholder } from './MedicineCabinetPlaceholder';

type QueryDocumentType =
    | typeof GetBasicPrescriberOrdersDocument
    | typeof GetBasicPrescriberOrdersByPatientDocument;

export type MedicineCabinetLoaderProps = {
    patientId: string;
    viewDependentPrescriptions?: boolean;
    Component: ComponentType<{
        patient:
            | NonNullable<GetBasicPrescriberOrdersQuery['patient']>
            | NonNullable<GetBasicPrescriberOrdersByPatientQuery['patient']>;
        patientId: string;
        nonDrxPatientPriorAuthorizations: NonNullable<
            GetPatientPriorAuthorizationsQuery['patient']
        >;
    }>;
};

export const MedicineCabinetLoader = ({
    patientId,
    viewDependentPrescriptions,
    Component,
}: MedicineCabinetLoaderProps) => {
    const QueryDocument: QueryDocumentType = viewDependentPrescriptions
        ? GetBasicPrescriberOrdersDocument
        : GetBasicPrescriberOrdersByPatientDocument;

    const input = {
        statusFilter: [
            PrescriberOrderStatus.New,
            PrescriberOrderStatus.SentToPharmacy,
            PrescriberOrderStatus.Filled,
        ],
    };

    // @TODO - investigate preloading queries outside of react or with background and loadable query hooks
    // https://www.apollographql.com/docs/react/data/suspense#initiating-queries-outside-react
    //  preload medicine cabinet query
    // const [GetPrescriberOrders] = useLazyQuery(GetPrescriberOrdersDocument, {
    //     variables: {
    //         patientId: primaryPatientId,
    //         input,
    //     },
    //     errorPolicy: 'all',
    // });

    const {
        data: basicPrescribersOrderData,
        loading: basicPrescribersOrderLoading,
    } = useQuery(QueryDocument, {
        variables: { patientId, input },
        errorPolicy: 'all',
    });

    const {
        data: priorAuthorizationsData,
        loading: priorAuthorizationsLoading,
    } = useQuery(GetPatientPriorAuthorizationsDocument, {
        variables: {
            patientId,
            input: {
                nonDrx: true,
                active: true,
            },
        },
        errorPolicy: 'all',
    });

    const loading = basicPrescribersOrderLoading || priorAuthorizationsLoading;

    if (loading) return <MedicineCabinetPlaceholder variant="outlined" />;

    if (
        !basicPrescribersOrderData?.patient ||
        !priorAuthorizationsData?.patient
    ) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    return (
        <Component
            patient={basicPrescribersOrderData.patient}
            patientId={patientId}
            nonDrxPatientPriorAuthorizations={priorAuthorizationsData.patient}
        />
    );
};
