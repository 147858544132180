import { Box, Flex, Subtitle2 } from '@phx/design-system';
import { MedFormIcon } from '@phx/myphx-lib';

import type { PriorAuthorizationInfoFragment } from '../../../graphql/generated/graphql';
import { useMedicationOrderDetails } from '../../../hooks/use-medication-order-details';
import {
    type BasicPrescriberOrderFragment,
    getOrderQuantity,
} from '../../../util/cabinet/prescriber-orders';
import { isBasicPrescriberOrder } from '../../../util/orders';
import DrugDetails from '../../common/DrugDetails';

import { MedicationOrderDetails } from './MedicationOrderDetails';
import { MedicationOrderStateDisplay } from './MedicationOrderStateDisplay';
import { UnclaimedOrderInfoModal } from './UnclaimedOrderInfoModal';

export type MedicationOrderProps = {
    order: BasicPrescriberOrderFragment | PriorAuthorizationInfoFragment;
};

export const MedicationOrder = ({ order }: MedicationOrderProps) => {
    const {
        productForm,
        description,
        drugDetailsSplitEnabled,
        prescribedProduct,
        clickHandler,
        isUnclaimed,
        fillOrder,
        verifyOrder,
        showUnclaimedModal,
        closeUnclaimedModal,
    } = useMedicationOrderDetails(order);

    if (!description) {
        return null;
    }

    return (
        <Flex gap="xs" justify="space-between" align="start" role="listitem">
            <Flex gap="xs">
                <MedFormIcon form={productForm} />
                <Box
                    style={{ cursor: clickHandler ? 'pointer' : 'auto' }}
                    onClick={clickHandler}
                >
                    <Box data-testid="medication-entry-medication-description">
                        <Subtitle2>
                            {drugDetailsSplitEnabled
                                ? prescribedProduct?.name
                                : description}
                        </Subtitle2>
                    </Box>
                    {drugDetailsSplitEnabled && prescribedProduct ? (
                        <DrugDetails
                            product={prescribedProduct}
                            quantity={
                                isBasicPrescriberOrder(order)
                                    ? getOrderQuantity(order)
                                    : undefined
                            }
                        />
                    ) : null}
                    <MedicationOrderDetails order={order} />
                </Box>
            </Flex>
            <MedicationOrderStateDisplay
                isUnclaimed={isUnclaimed}
                order={order}
                fillOrder={fillOrder}
                verifyOrder={verifyOrder}
            />
            <UnclaimedOrderInfoModal
                onClose={closeUnclaimedModal}
                verifyOrder={verifyOrder}
                visible={showUnclaimedModal}
            />
        </Flex>
    );
};
