import {
    FilledCard,
    IconChevronRight,
    IconWarning,
    TextButton,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getAbsoluteRoute } from '../../../util';

export const MissingBenefitsCard = ({
    partnerName = 'Prescryptive',
}: {
    partnerName?: string;
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <FilledCard
            color="warning"
            Icon={IconWarning}
            header={t('priorAuth.locationCard.header')}
            footer={
                <TextButton
                    onClick={() => navigate(getAbsoluteRoute('startSaving'))}
                    RightIcon={IconChevronRight}
                >
                    {t('priorAuth.locationCard.learnHowButton')}
                </TextButton>
            }
        >
            {t('priorAuth.locationCard.description', { partnerName })}
        </FilledCard>
    );
};
