import { Anchor, Body1, List, ListItem, Stack } from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { Trans, useTranslation } from 'react-i18next';

import { PriorAuthCoverageCard } from '../PriorAuthCoverageCard';

export const ApprovedDescription = ({
    isSpecialty,
    isPrescriberOrder,
}: {
    isSpecialty?: boolean;
    isPrescriberOrder?: boolean;
}) => {
    const { t } = useTranslation();
    const [openedInsuranceinformation, { toggle: toggleInsuranceinformation }] =
        useDisclosure();

    const description = isPrescriberOrder ? (
        t('priorAuthorization.statusPage.approved.description.drx')
    ) : isSpecialty ? (
        t('priorAuthorization.statusPage.approved.description.nonDrxSpecialty')
    ) : (
        <Trans
            i18nKey="priorAuthorization.statusPage.approved.description.nonDrx"
            components={{
                1: <Anchor onClick={toggleInsuranceinformation} />,
            }}
        />
    );

    const showSteps = isSpecialty || isPrescriberOrder;

    return (
        <Stack gap="sm">
            <Body1>{description}</Body1>
            {showSteps ? (
                <>
                    <List>
                        <ListItem>
                            <Body1>
                                {t(
                                    'priorAuthorization.statusPage.approved.stepOne'
                                )}
                            </Body1>
                        </ListItem>
                        <ListItem>
                            <Body1>
                                {t(
                                    'priorAuthorization.statusPage.approved.stepTwo'
                                )}
                            </Body1>
                        </ListItem>
                        <ListItem>
                            <Body1>
                                {t(
                                    'priorAuthorization.statusPage.approved.stepThree'
                                )}
                            </Body1>
                        </ListItem>
                    </List>
                    <Body1>
                        {t('priorAuthorization.statusPage.approved.footNote')}
                    </Body1>
                </>
            ) : null}
            <PriorAuthCoverageCard
                opened={openedInsuranceinformation}
                onClose={toggleInsuranceinformation}
            />
        </Stack>
    );
};
