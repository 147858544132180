import { useAuthContext } from '@phx/auth/client';
import { Flex, IconBolt, Modal, Subtitle2 } from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { geolocationDistance, useGeolocation } from '@phx/location-utils';
import {
    HighlightPharmacyCard,
    PharmacyCard,
    type Pricing,
} from '@phx/myphx-lib';
import { useTranslation } from 'react-i18next';

import { getFragment, getFragments } from '../../../graphql';
import {
    type CashOfferInfoFragment,
    type InsuranceOfferInfoFragment,
    type PharmacyProviderInfoFragment,
    type ProviderOffersInfoFragment,
    SuggestedProviderOffersReason,
} from '../../../graphql/generated/graphql';
import { usePatientContext } from '../../../hooks/use-patient-context';
import { getOffersByProviderId } from '../../../util/offers/get-offers-by-provider-id';
import { getPriceableOffers } from '../../../util/offers/get-priceable-offers';
import { getTodaysHours } from '../../../util/pharmacy/get-todays-hours';
import { getPrimaryProvider } from '../../../util/provider-offers/provider-utils';

import { MoreProviders } from './MoreProviders';

export const ProviderOffersCard = ({
    providerOffers,
    onSelected,
    suggestedReasons,
    role,
    showContactForPrice = true,
}: {
    providerOffers: ProviderOffersInfoFragment;
    onSelected: (id: string, providerId?: string) => void;
    suggestedReasons?: SuggestedProviderOffersReason[];
    role?: React.AriaRole;
    showContactForPrice?: boolean;
}) => {
    const { t } = useTranslation();
    const { isAuthenticated } = useAuthContext();
    const { preferences } = usePatientContext();
    const { favoritePharmacies } = preferences;
    const { checkIsFavorite, toggleFavorite } = favoritePharmacies;
    const [opened, { open, close }] = useDisclosure(false);
    const { geolocation } = useGeolocation();
    const primaryPharmacy = getPrimaryProvider(providerOffers, geolocation);

    if (!primaryPharmacy) {
        throw new Error('No pharmacy for offer');
    }

    let description;
    let additionalInfo;

    if (primaryPharmacy.__typename === 'PharmacyProvider') {
        description = [
            t('common.distance.distanceWithUnit', {
                miles: Math.max(
                    0.1,
                    geolocation
                        ? geolocationDistance(geolocation, primaryPharmacy)
                        : providerOffers.distance
                ).toFixed(1),
            }),
            primaryPharmacy.description ?? '',
        ];

        additionalInfo = getTodaysHours(primaryPharmacy);
    } else if (primaryPharmacy.__typename === 'MailOrderProvider') {
        description = [primaryPharmacy.deliveryTimeDisplay];
    }

    const offers = getOffersByProviderId(
        getPriceableOffers(providerOffers),
        primaryPharmacy.id
    ) as Array<CashOfferInfoFragment | InsuranceOfferInfoFragment>;
    const pricing = offers.map((offer) => offer.pricingDetail as Pricing);

    let morePharmacies: PharmacyProviderInfoFragment[] = [];
    if (providerOffers.provider.__typename === 'ChainProvider') {
        const chainProvider = getFragment(providerOffers.provider);
        if (chainProvider?.pharmacies?.length) {
            morePharmacies = getFragments(chainProvider.pharmacies).filter(
                (pharmacy) => pharmacy.id !== primaryPharmacy.id
            );
        }
    }

    // Temporary until VELA integration
    const instantRouting = false;

    const toggleFavoriteIcon = isAuthenticated
        ? () => toggleFavorite(primaryPharmacy.id)
        : undefined;

    const footer = (
        <Flex justify="space-between">
            <MoreProviders
                primaryName={primaryPharmacy.name}
                moreProviders={morePharmacies}
                onSelected={(id: string) => onSelected(providerOffers.id, id)}
            />
            {instantRouting ? (
                <Flex style={{ marginLeft: 'auto ' }} gap="xxxs" align="center">
                    <IconBolt
                        height={19}
                        variant="rounded"
                        filled
                        color="info"
                    />
                    <Subtitle2 color="info">
                        {t('controlledSubstance.modal.instantRouting')}
                    </Subtitle2>
                </Flex>
            ) : null}
        </Flex>
    );

    if (suggestedReasons?.includes(SuggestedProviderOffersReason.Cheapest)) {
        const fulfillmentType =
            providerOffers.provider.__typename === 'MailOrderProvider'
                ? t('offerPharmacyCard.delivery')
                : t('offerPharmacyCard.pickup');
        const heading = `${t('offerPharmacyCard.greatValue.heading')} (${fulfillmentType})`;

        return (
            <>
                <HighlightPharmacyCard
                    role={role}
                    title={primaryPharmacy.name}
                    favorite={checkIsFavorite(primaryPharmacy.id)}
                    toggleFavorite={toggleFavoriteIcon}
                    description={description}
                    pricing={pricing}
                    onClick={() => onSelected(providerOffers.id)}
                    variant="GreatValue"
                    header={heading}
                    onIconClick={open}
                    footer={footer}
                    additionalInfo={additionalInfo}
                />
                <Modal
                    title={t('offerPharmacyCard.greatValue.modal.title')}
                    onClose={close}
                    opened={opened}
                >
                    {t('offerPharmacyCard.greatValue.modal.content')}
                </Modal>
            </>
        );
    }

    const subTitle =
        pricing.length || !showContactForPrice
            ? undefined
            : t('offerPharmacyCard.contactForPrice');

    return (
        <PharmacyCard
            role={role}
            title={primaryPharmacy.name}
            favorite={checkIsFavorite(primaryPharmacy.id)}
            toggleFavorite={toggleFavoriteIcon}
            description={description}
            pricing={pricing}
            onClick={() => onSelected(providerOffers.id)}
            footer={footer}
            subtitle={subTitle}
            additionalInfo={additionalInfo}
        />
    );
};
