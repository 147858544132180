import { useAuthContext } from '@phx/auth/client';
import { Box, Button } from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useMaintenanceStatus } from '../unauth-home/hooks/use-maintenance-status';

export const SignUpButton = () => {
    const { t } = useTranslation();
    const { signUp } = useAuthContext();
    const location = useLocation();
    const { isMaintenanceActive } = useMaintenanceStatus();

    return (
        <Box mr="sm" onClick={() => signUp(location.pathname)}>
            <Button disabled={isMaintenanceActive} size="sm">
                {t('unauth.header.signUp')}
            </Button>
        </Box>
    );
};
