import type { PriorAuthorizationInfoFragment } from '../../graphql/generated/graphql';
import type { BasicPrescriberOrderFragment } from '../cabinet/prescriber-orders';

/**
 * Sorts an array of medication orders (dRx or non-dRx) by date from newest to oldest
 *
 * @param orders - The array of medication orders to sort
 * @returns The sorted array of medication orders
 */
export const sortOrdersByDate = (
    orders: (BasicPrescriberOrderFragment | PriorAuthorizationInfoFragment)[]
): (BasicPrescriberOrderFragment | PriorAuthorizationInfoFragment)[] => {
    const getDate = (
        med: BasicPrescriberOrderFragment | PriorAuthorizationInfoFragment
    ): Date => {
        switch (med.__typename) {
            case 'PriorAuthorization':
                return new Date(med.createdAt);
            case 'DigitallyReceivedPrescriberOrder':
                return new Date(med.writtenDate);
            case 'ClaimGeneratedPrescriberOrder':
                return new Date(med.writtenDateClaimGenerated ?? 0);
            case 'UnclaimedPrescriberOrder':
                return new Date(med.writtenDateUnclaimed ?? 0);
            default:
                return new Date(0);
        }
    };

    return orders.sort((a, b) => getDate(b).getTime() - getDate(a).getTime());
};
