import { useContext } from 'react';

import { CareCircleContext } from '../contexts/CareCircleProvider';

export const useCareCircle = () => {
    const ctx = useContext(CareCircleContext);

    if (!ctx) {
        throw new Error(
            'useCareCircle must be used within a CareCircleProvider'
        );
    }

    return ctx;
};
