import { Button, Heading1, HorizontalDivider, Stack } from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getFragment } from '../../graphql';
import type {
    DigitallyReceivedPrescriberOrderInfoFragment,
    MailOrderProviderInfoFragment,
    PharmacyProviderInfoFragment,
    PriorAuthorizationInfoFragment,
} from '../../graphql/generated/graphql';
import {
    PriorAuthorizationSource,
    PriorAuthorizationStatus as PriorAuthorizationStatusType,
} from '../../graphql/generated/graphql';
import { formatToLocalizedDate, getAbsoluteRoute } from '../../util';
import { ContactUsWidget } from '../common/ContactUsWidget';
import { PatientProductDetails } from '../preview-details/components/PatientProductDetails';
import { ConditionalComponent } from '../utility/ConditionalComponent';

import { PriorAuthAccordion } from './components/PriorAuthAccordion';
import { PriorAuthLocationDetails } from './components/PriorAuthLocationDetails';
import {
    PriorAuthStatusDescription,
    getStatusTranslation,
} from './components/PriorAuthStatusDescriptions';
import { PriorAuthTimeline } from './components/PriorAuthTimeline';
type PriorAuthorizationStatusProps = {
    prescriberOrder?: DigitallyReceivedPrescriberOrderInfoFragment;
    priorAuthInfo: PriorAuthorizationInfoFragment;
    location?: PharmacyProviderInfoFragment | MailOrderProviderInfoFragment;
};

export const PriorAuthorizationStatus = ({
    prescriberOrder,
    priorAuthInfo,
    location,
}: PriorAuthorizationStatusProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const patient = getFragment(priorAuthInfo.patient);
    const product = getFragment(priorAuthInfo.medication)?.name;
    const quantity = prescriberOrder?.quantity ?? priorAuthInfo.quantity;

    const isApproved =
        priorAuthInfo.status === PriorAuthorizationStatusType.Approved ||
        priorAuthInfo.status === PriorAuthorizationStatusType.AppealApproved;
    const isDenied =
        priorAuthInfo.status === PriorAuthorizationStatusType.Denied ||
        priorAuthInfo.status === PriorAuthorizationStatusType.AppealDenied;
    const isPrescriberOrder =
        priorAuthInfo.requestOrigin ===
        PriorAuthorizationSource.PrescriptionInitiated;
    const isSpecialty = priorAuthInfo.isSpecialty;

    const trackingNumber = priorAuthInfo.externalThirdPartyId;
    const validTo = getFragment(priorAuthInfo.effectiveDates).effectiveTo;
    const validFrom = getFragment(priorAuthInfo.effectiveDates).effectiveFrom;
    const validToDate =
        validTo &&
        formatToLocalizedDate(new Date(validTo), { dateStyle: 'medium' });
    const validFromDate =
        validFrom &&
        formatToLocalizedDate(new Date(validFrom), { dateStyle: 'medium' });
    const appealDeadline = priorAuthInfo.appealableBefore;

    //NOTE: When a status is denied, we don't want the user to focus on pharmacy location but rather denial decision and recommendations, so pharmacy location info will be available via collapsed accordion item
    const showPharmacyInfo =
        location && !isDenied && (isSpecialty || isPrescriberOrder);

    return (
        <Stack>
            <PatientProductDetails
                patientName={patient.fullName}
                productDescription={product}
                quantity={quantity}
                prescriberOrder={prescriberOrder}
                priorAuth={priorAuthInfo}
            />
            <HorizontalDivider />
            <Stack gap="xl">
                <Stack gap="sm">
                    <Heading1>
                        {t('priorAuthorization.statusPage.currentStatus')}
                        {getStatusTranslation(priorAuthInfo.status)}
                    </Heading1>
                    <PriorAuthStatusDescription
                        trackingNumber={trackingNumber}
                        appealBeforeDate={appealDeadline}
                        rationale={priorAuthInfo.determinationRationale}
                        status={priorAuthInfo.status}
                        isPrescriberOrder={isPrescriberOrder}
                        isSpecialty={isSpecialty}
                    />
                </Stack>
                <ConditionalComponent condition={!isApproved && !isDenied}>
                    <PriorAuthTimeline priorAuthInfo={priorAuthInfo} />
                </ConditionalComponent>

                {showPharmacyInfo ? (
                    <PriorAuthLocationDetails
                        isNonDrxSpecialtyPriorAuth={
                            isSpecialty && !isPrescriberOrder
                        }
                        isApproved={isApproved}
                        location={location}
                    />
                ) : null}

                {/* NOTE: Display details as expandable accordion for approved and denied statuses only */}
                {isApproved || isDenied ? (
                    <PriorAuthAccordion
                        isDenied={isDenied}
                        location={location}
                        isApproved={isApproved}
                        priorAuthInfo={priorAuthInfo}
                        validFromDate={validFromDate}
                        validToDate={validToDate}
                    />
                ) : null}
            </Stack>

            <HorizontalDivider />
            <ContactUsWidget />
            <Button
                onClick={() => {
                    navigate(getAbsoluteRoute('home'));
                }}
            >
                {t('common.button.returnToHome')}
            </Button>
        </Stack>
    );
};
