import { type FieldReadFunction } from '@apollo/client';

export const readMedicationStrengthDisplayText: FieldReadFunction = (
    _,
    { readField }
) => {
    const strengthText = readField<string>('strengthValue');
    const strengthUnit = readField<string>('strengthUnit');

    if (!!strengthText && !!strengthUnit) {
        return `${strengthText}${strengthUnit}`;
    }
    return null;
};
