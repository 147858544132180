import {
    Body1,
    Flex,
    Heading2,
    IconButton,
    IconCalendarToday,
    IconControlledSubstance,
    IconMoreHoriz,
    IconPerson,
    Stack,
    Subtitle2,
} from '@phx/design-system';
import { useFlags } from '@phx/feature-flags/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getFragment } from '../../../graphql';
import { ControlledSubstanceCode } from '../../../graphql/generated/graphql';
import i18n from '../../../i18n';
import { formatToLocalizedDate, getAbsoluteRoute } from '../../../util';
import { getOfferProdct } from '../../../util/offers/get-offer-product';
import DrugDetails from '../../common/DrugDetails';
import { ConditionalComponent } from '../../utility/ConditionalComponent';
import type { OrderConfirmationProps } from '../OrderConfirmation';

export const YourPrescription = ({
    prescriberOrder,
    offer,
    isInstantRouting = false,
    controlledSubstanceCode,
}: OrderConfirmationProps & {
    isInstantRouting?: boolean;
    controlledSubstanceCode?: ControlledSubstanceCode | null;
}) => {
    const { drugDetailsSplitEnabled } = useFlags();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigateToPrescriptionDetailsPage = () => {
        {
            prescriberOrder.id &&
                navigate(
                    getAbsoluteRoute('orders.prescriberOrderId.details', {
                        prescriberOrderId: prescriberOrder.id,
                    }),
                    { state: { prev: 'history' } }
                );
        }
    };

    const { quantity } = prescriberOrder;
    const patient = getFragment(prescriberOrder.patient);
    const product = getOfferProdct(offer);

    const createdDate = formatToLocalizedDate(
        new Date(offer?.createdDate),
        { dateStyle: 'medium' },
        i18n.language
    );

    const displayQuantity =
        (!!quantity && !drugDetailsSplitEnabled && !controlledSubstanceCode) ||
        isInstantRouting;

    const displayProductNameOnly =
        drugDetailsSplitEnabled ||
        (controlledSubstanceCode && !isInstantRouting);

    const medicationName =
        product && displayProductNameOnly
            ? product.name
            : prescriberOrder.productDescription;

    return (
        <Stack gap="md">
            <Heading2>
                {t('orderConfirmation.yourPrescription.subHeader')}
            </Heading2>
            <Stack gap="xs">
                <Stack gap="xxs">
                    <Flex gap="xxxs">
                        <Subtitle2 data-testid="product-details-header">
                            {medicationName}
                        </Subtitle2>
                        <ConditionalComponent
                            condition={!!controlledSubstanceCode}
                        >
                            <IconButton
                                Icon={IconControlledSubstance}
                                withStyles={false}
                                onClick={() =>
                                    navigate(
                                        getAbsoluteRoute(
                                            'helpCenter.medications'
                                        )
                                    )
                                }
                            />
                        </ConditionalComponent>
                    </Flex>
                    <ConditionalComponent condition={displayQuantity}>
                        <Body1>
                            {t('orderDetails.quantity', {
                                quantity,
                            })}
                        </Body1>
                    </ConditionalComponent>
                </Stack>
                <ConditionalComponent
                    condition={drugDetailsSplitEnabled && product}
                >
                    {<DrugDetails product={product!} quantity={quantity} />}
                </ConditionalComponent>

                <Flex gap="xs">
                    <IconPerson color="primary" />
                    <Body1>
                        {t('orderConfirmation.patientInfo', {
                            firstName: patient.firstName,
                            lastName: patient.lastName,
                        })}
                    </Body1>
                </Flex>
                <ConditionalComponent
                    condition={
                        isInstantRouting ||
                        !controlledSubstanceCode /**|| Todo: add is no longer pending status */
                    }
                >
                    <Flex gap="xs">
                        <IconCalendarToday color="primary" />
                        <Body1>
                            {t('orderConfirmation.sentToPharmacyDate', {
                                date: createdDate,
                            })}
                        </Body1>
                    </Flex>
                </ConditionalComponent>
                <Flex
                    gap="xs"
                    style={{ cursor: 'pointer' }}
                    onClick={navigateToPrescriptionDetailsPage}
                >
                    <IconMoreHoriz color="primary" />
                    <Body1> {t('orderConfirmation.viewMoreDetails')}</Body1>
                </Flex>
            </Stack>
        </Stack>
    );
};
