import {
    Button,
    Group,
    Heading1,
    IconInfo,
    Stack,
    TextButton,
    TextInput,
    useNavBar,
} from '@phx/design-system';
import { useIsMobile } from '@phx/design-system/hooks';
import { useTelemetryContext } from '@phx/instrumentation/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';

import type {
    MedicationFamilyInfoFragment,
    MedicationInfoFragment,
} from '../../graphql/generated/graphql';
import { useMedicationConfig } from '../../hooks/use-medication-config';
import { computeDaysSupplyV2, getAbsoluteRoute } from '../../util';
import { TotalQuantityDialog } from '../product-configuration';

import { MedicationFormSelect } from './MedicationFormSelect';
import { MedicationPackageSelect } from './MedicationPackageSelect';
import { MedicationQuantitySelect } from './MedicationQuantitySelect';
import { MedicationStrengthSelect } from './MedicationStrengthSelect';

export type MedicationConfigurationProps = {
    medicationFamily: MedicationFamilyInfoFragment;
    medication: MedicationInfoFragment;
    closeEdit: () => void;
};

export const MedicationConfiguration = ({
    medicationFamily,
    medication,
    closeEdit,
}: MedicationConfigurationProps) => {
    const [daysSupply, setDaysSupply] = useState(
        medication.defaultDaysSupply ?? 30
    );
    const [dialogOpen, setDialogOpen] = useState(false);

    const { setBackOverrideState } = useNavBar();
    const { telemetryInstance } = useTelemetryContext();
    const { t } = useTranslation(['myphx']);

    const navigate = useNavigate();
    const isMobile = useIsMobile();

    const {
        forms,
        selectedForm,
        setSelectedForm,
        strengthSortedForm,
        packageOptions,
        quantityOptions,
        selectedQuantity,
        setSelectedQuantity,
        totalQuantity,
        selectedPackage,
        setSelectedPackage,
        selectedStrength,
        setSelectedStrength,
        chosenQuantity,
        customQuantity,
        setCustomQuantity,
        setSearchParams,
        setMedicationId,
    } = useMedicationConfig({ medicationFamily, medication });

    const applyEnabled = !!selectedPackage && chosenQuantity >= 1;

    const applyChanges = useCallback(() => {
        if (!applyEnabled) {
            return;
        }

        if (selectedPackage && chosenQuantity) {
            const searchParams = createSearchParams({
                quantity: totalQuantity.value.toString(),
                daysSupply: daysSupply.toString(),
                familyId: medicationFamily.id,
                medicationId: selectedPackage,
                qInput: chosenQuantity.toString(),
            });

            setSearchParams((previous) => {
                return {
                    ...previous,
                    ...searchParams,
                };
            });

            telemetryInstance.logEvent({
                name: 'Drugconfig apply configuration changes',
                source: 'MedicationConfiguration',
                properties: {
                    searchParams: searchParams.toString(),
                },
            });

            navigate({
                pathname: getAbsoluteRoute('medication.familyId.root', {
                    familyId: medicationFamily.id,
                }),
                search: searchParams.toString(),
            });

            closeEdit();
        }
    }, [
        selectedPackage,
        chosenQuantity,
        applyEnabled,
        selectedForm,
        selectedQuantity,
        selectedStrength,
    ]);

    function updateDaysSupply(chosenQuantity: number) {
        const packageOption = packageOptions.find(
            (option) => option.id === selectedPackage
        );

        const daysSupply = packageOption
            ? computeDaysSupplyV2(packageOption, chosenQuantity)
            : 30;

        setDaysSupply(daysSupply);
    }

    useEffect(() => {
        setBackOverrideState({
            overrideFn: closeEdit,
        });

        return () => setBackOverrideState({ overrideFn: null });
    }, []);

    return (
        <Stack gap="lg" flex="1">
            <Stack gap="lg" flex={isMobile ? '1' : '0'}>
                <Heading1>{t('drugConfig.editMedication')}</Heading1>

                <Stack gap="md">
                    <MedicationFormSelect
                        forms={forms}
                        selectedForm={selectedForm}
                        setSelectedForm={setSelectedForm}
                        setMedicationId={setMedicationId}
                    />

                    <MedicationStrengthSelect
                        strengths={strengthSortedForm.strengths}
                        selectedStrength={selectedStrength}
                        setSelectedStrength={setSelectedStrength}
                    />

                    <MedicationPackageSelect
                        packageOptions={packageOptions}
                        selectedPackage={selectedPackage}
                        setSelectedPackage={setSelectedPackage}
                    />

                    <Group justify="space-between" grow wrap="nowrap">
                        <MedicationQuantitySelect
                            quantityOptions={quantityOptions}
                            selectedQuantity={selectedQuantity}
                            setSelectedQuantity={setSelectedQuantity}
                            updateDaysSupply={updateDaysSupply}
                        />

                        {selectedQuantity === 'Custom' ? (
                            <TextInput
                                label={totalQuantity.quantityDescriptor}
                                type="number"
                                value={customQuantity}
                                onChange={(event) => {
                                    setCustomQuantity(event.target.value);
                                    updateDaysSupply(
                                        Number(event.target.value)
                                    );
                                }}
                            />
                        ) : null}
                    </Group>
                </Stack>

                <TextButton
                    justify="left"
                    LeftIcon={IconInfo}
                    disabled={!applyEnabled}
                    onClick={() => setDialogOpen(true)}
                >
                    {t('drugConfig.totalQuantityButton', {
                        quantity: totalQuantity.shortText,
                    })}
                </TextButton>

                <TotalQuantityDialog
                    isOpen={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    formulaText={totalQuantity.formulaText}
                />
            </Stack>

            <Button disabled={!applyEnabled} onClick={applyChanges}>
                {t('drugConfig.applyChanges')}
            </Button>
        </Stack>
    );
};
