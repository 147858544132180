import { NavLink } from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ConditionalComponent } from '../../../components/utility/ConditionalComponent';
import { getFragment } from '../../../graphql';
import type {
    GetBasicPrescriberOrdersByPatientQuery,
    GetBasicPrescriberOrdersQuery,
    GetPatientPriorAuthorizationsQuery,
    PriorAuthorizationInfoFragment,
} from '../../../graphql/generated/graphql';
import { usePatientContext } from '../../../hooks/use-patient-context';
import { usePrescriberOrders } from '../../../hooks/use-prescriber-orders';
import { getAbsoluteRoute } from '../../../util';
import {
    hasPriorPrescriptions,
    isBasicPrescriberOrder,
} from '../../../util/orders';

export type PrescriptionHistoryLinkProps = {
    patient:
        | NonNullable<GetBasicPrescriberOrdersQuery['patient']>
        | NonNullable<GetBasicPrescriberOrdersByPatientQuery['patient']>;
    nonDrxPatientPriorAuthorizations: NonNullable<
        GetPatientPriorAuthorizationsQuery['patient']
    >;
};

export const PrescriptionHistoryLink = ({
    patient,
    nonDrxPatientPriorAuthorizations,
}: PrescriptionHistoryLinkProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { primaryPatientId, scopedPatientId } = usePatientContext();

    const nonDrxPriorAuths = nonDrxPatientPriorAuthorizations
        ? getFragment(
              nonDrxPatientPriorAuthorizations.priorAuthorizationsConnection
          ).edges.map((edge) => edge.node as PriorAuthorizationInfoFragment)
        : null;

    const orders = usePrescriberOrders({
        patient,
        patientId: scopedPatientId ?? primaryPatientId,
        primaryPatientId,
        nonDrxPriorAuthorizations: nonDrxPriorAuths ?? undefined,
    });

    // Filter orders to include only BasicPrescriberOrderFragment items
    const basicPrescriberOrders = orders.filter(isBasicPrescriberOrder);

    return (
        <ConditionalComponent
            condition={hasPriorPrescriptions(basicPrescriberOrders)}
        >
            <NavLink
                label={t('cabinet.historyNav')}
                onClick={() => navigate(getAbsoluteRoute('cabinet.history'))}
                variant="subtle"
            />
        </ConditionalComponent>
    );
};
