import { Body1, Button, Stack, Subtitle1 } from '@phx/design-system';
import { IllustrationCard, NoResultsIllustration } from '@phx/myphx-lib';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getAbsoluteRoute } from '../../util';

export const InviteNotFound = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const goBackToCareCircle = useCallback(() => {
        navigate(getAbsoluteRoute('careCircle.root'));
    }, []);

    return (
        <IllustrationCard
            illustrationPosition="top"
            Illustration={NoResultsIllustration}
            illustrationSize="lg"
            variant="bare"
            withShadow={false}
        >
            <Stack gap="xs">
                <Stack gap="xxs">
                    <Subtitle1 ta="center">
                        {t('careCircle.invite.notFound.header')}
                    </Subtitle1>
                    <Body1 ta="center">
                        {t('careCircle.invite.notFound.body')}
                    </Body1>
                </Stack>
                <Button onClick={goBackToCareCircle}>
                    {t('careCircle.invite.notFound.backToCareCircle')}
                </Button>
            </Stack>
        </IllustrationCard>
    );
};
