import type { FieldReadFunction } from '@apollo/client';
import { getPackageRelevance } from '@phx/rx-common';

import {
    getComplexQuantityOptionsV2,
    getIndividualFormQuantitiesV2,
    getPackUnitQuantitiesV2,
    getPackageCodeQuantitiesV2,
} from '../../../util';
import type { MedicationPackageInfoFragment } from '../../generated/graphql';

function getSimpleQuantityOptions(
    packageInfo: Pick<
        MedicationPackageInfoFragment,
        | 'defaultDaysSupply'
        | 'defaultQuantity'
        | 'packageCode'
        | 'packageQuantity'
        | 'formCode'
    >,
    isPackageRelevant: boolean
) {
    const { defaultDaysSupply, packageCode, packageQuantity } = packageInfo;

    if (isPackageRelevant) {
        if (packageQuantity === 1) {
            return getPackageCodeQuantitiesV2({
                packageCode,
                defaultDaysSupply,
            });
        } else if (packageQuantity && packageQuantity > 1) {
            return getPackUnitQuantitiesV2({ defaultDaysSupply });
        }
        return [];
    }

    if (packageQuantity === 1) {
        return getIndividualFormQuantitiesV2(packageInfo);
    } else if (packageQuantity && packageQuantity > 1) {
        return getPackUnitQuantitiesV2({ defaultDaysSupply });
    }

    return [];
}

export const readPackageQuantityOptionsV2: FieldReadFunction = (
    _,
    { readField }
) => {
    const formCode = readField<string>('formCode') ?? '';
    const packageCode = readField<string>('packageCode') ?? '';
    const packageSize = readField<number>('packageSize') ?? 1;
    const packageSizeUnit = readField<string>('packageSizeUnit') ?? '';
    const packageQuantity = readField<number>('packageQuantity') ?? 1;
    const defaultQuantity = readField<number>('defaultQuantity') ?? 1;
    const defaultDaysSupply = readField<number>('defaultDaysSupply') ?? 1;

    const packageInfo = {
        formCode,
        defaultDaysSupply,
        defaultQuantity,
        packageCode,
        packageQuantity,
        packageSize,
        packageSizeUnit,
    };

    const packageRelevance = getPackageRelevance({
        formCode,
        packageCode,
        packageSizeUnit,
        packageQuantity,
    });

    // //if we could not determine relevance then we cannot show options
    if (!packageRelevance) {
        return '-';
    }
    const { isSimpleQuantity, isPackageRelevant } = packageRelevance;

    if (isSimpleQuantity) {
        return getSimpleQuantityOptions(packageInfo, isPackageRelevant);
    }

    return getComplexQuantityOptionsV2(packageInfo);
};
