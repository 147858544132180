import { Navigate, useParams } from 'react-router-dom';

import { ShoppingHeader } from '../../components/cabinet/shopping-header/ShoppingHeader';
import { DrugFamilyHeader } from '../../components/common/DrugFamilyHeader';
import { PrescriptionOverview } from '../../components/transfer-flow/PrescriptionOverview';
import { getFragment, getFragments } from '../../graphql';
import { GetProviderOffersDocument } from '../../graphql/generated/graphql';
import { useFlags } from '../../hooks/use-flags';
import { QueryLoader } from '../../loaders';
import { getAbsoluteRoute } from '../../util';

import { TransferRouteV2 } from './TransferRouteV2';

export const TransferRoute = () => {
    const { providerOffersId, offerId } = useParams();

    const { typeaheadSearchEnabled } = useFlags();

    if (typeaheadSearchEnabled) {
        return <TransferRouteV2 />;
    }

    const Loader = QueryLoader<typeof GetProviderOffersDocument>;

    if (!providerOffersId || !offerId) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    return (
        <Loader
            query={GetProviderOffersDocument}
            variables={{ id: providerOffersId }}
            render={(data) => {
                const providerOffers = getFragment(data.providerOffers);
                const offers = getFragments(providerOffers?.offers);
                const selectedOffer = offers?.find(
                    (offer) => offer.id === offerId
                );

                const product =
                    selectedOffer && getFragment(selectedOffer.product);

                const controlledSubstanceCode = getFragment(
                    providerOffers?.medication
                )?.controlledSubstanceCode;

                if (product) {
                    return (
                        <PrescriptionOverview
                            header={
                                <ShoppingHeader>
                                    <DrugFamilyHeader
                                        controlledSubstanceCode={
                                            controlledSubstanceCode
                                        }
                                        drugId={product.id}
                                    />
                                </ShoppingHeader>
                            }
                            offer={selectedOffer}
                            providerOffersId={providerOffersId}
                            controlledSubstanceCode={controlledSubstanceCode}
                        />
                    );
                }

                return <Navigate to={getAbsoluteRoute('error')} />;
            }}
        />
    );
};
