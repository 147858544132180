import {
    Body1,
    ElevatedCard,
    HorizontalDivider,
    Stack,
    Subtitle1,
} from '@phx/design-system';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type {
    IndividualCaregiver,
    PatientNetwork,
    PendingCareRecipientInvite,
} from '../../../graphql/generated/graphql';

import { Caregiver } from './Caregiver';
import { PendingCaregiver } from './PendingCaregiver';

export type CaregiverListProps = {
    network: PatientNetwork;
};

const CardHeader = () => {
    const { t } = useTranslation();

    return (
        <Stack gap="xxs">
            <Subtitle1>{t('careCircle.caregivers.heading')}</Subtitle1>
            <Body1>{t('careCircle.caregivers.subHeading')}</Body1>
        </Stack>
    );
};
export const CaregiverList = ({ network }: CaregiverListProps) => {
    const caregivers = useMemo(() => {
        return network.grants.filter(
            (grant) => grant.__typename === 'IndividualCaregiver'
        ) as IndividualCaregiver[];
    }, [network]);
    const pendingCaregivers = useMemo(() => {
        return network.pendingGrants.filter(
            (grant) =>
                grant.__typename === 'PendingCareRecipientInvite' &&
                !grant.isIncoming
        ) as PendingCareRecipientInvite[];
    }, [network]);

    if (caregivers.length === 0 && pendingCaregivers.length === 0) {
        return null;
    }

    return (
        <ElevatedCard header={<CardHeader />}>
            <Stack gap="sm">
                <HorizontalDivider />
                {pendingCaregivers.map((invite) => (
                    <PendingCaregiver key={invite.id} invite={invite} />
                ))}
                {caregivers.map((caregiver) => (
                    <Caregiver key={caregiver.id} member={caregiver} />
                ))}
            </Stack>
        </ElevatedCard>
    );
};
