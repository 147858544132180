import { Alert } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import { useMaintenanceStatus } from './hooks/use-maintenance-status';

export const MaintenanceBanner = () => {
    const { t } = useTranslation();
    const {
        showBanner,
        isMaintenanceActive,
        timeFrame,
        timeFrameStatus,
        displaySameDate,
    } = useMaintenanceStatus();

    if (!showBanner) {
        return null;
    }

    const { start, end } = timeFrame || { start: null, end: null };

    if (
        timeFrameStatus === 'past' ||
        timeFrameStatus === 'invalid' ||
        !start ||
        !end
    ) {
        return (
            <Alert severity="warning">
                {t('unauth.maintenanceBanner.label.withoutWindow')}
            </Alert>
        );
    }

    let maintenanceAlertMessage;
    if (isMaintenanceActive) {
        maintenanceAlertMessage = displaySameDate
            ? t('unauth.maintenanceBanner.label.withinWindow.sameDates', {
                  displaySameDate,
              })
            : t('unauth.maintenanceBanner.label.withinWindow.differentDates', {
                  start,
                  end,
              });
    } else {
        maintenanceAlertMessage = displaySameDate
            ? t('unauth.maintenanceBanner.label.outsideOfWindow.sameDates', {
                  displaySameDate,
              })
            : t(
                  'unauth.maintenanceBanner.label.outsideOfWindow.differentDates',
                  { start, end }
              );
    }

    return (
        <Alert severity={isMaintenanceActive ? 'warning' : 'info'}>
            {maintenanceAlertMessage}
        </Alert>
    );
};
