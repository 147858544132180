import {
    Box,
    Flex,
    HorizontalDivider,
    IconButton,
    IconInfo,
    OutlinedCard,
    Stack,
    Subtitle1,
    TextButton,
} from '@phx/design-system';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePlanMembers } from '../../hooks/use-plan-members';
import { PlanMembersModal } from '../plan-members/PlanMembersModal';

import {
    PlanMemberListItem,
    type PlanMemberListItemProps,
} from './PlanMemberListItem';

const DEFAULT_VISIBLE_PLAN_MEMBERS = 4;

export const PlanMemberList = () => {
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [showAll, setShowAll] = useState(false);
    const { t } = useTranslation();

    const closeDialog = useCallback(() => {
        setShowDialog(false);
    }, [setShowDialog]);
    const openDialog = useCallback(() => {
        setShowDialog(true);
    }, [setShowDialog]);

    const planMembers: PlanMemberListItemProps[] = usePlanMembers();

    if (planMembers.length === 0) {
        return null;
    }

    const visiblePlanMembers = showAll
        ? planMembers
        : planMembers.slice(0, DEFAULT_VISIBLE_PLAN_MEMBERS);
    const showMore = visiblePlanMembers.length < planMembers.length;

    return (
        <>
            <Box>
                <Box>
                    <Flex align="center" gap="xxs" mb="sm">
                        <Subtitle1>{t('planMembers.heading')}</Subtitle1>
                        <IconButton
                            Icon={IconInfo}
                            onClick={openDialog}
                            withStyles={false}
                        />
                    </Flex>
                    <OutlinedCard>
                        <Stack gap="sm">
                            {visiblePlanMembers.map((member, idx) => (
                                <>
                                    {idx > 0 ? <HorizontalDivider /> : null}
                                    <PlanMemberListItem
                                        key={member.patient.id}
                                        {...member}
                                    />
                                </>
                            ))}
                            {showMore ? (
                                <TextButton
                                    data-testid="see-more-plan-members-button"
                                    onClick={() => setShowAll(true)}
                                >
                                    {`${t('planMembers.seeMore')} (${planMembers.length - 4})`}
                                </TextButton>
                            ) : null}
                        </Stack>
                    </OutlinedCard>
                </Box>
                <PlanMembersModal isOpen={showDialog} close={closeDialog} />
            </Box>
            <HorizontalDivider />
        </>
    );
};
