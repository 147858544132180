import { Select } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import type { QuantityOption } from '../../graphql/generated/graphql';

export const MedicationQuantitySelect = ({
    quantityOptions,
    selectedQuantity,
    setSelectedQuantity,
    updateDaysSupply,
}: {
    quantityOptions: QuantityOption[];
    selectedQuantity: string;
    setSelectedQuantity: (value: string) => void;
    updateDaysSupply: (value: number) => void;
}) => {
    const { t } = useTranslation(['myphx']);

    const data = quantityOptions.map((option) => ({
        value: option.value,
        label: option.displayText,
    }));

    const onChange = (value: string | null) => {
        value && setSelectedQuantity(value);

        if (value !== 'Custom') {
            updateDaysSupply(Number(value));
        }
    };

    return (
        <Select
            label={t('drugConfig.quantity')}
            disabled={quantityOptions.length <= 1}
            value={selectedQuantity}
            onChange={onChange}
            data={data}
        />
    );
};
