import { Body1, Modal, Stack } from '@phx/design-system';
import { t } from 'i18next';

type NotCoveredModalProps = {
    opened: boolean;
    close: () => void;
};

export const NotCoveredModal = ({ opened, close }: NotCoveredModalProps) => {
    return (
        <Modal
            opened={opened}
            onClose={close}
            title={t('common.drugCoverageAlerts.notCovered.modalTitle')}
        >
            <Stack gap="sm">
                <Body1>
                    {t(
                        'common.drugCoverageAlerts.notCovered.modalDescriptionOne'
                    )}
                </Body1>
                <Body1>
                    {t(
                        'common.drugCoverageAlerts.notCovered.modalDescriptionTwo'
                    )}
                </Body1>
            </Stack>
        </Modal>
    );
};
