import { AuthorizedOutlet } from '@phx/auth/client';
import { Navigate, Outlet, type RouteObject } from 'react-router-dom';

import routes from '../app-routes';

import { MedicationOfferSelectionRoute } from './MedicationOfferSelectionRoute';
import { MedicationRoute } from './MedicationRoute';

export const medicationRoutes: RouteObject[] = [
    {
        path: routes.medication.root,
        element: <Outlet />,
        children: [
            {
                index: true,
                element: <Navigate to={routes.medication.root} />,
            },
            {
                path: routes.medication.familyId.root,
                element: <Outlet />,
                children: [
                    {
                        index: true,
                        element: <MedicationRoute />,
                    },
                    {
                        path: routes.medication.familyId.providerOffers.root,
                        element: <AuthorizedOutlet />,
                        children: [
                            {
                                index: true,
                                element: <MedicationOfferSelectionRoute />,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
