import { type PropsWithChildren as PWC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type PendingCareRecipientInvite } from '../../graphql/generated/graphql';
import { useAcceptInviteForm } from '../../hooks/care-circle/use-accept-invite-form';
import { useAcceptInviteMutation } from '../../hooks/care-circle/use-accept-invite-mutation';
import { useDeclineInviteMutation } from '../../hooks/care-circle/use-decline-invite-mutation';
import { usePlanMembers } from '../../hooks/use-plan-members';

import {
    AcceptInviteContext,
    type AcceptInviteCtx,
} from './accept-invite-context';

export type AcceptInviteProviderProps = {
    selectedInvite: PendingCareRecipientInvite | null;
};

export const AcceptInviteProvider = ({
    children,
    selectedInvite,
}: PWC<AcceptInviteProviderProps>) => {
    const { t } = useTranslation();
    const planMembers = usePlanMembers();
    const [error, setErr] = useState<string | null>(null);

    const setError = useCallback((err: string | null) => {
        if (err) {
            switch (err) {
                case 'INVALID_DETAILS':
                    setErr(t('careCircle.invite.error.invalidDetails'));
                    return;
                default:
                    setErr(t('careCircle.invite.error.generic'));
                    return;
            }
        }
        setErr(null);
    }, []);

    const relatedCareRecipient = planMembers.find(
        (member) =>
            member.patient.fullName === selectedInvite?.careRecipientName
    )?.patient;

    const form = useAcceptInviteForm(relatedCareRecipient);
    const { accept, isAccepting } = useAcceptInviteMutation(
        selectedInvite,
        form,
        relatedCareRecipient,
        setError
    );
    const { decline, isDeclining } = useDeclineInviteMutation(
        selectedInvite,
        setError
    );

    const clearError = useCallback(() => {
        setErr(null);
    }, []);

    const ctx: AcceptInviteCtx = {
        accept,
        clearError,
        decline,
        error,
        form,
        isAccepting,
        isDeclining,
        relatedCareRecipient,
        selectedInvite,
        setError,
    };

    return (
        <AcceptInviteContext.Provider value={ctx}>
            {children}
        </AcceptInviteContext.Provider>
    );
};
