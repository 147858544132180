import { Body1, Button, Flex, Modal, Stack } from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { useTranslation } from 'react-i18next';

import { useAcceptInviteContext } from '../../hooks/care-circle/use-accept-invite-context';

export const DeclineInviteButton = () => {
    const { t } = useTranslation();
    const [showModal, { open, close }] = useDisclosure();
    const { decline, isAccepting, isDeclining, selectedInvite } =
        useAcceptInviteContext();

    return (
        <>
            <Button
                disabled={isAccepting || isDeclining}
                variant="outline"
                onClick={open}
            >
                {t('careCircle.invite.respond.buttons.decline')}
            </Button>
            <Modal
                opened={showModal || isDeclining}
                onClose={close}
                title={t('careCircle.invite.decline.heading')}
            >
                <Stack gap="lg">
                    <Body1>
                        {t('careCircle.invite.decline.body', {
                            recipient: selectedInvite!.careRecipientName,
                        })}
                    </Body1>
                    <Flex gap="sm">
                        <Button
                            disabled={isDeclining}
                            variant="outline"
                            onClick={close}
                            flex={1}
                        >
                            {t('careCircle.invite.decline.buttons.cancel')}
                        </Button>
                        <Button
                            loading={isDeclining}
                            disabled={isDeclining}
                            color="error"
                            onClick={decline}
                            flex={1}
                        >
                            {t('careCircle.invite.decline.buttons.confirm')}
                        </Button>
                    </Flex>
                </Stack>
            </Modal>
        </>
    );
};
