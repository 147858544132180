import {
    Avatar,
    Body1,
    Body2,
    Box,
    Flex,
    IconButton,
    IconChevronRight,
    OutlinedCard,
    Subtitle1,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import { getRelationshipDisplay } from '../../util/patient/get-relationship-display';
import type { PlanMemberListItemProps } from '../plan-members/PlanMemberListItem';

export type PendingCareCircleMemberCardProps = PlanMemberListItemProps & {
    onClick: (patient: PlanMemberListItemProps['patient']) => void;
};

export const PendingCareCircleMemberCard = ({
    onClick,
    ...member
}: PendingCareCircleMemberCardProps) => {
    const { t } = useTranslation();

    return (
        <OutlinedCard onClick={() => onClick(member.patient)}>
            <Flex gap="xs">
                <Avatar initials={member.patient.initials} />
                <Box flex={1}>
                    <Flex gap="xxs">
                        <Subtitle1>{member.patient.fullName}</Subtitle1>
                        <Body1>
                            {'('}
                            {t('member.age', { age: member.patient.age })}
                            {')'}
                        </Body1>
                    </Flex>
                    <Body2>
                        {getRelationshipDisplay(member.relationshipType)}
                    </Body2>
                </Box>
                <IconButton color="gray" Icon={IconChevronRight} />
            </Flex>
        </OutlinedCard>
    );
};
