import { Anchor, Body1, Box, Modal } from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { Trans, useTranslation } from 'react-i18next';

import { DeniedDescription } from './DeniedDescription';

export const AppealRedeterminationDescription = ({
    trackingNumber,
    appealBeforeDate,
    rationale,
    appealed,
}: {
    trackingNumber?: string | null;
    appealBeforeDate?: string | null;
    rationale?: string | null;
    appealed?: boolean;
}) => {
    const { t } = useTranslation();
    const [opened, { toggle }] = useDisclosure();

    return (
        <Box>
            <Body1>
                {appealed ? (
                    <Trans
                        i18nKey="priorAuthorization.statusPage.appealed.description"
                        components={{
                            1: <Anchor onClick={toggle} />,
                        }}
                    />
                ) : (
                    <Trans
                        i18nKey="priorAuthorization.statusPage.redetermination.description"
                        components={{
                            1: <Anchor onClick={toggle} />,
                        }}
                    />
                )}
            </Body1>
            <Modal
                title={t('priorAuth.decisionDetails.modal.title')}
                opened={opened}
                onClose={toggle}
            >
                <DeniedDescription
                    trackingNumber={trackingNumber}
                    appealBeforeDate={appealBeforeDate}
                    rationale={rationale}
                />
            </Modal>
        </Box>
    );
};
