import { getFragment, getFragments } from '../graphql';
import {
    type CashOfferInfoFragment,
    type GetPrescriptionOffersQuery,
    type InsuranceOfferInfoFragment,
    type ProviderOffersInfoFragment,
    type SuggestedProviderOffersInfoFragment,
    SuggestedProviderOffersReason,
} from '../graphql/generated/graphql';
import { getCheapestPatientCostOffer } from '../util/offers/get-cheapest-patient-cost-offer';

const getSuggestedCheapestOffer = (
    suggestions: SuggestedProviderOffersInfoFragment[],
    providerOffers: ProviderOffersInfoFragment[]
) => {
    const cheapest = suggestions.find(
        (suggestion) =>
            suggestion.reason === SuggestedProviderOffersReason.Cheapest
    );

    const providerOffer = providerOffers.find(
        (offer) => offer.id === cheapest?.targetId
    );

    if (providerOffer) {
        const fragments = getFragments(providerOffer.offers).filter(
            (offer) =>
                offer.__typename === 'CashOffer' ||
                offer.__typename === 'InsuranceOffer'
        );

        return getCheapestPatientCostOffer(
            fragments as (CashOfferInfoFragment | InsuranceOfferInfoFragment)[]
        );
    }
};

export const usePrescriptionSubstitution = (
    data: GetPrescriptionOffersQuery
) => {
    if (
        data.prescriberOrderV2?.__typename ===
        'DigitallyReceivedPrescriberOrder'
    ) {
        const prescriberOrder = getFragment(data.prescriberOrderV2);

        const authorizedMedication = getFragment(
            prescriberOrder.authorizedMedication
        );
        const prescribedMedication = getFragment(
            prescriberOrder.prescribedMedication
        );

        const isMedicationSubstituted =
            authorizedMedication.id !== prescribedMedication.id;

        const authorizedMedicationConnection = getFragment(
            data.prescriberOrderV2.authorizedMedication.offersConnection
        );
        const prescribedMedicationConnection = getFragment(
            data.prescriberOrderV2.prescribedMedication.offersConnection
        );

        const authorizedProviderOffers =
            authorizedMedicationConnection?.edges.map((edge) =>
                getFragment(edge.node)
            );

        const prescribedProviderOffers =
            prescribedMedicationConnection?.edges.map((edge) =>
                getFragment(edge.node)
            );

        //calculate savings between the two if substituted.
        let authorizedMedicationSavings = 0;

        if (isMedicationSubstituted) {
            const cheapestAuthorizedOffer = getSuggestedCheapestOffer(
                getFragments(authorizedMedicationConnection?.suggested) ?? [],
                authorizedProviderOffers ?? []
            );

            const cheapestPrescribedOffer = getSuggestedCheapestOffer(
                getFragments(prescribedMedicationConnection?.suggested) ?? [],
                prescribedProviderOffers ?? []
            );

            if (cheapestAuthorizedOffer && cheapestPrescribedOffer) {
                authorizedMedicationSavings =
                    cheapestPrescribedOffer.patientResponsibilityCost -
                    cheapestAuthorizedOffer.patientResponsibilityCost;
            }
        }

        return {
            authorizedMedicationConnection,
            prescribedMedicationConnection,
            authorizedMedicationSavings,
        };
    }

    return {
        authorizedMedicationConnection: undefined,
        prescribedMedicationConnection: undefined,
        authorizedMedicationSavings: 0,
    };
};
