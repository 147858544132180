import type { PlanMemberRelationshipType } from '../../components/plan-members/PlanMemberListItem';
import type { useProfile } from '../../hooks/use-profile';

import { getRelationshipDisplay } from './get-relationship-display';

export const getCurrentUserRelationship = (
    profile: ReturnType<typeof useProfile>['profile'],
    patientId: string
): string => {
    for (const edge of profile.patientRelationshipConnection.edges) {
        const { relationshipType, relatedAccountId } = edge.relationship;
        const dependentType = relationshipType as PlanMemberRelationshipType;

        if (relatedAccountId === patientId) {
            return getRelationshipDisplay(dependentType);
        }
    }

    return getRelationshipDisplay('PRIMARY');
};
