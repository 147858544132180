/**
 * Determines the status of the current time relative to a given time window
 *
 * @param start - The start time in ISO-8601 string format
 * @param end - The end time in ISO-8601 string format
 * @returns Returns 'within', 'past', 'future', or 'invalid' based on the current time and the window
 */
export const getCurrentTimeWindowStatus = (
    start?: string | null,
    end?: string | null
): 'within' | 'past' | 'future' | 'invalid' => {
    if (!start || !end) {
        return 'invalid';
    }

    const startTime = new Date(start);
    const endTime = new Date(end);
    const currentTime = new Date();

    if (
        isNaN(startTime.getTime()) ||
        isNaN(endTime.getTime()) ||
        startTime > endTime
    ) {
        return 'invalid';
    }

    if (currentTime < startTime) {
        return 'future';
    }

    if (currentTime > endTime) {
        return 'past';
    }

    return 'within';
};
