import { useMutation } from '@apollo/client';
import {
    Alert,
    Body1,
    Button,
    Checkbox,
    Flex,
    Heading1,
    HorizontalDivider,
    List,
    ListItem,
    PhoneNumberInput,
    Stack,
    Subtitle1,
    isValidPhoneNumber,
} from '@phx/design-system';
import { type ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
    GetCareCircleDocument,
    InviteCaregiverDocument,
} from '../../graphql/generated/graphql';
import { usePatientContext } from '../../hooks/use-patient-context';
import { getAbsoluteRoute } from '../../util';

export const SendInviteRoute = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { primaryPatientId, scopedPatientId } = usePatientContext();
    const [params] = useSearchParams();

    // @TODO: error handling / checking for missing params
    const caregiverId = params.get('caregiverId')!;
    const firstName = params.get('firstName')!;
    const lastName = params.get('lastName')!;
    const phoneNumber = params.get('phoneNumber');

    const [
        inviteCaregiver,
        {
            called: inviteCaregiverCalled,
            error: inviteCaregiverError,
            loading: inviteCaregiverLoading,
        },
    ] = useMutation(InviteCaregiverDocument, {
        onCompleted: () => {
            navigate({
                pathname: getAbsoluteRoute('careCircle.root'),
                search: new URLSearchParams({
                    invitedCaregiver: `${firstName} ${lastName}`,
                }).toString(),
            });
        },
        refetchQueries: [GetCareCircleDocument],
        awaitRefetchQueries: true,
    });

    const [hasPermission, setHasPermission] = useState(false);
    const [manualPhoneNumber, setManualPhoneNumber] = useState('');

    const handlePhoneChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setManualPhoneNumber(e.target.value);
        },
        []
    );

    const disableButton =
        (inviteCaregiverCalled && !inviteCaregiverError) ||
        !(
            hasPermission &&
            (phoneNumber || isValidPhoneNumber(manualPhoneNumber, true))
        );

    const handleSubmit = useCallback(() => {
        void inviteCaregiver({
            variables: {
                careRecipientId: scopedPatientId ?? primaryPatientId,
                input: {
                    caregiverId,
                    firstName,
                    lastName,
                    phoneNumber: phoneNumber || manualPhoneNumber,
                    hasCareRecipientConsent: true,
                },
            },
        });
    }, [
        caregiverId,
        disableButton,
        firstName,
        lastName,
        manualPhoneNumber,
        phoneNumber,
        primaryPatientId,
        scopedPatientId,
    ]);

    return (
        <Stack gap="lg" flex={1}>
            <Flex gap="xs" align="center">
                <Heading1>{t('careCircle.invite.send.heading')}</Heading1>
            </Flex>
            <Stack gap="xs">
                <Body1>
                    {t('careCircle.invite.send.content.access', {
                        firstName,
                        lastName,
                    })}
                </Body1>
                <List>
                    <ListItem>
                        <Body1>
                            {t('careCircle.invite.send.content.item1')}
                        </Body1>
                    </ListItem>
                    <ListItem>
                        <Body1>
                            {t('careCircle.invite.send.content.item2')}
                        </Body1>
                    </ListItem>
                    <ListItem>
                        <Body1>
                            {t('careCircle.invite.send.content.item3')}
                        </Body1>
                    </ListItem>
                </List>
            </Stack>
            <Checkbox
                onChange={setHasPermission}
                label={t('careCircle.invite.send.authorize')}
            />
            <HorizontalDivider />
            <Subtitle1>
                {t('careCircle.invite.send.subtitle', { firstName })}
            </Subtitle1>
            {phoneNumber ? (
                <Stack gap="xs">
                    <Body1>
                        {t('careCircle.invite.send.phoneDesc', { firstName })}
                    </Body1>
                    <Body1 bold>
                        {t('careCircle.invite.send.phone', {
                            phoneLast4: phoneNumber
                                .replaceAll(/\D/g, '')
                                .slice(-4),
                        })}
                    </Body1>
                </Stack>
            ) : (
                <PhoneNumberInput
                    placeholder={t('careCircle.invite.inputs.phoneNumber')}
                    onChange={handlePhoneChange}
                    value={manualPhoneNumber}
                />
            )}
            <Alert severity="info">{t('careCircle.invite.send.info')}</Alert>
            <Button
                loading={inviteCaregiverLoading}
                disabled={disableButton}
                onClick={handleSubmit}
            >
                {t('careCircle.invite.send.button.grantAccess')}
            </Button>
        </Stack>
    );
};
