import { ClaimHistoryV2 } from '../../components/claim-history/ClaimHistoryV2';
import { GetCoverageMembersDocument } from '../../graphql/generated/graphql';
import { usePatientContext } from '../../hooks/use-patient-context';
import { QueryLoader } from '../../loaders';

const ClaimHistoryV2Route = () => {
    const { primaryPatientId: patientId } = usePatientContext();
    const Loader = QueryLoader<typeof GetCoverageMembersDocument>;

    return (
        <Loader
            query={GetCoverageMembersDocument}
            variables={{
                patientId,
            }}
            queryOptions={{ errorPolicy: 'all' }}
            component={ClaimHistoryV2}
        />
    );
};

export default ClaimHistoryV2Route;
