import { useContext } from 'react';

import { AcceptInviteContext } from '../../contexts/care-circle/accept-invite-context';

export const useAcceptInviteContext = () => {
    const context = useContext(AcceptInviteContext);

    if (!context) {
        throw new Error(
            'useAcceptInviteContext must be used within a AcceptInviteProvider'
        );
    }

    return context;
};
