import { Stack } from '@phx/design-system';

import { useFlags } from '../../hooks/use-flags';

export const CobrandedLogo = () => {
    const { cobrandingEnabled } = useFlags();

    // TODO: remove redundant code. i don't want to store partner logo in myphx-lib
    // but also null check doesn't work well with this component. going to follow
    // up and not do this
    if (!cobrandingEnabled) {
        return (
            <img src="/static/libraries/myphx-lib/logos/prescryptive-logo-white-text.svg" />
        );
    }

    return (
        <Stack ml="auto" mr="auto">
            <img src="/static/img/partner-alternate-logo.svg" width="85%" />
        </Stack>
    );
};
