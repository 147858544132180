import { Body1, Flex } from '@phx/design-system';
import type { ReactNode } from 'react';

export type DeliveryStepProps = {
    stepTexts: Array<
        | string
        | {
              key: string;
              value: ReactNode;
          }
    >;
};

export const DeliveryStep = ({ stepTexts }: DeliveryStepProps) => (
    <>
        {stepTexts.map((step) => {
            if (typeof step === 'string') {
                return (
                    <Flex key={step} align="start">
                        <Body1>{step}</Body1>
                    </Flex>
                );
            }

            return (
                <Flex key={step.key} align="start">
                    {step.value}
                </Flex>
            );
        })}
    </>
);
