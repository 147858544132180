import { getFragment } from '../../graphql';
import type {
    CashOfferInfoFragment,
    InsuranceOfferInfoFragment,
    PharmacyProvider,
    ProviderOffersInfoFragment,
} from '../../graphql/generated/graphql';

import { getMinCost, getMinPlanPays } from './get-minimum-cost';

type SortFunction = (
    offers: ProviderOffersInfoFragment[]
) => ProviderOffersInfoFragment[];

export const sortOptions = {
    distance: 'distance',
    price: 'price',
    planPays: 'planPays',
    deliveryTime: 'deliveryTime',
} as const;

export type SortOption = keyof typeof sortOptions;

function getProviderName(providerOffers: ProviderOffersInfoFragment) {
    const fragment = getFragment(providerOffers.provider);

    switch (fragment.__typename) {
        case 'ChainProvider':
            return fragment.chainName;
        case 'MailOrderProvider':
        case 'PharmacyProvider':
        default:
            return (fragment as PharmacyProvider)?.name ?? 'Unknown';
    }
}

function secondaryCompare(
    a: ProviderOffersInfoFragment,
    b: ProviderOffersInfoFragment
) {
    if (
        a.provider.__typename === 'MailOrderProvider' &&
        b.provider.__typename === 'MailOrderProvider'
    ) {
        const aName = getProviderName(a);
        const bName = getProviderName(b);

        return aName?.localeCompare(bName);
    }

    return a.distance - b.distance;
}

/**
 * Sorts an array of provider offers by the minimum amount that the insurance plan pays.
 *
 * @param offers An array of provider offers
 * @returns The sorted array of provider offers by the minimum plan pays amount
 */
export function sortOffersByPlanPays(offers: ProviderOffersInfoFragment[]) {
    return offers.toSorted((a, b) => {
        const aMinPlanPays = getMinPlanPays(
            a.offers as InsuranceOfferInfoFragment[]
        );
        const bMinPlanPays = getMinPlanPays(
            b.offers as InsuranceOfferInfoFragment[]
        );
        if (aMinPlanPays === bMinPlanPays) {
            return secondaryCompare(a, b);
        }
        return aMinPlanPays - bMinPlanPays;
    });
}

/**
 * Sorts an array of provider offers by the lowest cost between cash and insurance offers.
 *
 * @param offers An array of provider offers
 * @returns The sorted array of provider offers by the lowest cost
 */
export function sortOffersByPrice(offers: ProviderOffersInfoFragment[]) {
    return offers.toSorted((a, b) => {
        const aMinCost = getMinCost(
            a.offers as (CashOfferInfoFragment | InsuranceOfferInfoFragment)[]
        );
        const bMinCost = getMinCost(
            b.offers as (CashOfferInfoFragment | InsuranceOfferInfoFragment)[]
        );
        if (aMinCost === bMinCost) {
            return secondaryCompare(a, b);
        }
        return aMinCost - bMinCost;
    });
}

/**
 * Sorts an array of provider offers by distance.
 *
 * @param offers An array of provider offers
 * @returns The sorted array of provider offers by closest distance to furthest
 */
export function sortOffersByDistance(offers: ProviderOffersInfoFragment[]) {
    return offers.toSorted((a, b) => a.distance - b.distance);
}

/**
 * Sorts an array of provider offers by delivery time.
 * Prioritizes the highest delivery time (either deliveryDaysMax or deliveryDaysMin if deliveryDaysMax is not
 * available). Offers with null delivery times are treated as Infinity, ensuring they are sorted to the end.
 * @param offers An array of provider offers
 * @returns The sorted array of provider offers by fastest delivery time to slowest
 */

function getMinMaxDeliveryDays(offer: ProviderOffersInfoFragment) {
    let min = Infinity;
    let max = Infinity;
    if (offer.provider.__typename === 'MailOrderProvider') {
        const fragment = getFragment(offer.provider);
        min = fragment.deliveryDaysMin ?? Infinity;
        max = fragment.deliveryDaysMax ?? Infinity;
    }
    return {
        min,
        max,
    };
}

export function sortOffersByDeliveryTime(offers: ProviderOffersInfoFragment[]) {
    return offers.toSorted((a, b) => {
        const aDeliveryTimes = getMinMaxDeliveryDays(a);
        const bDeliveryTimes = getMinMaxDeliveryDays(b);

        if (aDeliveryTimes.max === bDeliveryTimes.max) {
            return aDeliveryTimes.min - bDeliveryTimes.min;
        }

        return aDeliveryTimes.max - bDeliveryTimes.max;
    });
}

const sortFunctions: Map<SortOption, SortFunction> = new Map([
    [sortOptions.distance, sortOffersByDistance],
    [sortOptions.deliveryTime, sortOffersByDeliveryTime],
    [sortOptions.planPays, sortOffersByPlanPays],
    [sortOptions.price, sortOffersByPrice],
]);

/**
 * Sorts an array of provider offers based on the selected sorting option.
 *
 * @param offers An array of provider offers
 * @param sortBy The sorting option selected (e.g., distance, delivery time, price)
 * @returns The sorted array of provider offers based on the selected sorting criteria
 */
export function sortProviderOffers(
    offers: ProviderOffersInfoFragment[],
    sortBy: SortOption
): ProviderOffersInfoFragment[] {
    const sortFunction = sortFunctions.get(sortBy);
    return sortFunction ? sortFunction(offers) : offers;
}
