import { Button, Flex } from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CareCircleLearnMoreButton } from '../../../components/care-circle/CareCircleLearnMoreButton';
import { getAbsoluteRoute } from '../../../util';

export const CareCircleFooter = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <Flex gap="xs">
                <Button flex={1} variant="outline">
                    {t('careCircle.footer.request')}
                </Button>
                <Button
                    flex={1}
                    onClick={() =>
                        navigate(getAbsoluteRoute('careCircle.invite.root'))
                    }
                >
                    {t('careCircle.footer.invite')}
                </Button>
            </Flex>
            <CareCircleLearnMoreButton />
        </>
    );
};
