import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
const launchDarklyAnonContext = {
    kind: 'user',
    anonymous: true,
};
export const makeFlagProvider = async (clientId, application) => {
    return await asyncWithLDProvider({
        context: launchDarklyAnonContext,
        clientSideID: clientId,
        options: {
            application,
            allAttributesPrivate: true,
        },
    });
};
