import {
    Body1,
    Flex,
    Heading1,
    HorizontalDivider,
    List,
    ListItem,
    Stack,
    TextSkeleton,
    useNavBar,
} from '@phx/design-system';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { AcceptInviteForm } from '../../components/care-circle/AcceptInviteForm';
import { AcceptInviteError } from '../../components/care-circle/invite/AcceptInviteError';
import { PermissionCheckbox } from '../../components/care-circle/invite/PermissionCheckbox';
import { InviteNotFound } from '../../components/care-circle/InviteNotFound';
import { ConditionalComponent } from '../../components/utility/ConditionalComponent';
import { AcceptInviteProvider } from '../../contexts/care-circle/AcceptInviteProvider';
import type { PendingCareRecipientInvite } from '../../graphql/generated/graphql';
import { useCareCircle } from '../../hooks/use-care-circle';
import { getAbsoluteRoute } from '../../util';

export const InviteByIdRoute = () => {
    const { t } = useTranslation();
    const { inviteId } = useParams();
    const { network, loading } = useCareCircle();
    const { setBackOverrideState } = useNavBar();

    const selectedInvite = (network?.pendingGrants?.find(
        (grant) =>
            grant.id === inviteId &&
            grant.__typename === 'PendingCareRecipientInvite'
    ) ?? null) as PendingCareRecipientInvite | null;

    const navigate = useNavigate();

    const goBackToCareCircle = useCallback(() => {
        navigate(getAbsoluteRoute('careCircle.root'));
    }, []);

    useEffect(() => {
        setBackOverrideState({ overrideFn: goBackToCareCircle });

        return () => {
            setBackOverrideState({ overrideFn: null });
        };
    }, []);

    if (!loading && !selectedInvite) {
        return <InviteNotFound />;
    }

    return (
        <AcceptInviteProvider selectedInvite={selectedInvite}>
            <Stack gap="lg" flex={1}>
                <AcceptInviteError />
                <Flex gap="xs" align="center">
                    <TextSkeleton visible={loading}>
                        <Heading1>
                            {t('careCircle.invite.respond.heading', {
                                recipient: selectedInvite!.careRecipientName,
                            })}
                        </Heading1>
                    </TextSkeleton>
                </Flex>
                <Stack gap="xs">
                    <TextSkeleton visible={loading}>
                        <Body1>
                            {t('careCircle.invite.respond.details.desc')}
                        </Body1>
                    </TextSkeleton>
                    <TextSkeleton visible={loading}>
                        <List>
                            <ListItem>
                                <Body1>
                                    {t(
                                        'careCircle.invite.respond.details.item1'
                                    )}
                                </Body1>
                            </ListItem>
                            <ListItem>
                                <Body1>
                                    {t(
                                        'careCircle.invite.respond.details.item2'
                                    )}
                                </Body1>
                            </ListItem>
                            <ListItem>
                                <Body1>
                                    {t(
                                        'careCircle.invite.respond.details.item3'
                                    )}
                                </Body1>
                            </ListItem>
                        </List>
                    </TextSkeleton>
                </Stack>
                <TextSkeleton visible={loading}>
                    <PermissionCheckbox />
                </TextSkeleton>
                <ConditionalComponent condition={!loading}>
                    <HorizontalDivider />
                    <AcceptInviteForm />
                </ConditionalComponent>
            </Stack>
        </AcceptInviteProvider>
    );
};
