import type { FieldReadFunction } from '@apollo/client';

import { formatMedicationDescription } from './format-medication-description';

export const readMedicationDescription: FieldReadFunction = (
    _,
    { readField }
) => {
    const formCode = readField<string>('formCode') ?? '';
    const name = readField<string>('name') ?? '';
    const packageQuantity = readField<number>('packageQuantity') ?? 1;
    const strengthValue = readField<string>('strengthValue') ?? '';
    const strengthUnit = readField<string>('strengthUnit') ?? '';

    return formatMedicationDescription({
        formCode,
        name,
        packageQuantity,
        strengthValue,
        strengthUnit,
    });
};
