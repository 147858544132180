import type { RouteObject } from 'react-router-dom';

import AppRoot from '../components/layout/AppRoot';
import Maintenance from '../components/maintenance/Maintenance';

import routes from './app-routes';
import UnauthHomeRoute from './auth/UnauthHomeRoute';
import helpCenterRoutes from './help-center';
import languageRoutes from './language';
import startSavingRoutes from './start-saving';

const maintenanceRoot: RouteObject = {
    path: routes.root,
    element: <AppRoot />,
    errorElement: <Maintenance />,
    children: [
        { index: true, element: <UnauthHomeRoute /> },
        ...helpCenterRoutes,
        ...startSavingRoutes,
        ...languageRoutes,
    ],
};

export default maintenanceRoot;
