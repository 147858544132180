import { Navigate, useParams } from 'react-router-dom';

import { PriorAuthorizationStatus } from '../../components/prior-authorization/PriorAuthorizationStatus';
import { getFragment } from '../../graphql';
import { GetPriorAuthorizationDocument } from '../../graphql/generated/graphql';
import { useFlags } from '../../hooks/use-flags';
import { QueryLoader } from '../../loaders';
import { getAbsoluteRoute } from '../../util';

const NonDrxPriorAuthorizationStatusRoute = () => {
    const { priorAuthorizationId } = useParams();
    const { nonDrxPriorAuthorizationEnabled } = useFlags();

    if (!nonDrxPriorAuthorizationEnabled) return null;

    const Loader = QueryLoader<typeof GetPriorAuthorizationDocument>;

    if (!priorAuthorizationId) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    return (
        <Loader
            query={GetPriorAuthorizationDocument}
            variables={{
                priorAuthorizationId,
            }}
            queryOptions={{ errorPolicy: 'all' }}
            render={(data) => {
                const priorAuthInfo = getFragment(data.priorAuthorization);

                if (!priorAuthInfo) {
                    return <Navigate to={getAbsoluteRoute('error')} />;
                }

                const location = getFragment(
                    priorAuthInfo.assignedPharmacyProvider
                );

                return (
                    <PriorAuthorizationStatus
                        location={location}
                        priorAuthInfo={priorAuthInfo}
                    />
                );
            }}
        />
    );
};

export default NonDrxPriorAuthorizationStatusRoute;
