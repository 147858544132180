import { IconWarning, TextButton } from '@phx/design-system';
import { t } from 'i18next';

type NotCoveredAlertProps = {
    toggle?: () => void;
};

export const NotCoveredAlert = ({ toggle }: NotCoveredAlertProps) => {
    return (
        <>
            <TextButton
                LeftIcon={IconWarning}
                color="warning"
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    toggle?.();
                }}
            >
                {t('common.drugCoverageAlerts.notCovered.buttonText')}
            </TextButton>
        </>
    );
};
