import {
    Body1,
    Box,
    IconChevronRight,
    Stack,
    Subtitle1,
    TextButton,
} from '@phx/design-system';
import { FamilyIllustration, IllustrationCard } from '@phx/myphx-lib';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useFlags } from '../../hooks/use-flags';
import { getAbsoluteRoute } from '../../util';

export const CareCircleCard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { enableCareCircleUi } = useFlags();

    if (!enableCareCircleUi) {
        return null;
    }

    return (
        <IllustrationCard
            Illustration={FamilyIllustration}
            variant="outlined"
            color="info"
            header={<Subtitle1>{t('home.careCircleCard.heading')}</Subtitle1>}
            onClick={() => navigate(getAbsoluteRoute('careCircle.root'))}
            role="tab"
        >
            <Stack gap="xs">
                <Body1>{t('home.careCircleCard.body')}</Body1>
                <Box>
                    <TextButton RightIcon={IconChevronRight}>
                        {t('home.careCircleCard.button')}
                    </TextButton>
                </Box>
            </Stack>
        </IllustrationCard>
    );
};
