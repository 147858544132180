import { Select } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import { getFragments } from '../../graphql';
import type { MedicationFormInfoFragment } from '../../graphql/generated/graphql';

export const MedicationFormSelect = ({
    forms,
    selectedForm,
    setSelectedForm,
    setMedicationId,
}: {
    forms: MedicationFormInfoFragment[];
    selectedForm?: MedicationFormInfoFragment;
    setSelectedForm: (value: MedicationFormInfoFragment) => void;
    setMedicationId: (value: string) => void;
}) => {
    const { t } = useTranslation(['myphx', 'code-locales/form-code-mapping']);

    const data = forms.map((form) => {
        return {
            value: JSON.stringify(form),
            label: t(form.formCode, {
                ns: 'code-locales/form-code-mapping',
                count: 1,
            }),
        };
    });

    const onChange = (value: string | null) => {
        // this is a temporary work around until we implement routeOfAdministration work
        if (value) {
            const form = JSON.parse(value) as MedicationFormInfoFragment;
            const strengths = getFragments(form.strengths);
            const selectedMedicationId = strengths
                .map((strength) => getFragments(strength.packages))
                .flat()
                .find((pkg) => pkg.id)?.id;

            selectedMedicationId && setMedicationId(selectedMedicationId);

            setSelectedForm(JSON.parse(value));
        }
    };

    return (
        <Select
            placeholder="-"
            disabled={forms.length <= 1}
            label={t('drugConfig.form')}
            value={JSON.stringify(selectedForm)}
            onChange={onChange}
            data={data}
        />
    );
};
