import { useDisclosure } from '@phx/design-system/hooks';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getStatusTranslation } from '../components/prior-authorization/components/PriorAuthStatusDescriptions';
import { getFragment } from '../graphql';
import type { PriorAuthorizationInfoFragment } from '../graphql/generated/graphql';
import { getAbsoluteRoute } from '../util';
import {
    type BasicPrescriberOrderFragment,
    getMedicationDescription,
    getPrescribedProductFragment,
    isUnclaimedPrescriberOrder,
} from '../util/cabinet/prescriber-orders';
import { isBasicPrescriberOrder } from '../util/orders';

import { useFlags } from './use-flags';
import { usePriorAuthorizationDetails } from './use-prior-authorization-details';

export const useMedicationOrderDetails = (
    order: BasicPrescriberOrderFragment | PriorAuthorizationInfoFragment
) => {
    const { drugDetailsSplitEnabled } = useFlags();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [
        showUnclaimedModal,
        { open: openUnclaimedModal, close: closeUnclaimedModal },
    ] = useDisclosure(false);

    const isUnclaimed = isBasicPrescriberOrder(order)
        ? isUnclaimedPrescriberOrder(order)
        : false;
    const digitallyPrescribedProductId = isBasicPrescriberOrder(order)
        ? (order.drxId ?? order.id)
        : null;

    const { isPriorAuthorization, priorAuthorizationDetails } =
        usePriorAuthorizationDetails(order);

    const fillOrder = useCallback(() => {
        if (digitallyPrescribedProductId) {
            navigate(
                getAbsoluteRoute('cabinet.rxId.root', {
                    prescriptionId: digitallyPrescribedProductId,
                })
            );
        }
    }, [digitallyPrescribedProductId, navigate]);

    const verifyOrder = useCallback(() => {
        if (digitallyPrescribedProductId) {
            navigate(
                getAbsoluteRoute('cabinet.rxId.verifyPatient', {
                    prescriptionId: digitallyPrescribedProductId,
                })
            );
        }
    }, [digitallyPrescribedProductId, navigate]);

    const prescribedProduct = isBasicPrescriberOrder(order)
        ? getFragment(getPrescribedProductFragment(order))
        : null;
    const nonDrxPaProduct = getFragment(priorAuthorizationDetails?.medication);
    const productForm =
        prescribedProduct?.__typename === 'Product'
            ? prescribedProduct?.formValue
            : nonDrxPaProduct?.formCode;

    const description = isBasicPrescriberOrder(order)
        ? getMedicationDescription(order)
        : isPriorAuthorization
          ? t('priorAuth.nonDrxPa.description', {
                nonDrxDescription: nonDrxPaProduct?.name,
            })
          : null;

    const clickHandler = useMemo(() => {
        if (isUnclaimed) {
            return openUnclaimedModal;
        }

        if (priorAuthorizationDetails) {
            return () =>
                navigate(
                    getAbsoluteRoute(
                        'priorAuthorization.priorAuthorizationId.details',
                        {
                            priorAuthorizationId: priorAuthorizationDetails.id,
                        }
                    ),
                    { state: { prev: 'history' } }
                );
        }

        if (digitallyPrescribedProductId) {
            return () =>
                navigate(
                    getAbsoluteRoute('orders.prescriberOrderId.details', {
                        prescriberOrderId: digitallyPrescribedProductId,
                    }),
                    { state: { prev: 'history' } }
                );
        }

        return undefined;
    }, [
        digitallyPrescribedProductId,
        isUnclaimed,
        openUnclaimedModal,
        priorAuthorizationDetails,
        navigate,
    ]);

    const navigateToPriorAuthStatus = useCallback(() => {
        if (priorAuthorizationDetails) {
            navigate(
                getAbsoluteRoute(
                    'priorAuthorization.priorAuthorizationId.status',
                    {
                        priorAuthorizationId: priorAuthorizationDetails.id,
                    }
                ),
                { state: { prev: 'history' } }
            );
        } else if (digitallyPrescribedProductId) {
            navigate(
                getAbsoluteRoute(
                    'orders.prescriberOrderId.priorAuthorization',
                    {
                        prescriberOrderId: digitallyPrescribedProductId,
                    }
                )
            );
        }
    }, [priorAuthorizationDetails, digitallyPrescribedProductId, navigate]);

    const patientName = isPriorAuthorization
        ? getFragment(priorAuthorizationDetails?.patient)?.fullName
        : isBasicPrescriberOrder(order) && !isUnclaimedPrescriberOrder(order)
          ? getFragment(order.patient)?.fullName
          : null;

    const drxPriorAuthorization =
        order.__typename === 'DigitallyReceivedPrescriberOrder'
            ? order.applicablePriorAuthorization
            : null;
    const status = isPriorAuthorization
        ? priorAuthorizationDetails?.status
        : getFragment(drxPriorAuthorization)?.status;
    const translatedStatus = status && getStatusTranslation(status);
    const shouldShowPaStatus = !!drxPriorAuthorization || isPriorAuthorization;

    return {
        productForm,
        description,
        drugDetailsSplitEnabled,
        prescribedProduct,
        clickHandler,
        isUnclaimed,
        patientName,
        shouldShowPaStatus,
        status: translatedStatus,
        navigateToPriorAuthStatus,
        fillOrder,
        verifyOrder,
        showUnclaimedModal,
        closeUnclaimedModal,
    };
};
