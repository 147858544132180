import { useMutation } from '@apollo/client';
import type { UseFormReturnType } from '@phx/design-system/hooks';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { PendingCareRecipientDetails } from '../../components/care-circle/care-circle-types';
import {
    type AcceptCaregiverInviteInput,
    AcceptInviteDocument,
    GetCareCircleDocument,
    type PatientInfoFragment,
    type PendingCareRecipientInvite,
} from '../../graphql/generated/graphql';
import { formatIsoDateString, getAbsoluteRoute } from '../../util';
import { usePatientContext } from '../use-patient-context';

export const useAcceptInviteMutation = (
    selectedInvite: PendingCareRecipientInvite | null,
    form: UseFormReturnType<PendingCareRecipientDetails>,
    relatedCareRecipient: PatientInfoFragment | undefined,
    setError: (err: string | null) => void
) => {
    const { primaryPatientId, scopedPatientId } = usePatientContext();
    const navigate = useNavigate();

    const acceptInviteInput = useMemo<AcceptCaregiverInviteInput>(() => {
        let birthDate = '';
        if (form.isValid()) {
            birthDate = formatIsoDateString(form.values.birthDate);
        }

        const baseInput = {
            birthDate,
            firstName: form.values.firstName,
            hasCaregiverConsent: true,
            lastName: form.values.lastName,
        };

        if (relatedCareRecipient) {
            return {
                ...baseInput,
                careRecipientId: relatedCareRecipient.id,
            };
        }

        return baseInput;
    }, [relatedCareRecipient, form.values]);

    const [acceptInvite, { loading: isAccepting }] = useMutation(
        AcceptInviteDocument,
        {
            onCompleted: ({ acceptCaregiverInvite }) => {
                if (acceptCaregiverInvite.success) {
                    navigate({
                        pathname: getAbsoluteRoute('careCircle.root'),
                        search: new URLSearchParams({
                            acceptedInviteFrom: `${acceptInviteInput!.firstName} ${acceptInviteInput!.lastName}`,
                        }).toString(),
                    });
                } else {
                    setError(acceptCaregiverInvite.message);
                }
            },
            refetchQueries: [GetCareCircleDocument],
            awaitRefetchQueries: true,
        }
    );

    const accept = useCallback(() => {
        if (!acceptInviteInput || !selectedInvite) {
            return;
        }

        setError(null);
        void acceptInvite({
            variables: {
                inviteId: selectedInvite.id,
                caregiverId: scopedPatientId ?? primaryPatientId,
                input: acceptInviteInput,
            },
        });
    }, [
        acceptInviteInput,
        primaryPatientId,
        scopedPatientId,
        selectedInvite?.id,
    ]);

    return { accept, isAccepting };
};
