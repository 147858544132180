import { SpendSummaryCard } from '@phx/myphx-lib';

import { getFragment } from '../../../graphql';
import type { PlanSummaryProps } from '../PlanSummary';

type FamilySummaryCardProps = {
    coverages: PlanSummaryProps['coverageEdges'];
};

export const FamilySummaryCard = ({ coverages }: FamilySummaryCardProps) => {
    const uniqueFamilyCoverages = coverages.reduce(
        (acc, coverage) => {
            if (
                coverage.familyAccum &&
                coverage.familyId &&
                !acc[coverage.familyId]
            ) {
                acc[coverage.familyId] = coverage;
            }
            return acc;
        },
        {} as Record<string, PlanSummaryProps['coverageEdges'][0]>
    );

    return Object.values(uniqueFamilyCoverages).map((coverage, id) => (
        <SpendSummaryCard
            key={`family-accumulator-card-${id}`}
            data-testid={`family-accumulator-card-${id}`}
            variant="family"
            deductible={{
                max: coverage.familyDeductibleMax ?? 0,
                spent:
                    getFragment(coverage.familyAccum)?.towardsDeductible ?? 0,
            }}
            outOfPocket={{
                max: coverage.familyOutOfPocketMax ?? 0,
                spent:
                    getFragment(coverage.familyAccum)?.towardsOutOfPocketMax ??
                    0,
            }}
        />
    ));
};
