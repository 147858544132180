import { type FunctionComponent as FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Navigate,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';

import { PatientProductDetails } from '../../../components/preview-details/components/PatientProductDetails';
import { OfferSelection } from '../../../components/provider-offers';
import { getFragment } from '../../../graphql';
import {
    GetProviderOffersDocument,
    type GetProviderOffersQuery,
} from '../../../graphql/generated/graphql';
import { QueryLoader } from '../../../loaders';
import { getAbsoluteRoute } from '../../../util';

export const PrescriptionOfferSelectionRoute = () => {
    const { providerOffersId } = useParams();
    const Loader = QueryLoader<typeof GetProviderOffersDocument>;

    if (!providerOffersId) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    return (
        <Loader
            query={GetProviderOffersDocument}
            variables={{
                id: providerOffersId,
            }}
            component={InnerRoute}
        />
    );
};

const InnerRoute: FC<{ data: GetProviderOffersQuery }> = ({ data }) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const { t } = useTranslation();

    const providerOffers = getFragment(data.providerOffers);

    if (providerOffers) {
        const prescriberOrder = getFragment(providerOffers?.prescriberOrder);
        const patient = getFragment(providerOffers?.patient);
        const product = getFragment(providerOffers?.product);

        const onSelect = (selectedOfferId?: string) =>
            navigate({
                pathname: getAbsoluteRoute(
                    'cabinet.rxId.providerOffersId.offer.root',
                    {
                        prescriptionId: product.id,
                        providerOffersId: providerOffers.id,
                        offerId: selectedOfferId ?? '',
                    }
                ),
                search: searchParams.toString(),
            });

        const buttonText = t('offerSelection.button.previewDetails');

        if (providerOffers.offers.length === 1) {
            return (
                <Navigate
                    to={{
                        pathname: getAbsoluteRoute(
                            'cabinet.rxId.providerOffersId.offer.root',
                            {
                                prescriptionId: product.id,
                                providerOffersId: providerOffers.id,
                                offerId: getFragment(providerOffers.offers[0])
                                    .id,
                            }
                        ),
                        search: searchParams.toString(),
                    }}
                />
            );
        }

        return (
            <OfferSelection
                providerOffers={providerOffers}
                header={
                    <PatientProductDetails
                        patientName={patient?.fullName}
                        productDescription={prescriberOrder?.productDescription}
                        quantity={prescriberOrder?.quantity}
                        prescriberOrder={prescriberOrder}
                    />
                }
                onSelect={onSelect}
                buttonText={buttonText}
            />
        );
    }

    return <Navigate to={getAbsoluteRoute('error')} />;
};
