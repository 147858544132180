import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Heading1, HorizontalDivider, Stack } from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { Navigate, useSearchParams } from 'react-router-dom';

import { getFragment } from '../../graphql';
import {
    type DigitallyReceivedPrescriberOrderInfoFragment,
    GetPrescriberOrderDocument,
    SendOfferDocument,
} from '../../graphql/generated/graphql';
import type { OfferFragment } from '../../types';
import { getAbsoluteRoute } from '../../util';
import { ShoppingHeader } from '../cabinet/shopping-header/ShoppingHeader';
import { GenericSwapPricingDesc } from '../common/pricing/GenericSwapPricingDesc';
import { Pricing } from '../common/pricing/Pricing';

import { PatientProductDetails } from './components/PatientProductDetails';
import { PharmacyProviderDetails } from './components/PharmacyProviderDetails';

export type PreviewDetailsProps = {
    offer: OfferFragment;
    prescriberOrder: DigitallyReceivedPrescriberOrderInfoFragment;
};

export const PreviewDetails = ({
    offer,
    prescriberOrder,
}: PreviewDetailsProps) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const generic = searchParams.get('generic');
    const genericName = searchParams.get('genericName');
    const brandName = searchParams.get('brandName');

    const patient = getFragment(prescriberOrder.patient);

    const [getUpdatedPrescriberOrder] = useLazyQuery(
        GetPrescriberOrderDocument,
        {
            fetchPolicy: 'network-only',
        }
    );

    const [sendOffer, { data, loading, error, called }] = useMutation(
        SendOfferDocument,
        {
            onCompleted: () => {
                setTimeout(() => {
                    getUpdatedPrescriberOrder({
                        variables: { prescriberOrderId: prescriberOrder.id },
                    });
                }, 10000);
            },
        }
    );

    if (called && error && !loading && !data?.sendOffer?.success) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    if (!loading && data?.sendOffer?.success) {
        return (
            <Navigate
                to={{
                    pathname: getAbsoluteRoute(
                        'orders.prescriberOrderId.confirmation',
                        {
                            prescriberOrderId: prescriberOrder.id,
                        }
                    ),
                    search: searchParams.toString(),
                }}
            />
        );
    }

    return (
        <Stack gap="lg">
            {!generic && (
                <>
                    <ShoppingHeader>
                        <PatientProductDetails
                            patientName={patient.fullName}
                            productDescription={
                                prescriberOrder.productDescription
                            }
                            quantity={offer.quantity}
                            prescriberOrder={prescriberOrder}
                        />
                    </ShoppingHeader>

                    <HorizontalDivider variant="full" />
                </>
            )}
            <Stack gap="xxl">
                <Stack gap="md">
                    <Heading1>{t('previewDetails.title')}</Heading1>
                    {generic && (
                        <GenericSwapPricingDesc
                            brandName={brandName!}
                            genericName={genericName!}
                        />
                    )}
                    <Pricing offer={offer} />
                </Stack>

                <PharmacyProviderDetails offer={offer} />
            </Stack>

            <Button
                size="lg"
                loading={loading}
                onClick={() => sendOffer({ variables: { offerId: offer.id } })}
            >
                {t('previewDetails.sendToPharmacy')}
            </Button>
        </Stack>
    );
};
