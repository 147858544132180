import { Body1, Modal, Stack } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

export type DAWModalProps = {
    close: () => void;
    isOpen: boolean;
};

export const DAWModal = ({ close, isOpen }: DAWModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            opened={isOpen}
            onClose={close}
            title={t('drx.product.details.modals.daw.title')}
        >
            <Stack gap="sm">
                <Body1>{t('drx.product.details.modals.daw.body1')}</Body1>
                <Body1>{t('drx.product.details.modals.daw.body2')}</Body1>
            </Stack>
        </Modal>
    );
};
