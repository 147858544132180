import {
    HorizontalDivider,
    IconGenericHome,
    NavLink,
} from '@phx/design-system';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useFlags } from '../../hooks/use-flags';
import { getAbsoluteRoute } from '../../util';

export const CareCircleLink = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { enableCareCircleUi } = useFlags();

    const viewCareCircle = useCallback(() => {
        navigate(getAbsoluteRoute('careCircle.root'));
    }, [navigate]);

    if (!enableCareCircleUi) {
        return null;
    }

    return (
        <>
            <NavLink
                label={t('profile.careCircle')}
                Icon={IconGenericHome}
                onClick={viewCareCircle}
                variant="subtle"
                data-testid="view-care-circle-button"
            />
            <HorizontalDivider />
        </>
    );
};
