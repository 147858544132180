import { Body1, Box, Modal, Stack, Subtitle2 } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

export const MedicineCabinetInfoModal = ({
    isOpen,
    close,
}: {
    isOpen: boolean;
    close: () => void;
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            opened={isOpen}
            onClose={close}
            title={t('cabinet.infoModal.header')}
        >
            <Stack gap="sm">
                <Box>
                    <Subtitle2>{t('cabinet.orders.verify')}</Subtitle2>
                    <Body1>{t('cabinet.infoModal.verify')}</Body1>
                </Box>
                <Box>
                    <Subtitle2>{t('cabinet.orders.fill')}</Subtitle2>
                    <Body1>{t('cabinet.infoModal.fill')}</Body1>
                </Box>
                <Box>
                    <Subtitle2>
                        {t('cabinet.infoModal.priorAuth.subtitle')}
                    </Subtitle2>
                    <Body1>
                        {t('cabinet.infoModal.priorAuth.description')}
                    </Body1>
                </Box>
            </Stack>
        </Modal>
    );
};
