import {
    Body1,
    Flex,
    IconControlledSubstance,
    Subtitle2,
} from '@phx/design-system';
import { useFlags } from '@phx/feature-flags/client';
import { useTranslation } from 'react-i18next';

import type {
    ControlledSubstanceCode,
    ProductInfoFragment,
} from '../../../graphql/generated/graphql';
import DrugDetails from '../../common/DrugDetails';

type PatientProductDetailsProps = {
    product: ProductInfoFragment;
    productDescription?: string | null;
    quantity?: number | null;
    controlledSubstanceCode?: ControlledSubstanceCode | null;
};

export const ProductDetails = ({
    product,
    productDescription,
    quantity,
    controlledSubstanceCode,
}: PatientProductDetailsProps) => {
    const { t } = useTranslation();
    const { drugDetailsSplitEnabled } = useFlags();
    return drugDetailsSplitEnabled ? (
        <>
            <Subtitle2>{product.name}</Subtitle2>
            <DrugDetails product={product} quantity={quantity} />
        </>
    ) : (
        <>
            <Flex gap="xxs">
                <Subtitle2>
                    {productDescription || product.description}
                </Subtitle2>
                {controlledSubstanceCode ? <IconControlledSubstance /> : null}
            </Flex>
            {quantity != null || product.modeQuantity != null ? (
                <Body1>
                    {t('orderDetails.quantity', {
                        quantity: quantity ?? product.modeQuantity,
                    })}
                </Body1>
            ) : null}
        </>
    );
};
