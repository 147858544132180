import {
    Body1,
    Center,
    ElevatedCard,
    HorizontalDivider,
    Stack,
    Subtitle1,
} from '@phx/design-system';
import { NoFamilyIllustration } from '@phx/myphx-lib';
import { useTranslation } from 'react-i18next';

import { CareCircleFooter } from './CareCircleFooter';

export const EmptyCareCircle = () => {
    const { t } = useTranslation();

    return (
        <ElevatedCard>
            <Stack gap="md">
                <Center>
                    <NoFamilyIllustration size="sm" />
                </Center>
                <Stack gap="xxs">
                    <Subtitle1 ta="center">
                        {t('careCircle.empty.heading')}
                    </Subtitle1>
                    <Body1 ta="center">{t('careCircle.empty.desc')}</Body1>
                </Stack>
                <HorizontalDivider />
                <CareCircleFooter />
            </Stack>
        </ElevatedCard>
    );
};
