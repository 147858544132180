import { useTranslation } from 'react-i18next';
import {
    Navigate,
    createSearchParams,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';

import { DrugFamilyHeaderV2 } from '../../components/common/DrugFamilyHeaderV2';
import { OfferSelection } from '../../components/provider-offers';
import { getFragment } from '../../graphql';
import { GetProviderOffersDocument } from '../../graphql/generated/graphql';
import { QueryLoader } from '../../loaders';
import { getAbsoluteRoute } from '../../util';

export const MedicationOfferSelectionRoute = () => {
    const { providerOffersId } = useParams();
    const { t } = useTranslation();

    const [searchParams] = useSearchParams();
    const quantityInput = searchParams.get('qInput');
    const quantity = searchParams.get('quantity');

    const navigate = useNavigate();

    const Loader = QueryLoader<typeof GetProviderOffersDocument>;

    if (!providerOffersId) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    return (
        <Loader
            query={GetProviderOffersDocument}
            variables={{
                id: providerOffersId,
            }}
            render={(data) => {
                const providerOffers = getFragment(data.providerOffers);

                const medication =
                    providerOffers?.medication.__typename === 'Medication' &&
                    getFragment(providerOffers?.medication);

                if (providerOffers && medication) {
                    const chosenQuantity =
                        quantityInput ??
                        quantity ??
                        String(medication.defaultQuantity);

                    const newSearchParams = createSearchParams({
                        qInput: chosenQuantity,
                    }).toString();

                    const onSelect = (selectedOfferId: string) =>
                        navigate({
                            pathname: getAbsoluteRoute('transfer.root', {
                                offerId: selectedOfferId,
                                providerOffersId: providerOffersId,
                            }),
                            search: newSearchParams,
                        });

                    const buttonText = t(
                        'offerSelection.button.reviewOverview'
                    );

                    return providerOffers.offers.length > 1 ? (
                        <OfferSelection
                            providerOffers={providerOffers}
                            header={
                                <DrugFamilyHeaderV2 medication={medication} />
                            }
                            onSelect={onSelect}
                            buttonText={buttonText}
                        />
                    ) : (
                        <Navigate
                            to={{
                                pathname: getAbsoluteRoute('transfer.root', {
                                    providerOffersId,
                                    offerId: getFragment(
                                        providerOffers.offers[0]
                                    ).id,
                                }),
                                search: newSearchParams,
                            }}
                        />
                    );
                }

                return <Navigate to={getAbsoluteRoute('error')} />;
            }}
        />
    );
};
