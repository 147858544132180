import { Box, Flex, Stack } from '@phx/design-system';
import { CoverageCard } from '@phx/myphx-lib';

import { useCoverageCardData } from '../../hooks/use-coverage-card-data';
import { useSwipeableViews } from '../../hooks/use-swipeable-views';
import { CobrandedLogo } from '../cobranding/CobrandedLogo';
import { BenefitCardDetails } from '../plan-cards/BenefitCardDetails';
import { PlanCardsCarousel } from '../plan-cards/PlanCardsCarousel';
import { SmartpriceCardDetails } from '../plan-cards/SmartpriceCardDetails';

export const PlanCardsSection = () => {
    const coverages = useCoverageCardData();
    const useSwipe = useSwipeableViews(coverages);

    const planCardData = coverages.find(
        (coverage) => coverage.variant === 'benefit'
    );
    const smartpriceCardData = coverages.find(
        (coverage) => coverage.variant === 'smartprice'
    );

    if (useSwipe) {
        return <PlanCardsCarousel />;
    }

    return (
        <Flex gap="lg">
            {planCardData && (
                <Stack gap="xl" flex={1}>
                    <Box>
                        <CoverageCard
                            {...planCardData}
                            partnerLogo={<CobrandedLogo />}
                        />
                    </Box>
                    <BenefitCardDetails />
                </Stack>
            )}
            {smartpriceCardData && (
                <Stack gap="xl" flex={1}>
                    <Box>
                        <CoverageCard
                            {...smartpriceCardData}
                            partnerLogo={<CobrandedLogo />}
                        />
                    </Box>
                    <SmartpriceCardDetails />
                </Stack>
            )}
        </Flex>
    );
};
