import { getFragment } from '../graphql';
import {
    type GetBasicPrescriberOrdersByPatientQuery,
    type GetBasicPrescriberOrdersQuery,
    type PriorAuthorizationInfoFragment,
} from '../graphql/generated/graphql';
import type { BasicPrescriberOrderFragment } from '../util/cabinet/prescriber-orders';
import {
    isBasicPrescriberOrder,
    isNewOrder,
    sortOrdersByDate,
} from '../util/orders';

export type PrescriberOrdersFilter =
    | 'all'
    | 'new-all'
    | 'new-by-patient'
    | 'unclaimed';

export type UsePrescriberOrdersProps = {
    patient:
        | NonNullable<GetBasicPrescriberOrdersQuery['patient']>
        | NonNullable<GetBasicPrescriberOrdersByPatientQuery['patient']>;
    patientId: string;
    primaryPatientId: string;
    nonDrxPriorAuthorizations?: PriorAuthorizationInfoFragment[];
    filter?: PrescriberOrdersFilter;
};

export const usePrescriberOrders = ({
    patient,
    patientId,
    primaryPatientId,
    nonDrxPriorAuthorizations,
    filter = 'all',
}: UsePrescriberOrdersProps) => {
    const isPrimaryPatient = patientId === primaryPatientId;
    const hasUnclaimedConnection =
        'unclaimedPrescriberOrdersConnection' in patient;
    const hasConsentedConnection = 'consentedPatientsConnection' in patient;

    const medications: (
        | BasicPrescriberOrderFragment
        | PriorAuthorizationInfoFragment
    )[] = [];

    // Only show unclaimed prescriptions for the primary patient
    if (isPrimaryPatient && hasUnclaimedConnection) {
        patient.unclaimedPrescriberOrdersConnection.edges.forEach((edge) => {
            if (edge.node.__typename) {
                medications.unshift(getFragment(edge.node));
            }
        });
    }

    if (filter === 'unclaimed') return sortOrdersByDate(medications);

    patient.prescriberOrdersConnection.edges.forEach((edge) => {
        medications.push(getFragment(getFragment(edge.node)));
    });

    if (isPrimaryPatient && hasConsentedConnection) {
        patient.consentedPatientsConnection.edges.forEach((patientEdge) => {
            patientEdge.node.prescriberOrdersConnection.edges.forEach(
                (edge) => {
                    medications.push(getFragment(getFragment(edge.node)));
                }
            );
        });
    }

    // Add non-drx Prior Authorizations to the medications array
    nonDrxPriorAuthorizations?.forEach((priorAuth) => {
        medications.push(priorAuth);
    });

    if (filter === 'all') return sortOrdersByDate(medications);

    const newMeds = medications.filter(isNewOrder);

    if (filter === 'new-by-patient') {
        const filteredNewMeds = newMeds.filter(
            (m) =>
                isBasicPrescriberOrder(m) &&
                'status' in m &&
                m.patient.id === patientId
        );
        return sortOrdersByDate(filteredNewMeds);
    }

    return sortOrdersByDate(newMeds);
};
