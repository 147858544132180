import { Accordion, Body1, Stack, Subtitle2 } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import {
    type MailOrderProviderInfoFragment,
    type PharmacyProviderInfoFragment,
    type PriorAuthorizationInfoFragment,
    PriorAuthorizationSource,
} from '../../../graphql/generated/graphql';
import { ConditionalComponent } from '../../utility/ConditionalComponent';

import { PriorAuthLocationDetails } from './PriorAuthLocationDetails';
import { PriorAuthTimeline } from './PriorAuthTimeline';

type PriorAuthAccordionProps = {
    validFromDate?: string | null;
    validToDate?: string | null;
    priorAuthInfo: PriorAuthorizationInfoFragment;
    location?: PharmacyProviderInfoFragment | MailOrderProviderInfoFragment;
    isApproved: boolean;
    isDenied: boolean;
};

/**
 * This component is displayed when the currentStatus is approved or denied. It consists of a combination of Decision information, PA status details, and/or Pharmacy information
 */
export const PriorAuthAccordion = ({
    validFromDate,
    validToDate,
    priorAuthInfo,
    location,
    isApproved,
    isDenied,
}: PriorAuthAccordionProps) => {
    const { t } = useTranslation();

    // NOTE: When a status is denied, we don't want the user to focus on pharmacy location but rather denial decision and recommendations, so pharmacy location info will be available via collapsed accordion item
    const showPharmacyInfo = location && isDenied;

    const isNonDrxSpecialtyPriorAuth =
        priorAuthInfo.isSpecialty &&
        priorAuthInfo.requestOrigin ===
            PriorAuthorizationSource.VendorInitiated;

    const items = [
        isApproved
            ? {
                  header: t('priorAuthorization.decisionInformation.header'),
                  key: 'decision-information',
                  children: (
                      <Stack gap="sm">
                          <ConditionalComponent
                              condition={!!(validFromDate && validToDate)}
                          >
                              <Stack gap="xxs">
                                  <Subtitle2>
                                      {t(
                                          'priorAuth.decisionDetails.modal.approvalTimeframe'
                                      )}
                                  </Subtitle2>
                                  <Body1>
                                      {t(
                                          'priorAuth.decisionDetails.modal.validDate',
                                          {
                                              validFromDate,
                                              validToDate,
                                          }
                                      )}
                                  </Body1>
                              </Stack>
                          </ConditionalComponent>
                          <Body1>{t('priorAuth.decisionInfo.footer')}</Body1>
                      </Stack>
                  ),
              }
            : null,
        {
            header: t('priorAuthorization.paStatusDetails.header'),
            key: 'pa-status-details',
            children: <PriorAuthTimeline priorAuthInfo={priorAuthInfo} />,
        },

        showPharmacyInfo
            ? {
                  header: t('priorAuthorization.pharmacyInformation.header'),
                  key: 'pa-pharmacy-information',
                  children: (
                      <PriorAuthLocationDetails
                          isNonDrxSpecialtyPriorAuth={
                              isNonDrxSpecialtyPriorAuth
                          }
                          isApproved={isApproved}
                          location={location}
                      />
                  ),
              }
            : null,
    ].filter((item) => item !== null);

    return <Accordion items={items} />;
};
