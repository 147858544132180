import { Body1, Flex, Stack, Subtitle1, TextInput } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import { useAcceptInviteContext } from '../../hooks/care-circle/use-accept-invite-context';
import { ConditionalComponent } from '../utility/ConditionalComponent';

import { AcceptInviteButton } from './AcceptInviteButton';
import { DeclineInviteButton } from './DeclineInviteButton';

export const AcceptInviteForm = () => {
    const { form, isAccepting, isDeclining, relatedCareRecipient } =
        useAcceptInviteContext();

    const { t } = useTranslation();

    const preventSubmit = !form.isValid();

    return (
        <>
            <ConditionalComponent condition={!relatedCareRecipient}>
                <Stack gap="md">
                    <Stack gap="xs">
                        <Subtitle1>
                            {t('careCircle.invite.respond.form.subtitle')}
                        </Subtitle1>
                        <Body1>
                            {t('careCircle.invite.respond.form.desc')}
                        </Body1>
                    </Stack>
                    <Flex gap="sm">
                        <TextInput
                            required
                            disabled={isAccepting || isDeclining}
                            flex={1}
                            label={t('common.formFields.firstName')}
                            key={form.key('firstName')}
                            {...form.getInputProps('firstName')}
                        />
                        <TextInput
                            required
                            disabled={isAccepting || isDeclining}
                            flex={1}
                            label={t('common.formFields.lastName')}
                            key={form.key('lastName')}
                            {...form.getInputProps('lastName')}
                        />
                    </Flex>
                    <TextInput
                        required
                        disabled={isAccepting || isDeclining}
                        key={form.key('birthDate')}
                        label={t('common.formFields.dob')}
                        placeholder="mm/dd/yyyy"
                        mask="00/00/0000"
                        {...form.getInputProps('birthDate')}
                    />
                </Stack>
            </ConditionalComponent>
            <AcceptInviteButton disabled={preventSubmit} />
            <DeclineInviteButton />
        </>
    );
};
