import { useDobZodSchema, useForm } from '@phx/design-system/hooks';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import type { PendingCareRecipientDetails } from '../../components/care-circle/care-circle-types';
import type { PatientInfoFragment } from '../../graphql/generated/graphql';
import { formatDateString } from '../../util';

export const useAcceptInviteForm = (
    relatedCareRecipient: PatientInfoFragment | undefined
) => {
    const { t } = useTranslation();
    const dobSchema = useDobZodSchema();

    return useForm<PendingCareRecipientDetails>({
        mode: 'controlled',
        initialValues: {
            birthDate: relatedCareRecipient?.dateOfBirth
                ? formatDateString(relatedCareRecipient.dateOfBirth, 'en-US', {
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                  })
                : '',
            firstName: relatedCareRecipient?.firstName ?? '',
            hasPermission: false,
            lastName: relatedCareRecipient?.lastName ?? '',
        },
        schema: z.object({
            birthDate: dobSchema,
            firstName: z.string().min(2, {
                message: t('common.formErrors.name.required'),
            }),
            hasPermission: z.literal<boolean>(true),
            lastName: z.string().min(2, {
                message: t('common.formErrors.name.required'),
            }),
        }),
    });
};
