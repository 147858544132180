import { Anchor, Body1, Modal } from '@phx/design-system';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import externalRoutes from '../../../external-routes';

type PARequiredModalProps = {
    opened: boolean;
    close: () => void;
};

export const PARequiredModal = ({ opened, close }: PARequiredModalProps) => {
    return (
        <Modal
            opened={opened}
            onClose={close}
            title={t('common.drugCoverageAlerts.paRequired.modalTitle')}
        >
            <Body1>
                <Trans
                    i18nKey="common.drugCoverageAlerts.paRequired.modalDescriptionOne"
                    components={{
                        1: <Anchor href={externalRoutes.priorAuthForm} />,
                    }}
                />
            </Body1>
        </Modal>
    );
};
