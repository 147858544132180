import { useQuery } from '@apollo/client';
import { useTelemetryContext } from '@phx/instrumentation/react';
import { useGeolocation } from '@phx/location-utils';
import { Navigate, useParams } from 'react-router-dom';

import { Alternatives } from '../../../components/cabinet/prescription/alternatives/Alternatives';
import { getFragment } from '../../../graphql';
import {
    GetAltProductOffersDocument,
    GetPrescriberOrderOffersV2Document,
} from '../../../graphql/generated/graphql';
import { useNumericSearchParam } from '../../../hooks/use-numeric-search-param';
import { usePatientContext } from '../../../hooks/use-patient-context';
import { Loader, QueryLoader } from '../../../loaders';
import { getAbsoluteRoute, getPrimaryProvider } from '../../../util';
import { getAltProducts } from '../../../util/alternatives/get-alt-products';
import { getCheapestOffer } from '../../../util/alternatives/get-cheapest-offer';
import { getFeaturedProviderOffers } from '../../../util/alternatives/get-featured-provider-offers';

import { usePrescriptionInputVariables } from './use-prescription-variables';

export const AlternativesRoute = () => {
    const { prescriptionId } = useParams();
    const { scopedPatientId } = usePatientContext();
    const { geolocation } = useGeolocation();
    const { telemetryInstance } = useTelemetryContext();

    const distance = useNumericSearchParam('within');

    const input = usePrescriptionInputVariables(distance, scopedPatientId);
    const variables = {
        prescriberOrderId: prescriptionId ?? '',
        input,
    };
    const { data: offerData, loading } = useQuery(
        GetPrescriberOrderOffersV2Document,
        { variables, skip: !prescriptionId }
    );

    if (!prescriptionId) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    if (loading) {
        return <Loader />;
    }
    if (
        offerData?.prescriberOrderV2?.__typename === 'UnclaimedPrescriberOrder'
    ) {
        return (
            <Navigate
                to={getAbsoluteRoute('cabinet.rxId.verifyPatient', {
                    prescriptionId: prescriptionId!,
                })}
            />
        );
    }

    if (
        offerData?.prescriberOrderV2?.__typename !==
        'DigitallyReceivedPrescriberOrder'
    ) {
        telemetryInstance.logError({
            source: 'prescription-route',
            error: new Error(
                'Cannot get alternatives for a non-digital prescription'
            ),
        });

        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    const offersConnection = getFragment(
        offerData.prescriberOrderV2.offersConnection
    );

    const featuredOffer = getFeaturedProviderOffers(offersConnection);
    if (!featuredOffer) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    return (
        <QueryLoader
            query={GetAltProductOffersDocument}
            variables={variables}
            render={(data) => {
                if (data.prescriberOrder) {
                    const { providerOffers } = featuredOffer;

                    if (
                        providerOffers.product.__typename === 'UnknownProduct'
                    ) {
                        return <Navigate to={getAbsoluteRoute('error')} />;
                    }

                    const cheapestOffer = getCheapestOffer(providerOffers);
                    const { products } = getAltProducts(
                        data.prescriberOrder?.fillRecommendationsConnection,
                        cheapestOffer,
                        getFragment(cheapestOffer.pharmacyProvider).id
                    );

                    return (
                        <Alternatives
                            products={products}
                            patient={getFragment(providerOffers.patient)}
                            product={getFragment(providerOffers.product)}
                            prescriberOrder={getFragment(
                                providerOffers.prescriberOrder
                            )}
                            pharmacyName={
                                getPrimaryProvider(providerOffers, geolocation)
                                    ?.name ?? ''
                            }
                            cheapestOffer={cheapestOffer}
                        />
                    );
                }

                return <Navigate to={getAbsoluteRoute('home')} />;
            }}
        />
    );
};
