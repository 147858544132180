import { Box, Button, IconWarning } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import type { PriorAuthorizationInfoFragment } from '../../../graphql/generated/graphql';
import { usePriorAuthorizationDetails } from '../../../hooks/use-prior-authorization-details';
import type { BasicPrescriberOrderFragment } from '../../../util/cabinet/prescriber-orders';

type MedicationOrderStateDisplayProps = {
    isUnclaimed: boolean;
    verifyOrder: () => void;
    order: BasicPrescriberOrderFragment | PriorAuthorizationInfoFragment;
    fillOrder: () => void;
};

export const MedicationOrderStateDisplay = ({
    isUnclaimed,
    verifyOrder,
    order,
    fillOrder,
}: MedicationOrderStateDisplayProps) => {
    const { t } = useTranslation();
    const { isPriorAuthorization } = usePriorAuthorizationDetails(order);

    return (
        <Box>
            {isUnclaimed ? (
                <Button
                    variant="outline"
                    onClick={verifyOrder}
                    size="sm"
                    data-testid="medication-entry-verify-button"
                >
                    {t('cabinet.orders.verify')}
                </Button>
            ) : order.__typename === 'DigitallyReceivedPrescriberOrder' ? (
                order?.applicablePriorAuthorization ? null : (
                    <Button
                        variant="contained"
                        onClick={fillOrder}
                        size="sm"
                        data-testid="medication-entry-fill-button"
                    >
                        {t('cabinet.orders.fill')}
                    </Button>
                )
            ) : isPriorAuthorization ? (
                <IconWarning color="warning" />
            ) : null}
        </Box>
    );
};
