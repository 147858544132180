import { type PropsWithChildren as PWC, createContext, useState } from 'react';

export type LoadingContextType = {
    isLoading: boolean;
    setIsLoading: (loading: boolean) => void;
};

export const LoadingContext = createContext<LoadingContextType>({
    isLoading: true,
    setIsLoading: () => {},
});

export const LoadingProvider = ({ children }: PWC) => {
    const [isLoading, setIsLoading] = useState(true);

    return (
        <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
            {children}
        </LoadingContext.Provider>
    );
};
