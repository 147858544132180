import { useQuery } from '@apollo/client';
import { useAuthContext } from '@phx/auth/client';
import { Overlay, useConditionalBackOverride } from '@phx/design-system';
import { useTelemetryContext } from '@phx/instrumentation/react';
import { useGeolocation } from '@phx/location-utils';
import { useState } from 'react';
import {
    Navigate,
    createSearchParams,
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';

import { ShoppingHeader } from '../../components/cabinet/shopping-header/ShoppingHeader';
import { DrugFamilyHeaderV2 } from '../../components/common/DrugFamilyHeaderV2';
import { MedicationConfiguration } from '../../components/medication-configuration/MedicationConfiguration';
import { ProviderSelection } from '../../components/provider-offers';
import { getFragment, getFragments } from '../../graphql';
import {
    GetMedicationOffersDocument,
    MedicationFamilyDocument,
    type MedicationFamilyQuery,
} from '../../graphql/generated/graphql';
import { useFlags } from '../../hooks/use-flags';
import { useNumericSearchParam } from '../../hooks/use-numeric-search-param';
import { usePatientContext } from '../../hooks/use-patient-context';
import { type DataHandlerType, QueryLoader } from '../../loaders';
import {
    getAbsoluteRoute,
    getPrimaryProvider,
    getProviderOffersByType,
} from '../../util';
import { getFeaturedProviderOffers } from '../../util/alternatives/get-featured-provider-offers';
import { getOffersByProviderId } from '../../util/offers/get-offers-by-provider-id';

export const MedicationRoute = () => {
    const { typeaheadSearchEnabled } = useFlags();
    if (!typeaheadSearchEnabled) {
        return <Navigate to={getAbsoluteRoute('drug.search.root')} />;
    }

    return <RenderMedicationRoute />;
};

const RenderMedicationRoute = () => {
    const { familyId } = useParams();

    const Loader = QueryLoader<typeof MedicationFamilyDocument>;

    if (!familyId) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    // useParams automatically decodes the family id, so we need to encode again to pass in the MedicationFamily query
    const encodedFamilyId = encodeURIComponent(familyId);

    return (
        <Loader
            query={MedicationFamilyDocument}
            variables={{
                id: encodedFamilyId,
            }}
            queryOptions={{ errorPolicy: 'all' }}
            component={DataHandler}
        />
    );
};

const DataHandler: DataHandlerType<typeof MedicationFamilyDocument> = ({
    data,
}: {
    data: MedicationFamilyQuery;
}) => {
    const { isAuthenticated, signIn } = useAuthContext();
    const { telemetryInstance } = useTelemetryContext();
    const { primaryPatientId: patientId } = usePatientContext();
    const { geolocation } = useGeolocation();
    const [isDrugConfigOpen, setDrugConfigOpen] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const searchQuery = searchParams.get('q');
    const quantityInput = searchParams.get('qInput');

    const quantity = useNumericSearchParam('quantity');
    const daysSupply = useNumericSearchParam('daysSupply');
    const distance = useNumericSearchParam('within');

    const medicationFamily = getFragment(data.medicationFamilyV2);
    const defaultMedication = getFragment(medicationFamily?.defaultMedication);
    const medicationId =
        searchParams.get('medicationId') || defaultMedication?.id;

    const chosenQuantity = quantity ?? defaultMedication?.defaultQuantity;

    const { data: medicationData, loading } = useQuery(
        GetMedicationOffersDocument,
        {
            variables: {
                id: medicationId ?? '',
                input: {
                    quantity: chosenQuantity,
                    daysSupply,
                    patientId,
                    coordinatesInput: geolocation
                        ? {
                              latitude: geolocation.latitude,
                              longitude: geolocation.longitude,
                              maxResults: 50,
                              radiusMiles: distance,
                          }
                        : undefined,
                },
            },
            errorPolicy: 'all',
            skip: !medicationId,
        }
    );

    const handleBack = () => {
        if (searchQuery) {
            navigate({
                pathname: getAbsoluteRoute('drug.search.root'),
                search: createSearchParams({ q: searchQuery }).toString(),
            });
        } else {
            navigate(getAbsoluteRoute('drug.search.root'));
        }
    };

    useConditionalBackOverride(handleBack, !!searchQuery);

    if (loading) return <Overlay fullScreen loader visible />;

    const medication = getFragment(medicationData?.medication);
    const offersConnection = getFragment(
        medicationData?.medication?.offersConnection
    );

    if (!medicationFamily || !medication || !offersConnection) {
        telemetryInstance.logError({
            source: 'medication-route',
            error: new Error(
                `no data for medicationFamilyId ${medicationFamily?.id} medicationId ${medication?.id}`
            ),
        });
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    const providerOffers = offersConnection.edges.map((edge) =>
        getFragment(edge.node)
    );

    const onSelect = (providerOffersId: string, providerId?: string) => {
        if (!isAuthenticated) {
            signIn(`${location.pathname}${location.search}`);
            return;
        }

        const selectedProviderOffers = providerOffers.find(
            (offer) => offer.id === providerOffersId
        );

        const qInput = String(quantityInput ?? chosenQuantity);

        const newSearchParams = providerId
            ? createSearchParams({
                  providerId,
                  qInput,
              }).toString()
            : createSearchParams({
                  qInput,
              }).toString();

        if (selectedProviderOffers) {
            //Skip to overview if only one offer option.
            if (
                selectedProviderOffers?.provider.__typename === 'ChainProvider'
            ) {
                const selectedProviderId =
                    providerId ??
                    getPrimaryProvider(selectedProviderOffers, geolocation)?.id;

                if (selectedProviderId) {
                    const offers = getFragments(selectedProviderOffers.offers);

                    const offersForSelected = getOffersByProviderId(
                        offers,
                        selectedProviderId
                    );

                    if (offersForSelected.length <= 1) {
                        navigate({
                            pathname: getAbsoluteRoute('transfer.root', {
                                providerOffersId,
                                offerId: offersForSelected[0].id,
                            }),
                            search: newSearchParams,
                        });
                        return;
                    }
                }
            }

            if (selectedProviderOffers.offers.length <= 1) {
                const offerFragment = getFragment(
                    selectedProviderOffers.offers[0]
                );

                navigate({
                    pathname: getAbsoluteRoute('transfer.root', {
                        providerOffersId,
                        offerId: offerFragment?.id ?? 'unknown',
                    }),
                    search: newSearchParams,
                });
                return;
            }
        }

        navigate({
            pathname: getAbsoluteRoute(
                'medication.familyId.providerOffers.root',
                {
                    providerOffersId,
                    familyId: medicationFamily.id,
                }
            ),
            search: newSearchParams,
        });
    };

    const featuredProvider = getFeaturedProviderOffers(offersConnection);

    const providerOfferTypes = getProviderOffersByType(
        offersConnection,
        featuredProvider?.providerOffers.id
    );

    const controlledSubstanceCode = offersConnection.edges[0]
        ? getFragment(getFragment(offersConnection.edges[0].node)?.medication)
              ?.controlledSubstanceCode
        : null;

    const coverageFlags = offersConnection?.coverageFlags?.find(
        (flag) => flag.groupCode === 'ROOT'
    );

    return isDrugConfigOpen ? (
        <MedicationConfiguration
            medication={medication}
            medicationFamily={medicationFamily}
            closeEdit={() => setDrugConfigOpen(false)}
        />
    ) : (
        <ProviderSelection
            providerOffersByType={providerOfferTypes}
            onSelect={onSelect}
            header={
                <ShoppingHeader>
                    <DrugFamilyHeaderV2
                        controlledSubstanceCode={controlledSubstanceCode}
                        medication={medication}
                        coverageFlags={coverageFlags}
                        editClicked={() => {
                            setDrugConfigOpen(true);
                        }}
                    />
                </ShoppingHeader>
            }
            featuredProvider={featuredProvider}
        />
    );
};
