import {
    Body1,
    Flex,
    Heading1,
    IconMedication,
    IconNumbers,
    Stack,
    Subtitle2,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import { getFragment } from '../../graphql';
import type { PriorAuthorizationInfoFragment } from '../../graphql/generated/graphql';
import { ConditionalComponent } from '../utility/ConditionalComponent';

import { MissingBenefitsCard } from './components/MissingBenefitsCard';
import { PriorAuthLastActivityCard } from './components/PriorAuthLastActivityCard';

type PriorAuthorizationDetailsProps = {
    priorAuthorization: PriorAuthorizationInfoFragment;
};

export const PriorAuthorizationDetails = ({
    priorAuthorization,
}: PriorAuthorizationDetailsProps) => {
    const { t } = useTranslation();

    const patient = getFragment(priorAuthorization.patient);
    const medication = getFragment(priorAuthorization.medication);

    return (
        <Stack gap="lg">
            <Stack gap="xs">
                <Subtitle2>
                    {t('priorAuth.paForUser.header', {
                        patientName: patient.fullName,
                    })}
                </Subtitle2>
                <Heading1>{medication.name}</Heading1>
            </Stack>
            <Stack gap="sm">
                <ConditionalComponent condition={!!priorAuthorization.quantity}>
                    <Flex gap="xs">
                        <IconMedication color="secondary" />
                        <Body1>
                            {t('orderDetails.quantity', {
                                quantity: priorAuthorization.quantity,
                            })}
                        </Body1>
                    </Flex>
                </ConditionalComponent>
                <Flex gap="xs">
                    <IconNumbers color="secondary" />
                    <Body1>
                        {t('order.ndc', {
                            ndc: medication.id,
                        })}
                    </Body1>
                </Flex>
            </Stack>
            <MissingBenefitsCard />
            <PriorAuthLastActivityCard
                priorAuthorization={priorAuthorization}
            />
        </Stack>
    );
};
