import { AuthorizedOutlet } from '@phx/auth/client';
import { useEffect } from 'react';
import { Outlet, type RouteObject, useNavigate } from 'react-router-dom';

import { useFlags } from '../../hooks/use-flags';
import { getAbsoluteRoute } from '../../util';
import routes from '../app-routes';

import { TransferLocationRoute } from './TransferLocationRoute';
import { TransferRoute } from './TransferRoute';

/*
 * This is only temporary until we turn on Rx Transfer, after which it should be deleted.
 */
const RxFeatureFlagBlock = () => {
    const { rxTransferEnabled } = useFlags();
    const navigate = useNavigate();

    useEffect(() => {
        if (!rxTransferEnabled) {
            navigate(getAbsoluteRoute('home'));
        }
    }, []);

    if (!rxTransferEnabled) {
        return null;
    }

    return <Outlet />;
};

export const transferRoutes: RouteObject[] = [
    {
        path: routes.transfer.root,
        element: <AuthorizedOutlet />,
        children: [
            {
                index: true,
                element: <TransferRoute />,
            },
            {
                path: routes.transfer.location.root,
                element: <RxFeatureFlagBlock />,
                children: [
                    {
                        index: true,
                        element: <TransferLocationRoute />,
                    },
                ],
            },
        ],
    },
];
