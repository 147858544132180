import {
    Body1,
    Body2,
    Box,
    Flex,
    HorizontalDivider,
    IconChevronRight,
    OutlinedCard,
    Stack,
    Subtitle1,
    Subtitle2,
    TextButton,
} from '@phx/design-system';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ConditionalComponent } from '../../../components/utility/ConditionalComponent';
import { getFragment } from '../../../graphql';
import {
    type PriorAuthorizationInfoFragment,
    PriorAuthorizationSource,
} from '../../../graphql/generated/graphql';
import { usePriorAuthorizationStatus } from '../../../hooks/use-prior-authorization-status';
import { formatToLocalizedDate, getAbsoluteRoute } from '../../../util';

export type PriorAuthLastActivityCardProps = {
    priorAuthorization: PriorAuthorizationInfoFragment;
    prescriberOrderId?: string;
};

export const PriorAuthLastActivityCard = ({
    priorAuthorization,
    prescriberOrderId,
}: PriorAuthLastActivityCardProps) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { priorAuthLinkText, statusText } = usePriorAuthorizationStatus(
        priorAuthorization?.status
    );

    const navigateToPriorAuthStatus = useCallback(() => {
        if (prescriberOrderId) {
            navigate(
                getAbsoluteRoute(
                    'orders.prescriberOrderId.priorAuthorization',
                    {
                        prescriberOrderId,
                    }
                )
            );
        } else if (priorAuthorization.id) {
            navigate(
                getAbsoluteRoute(
                    'priorAuthorization.priorAuthorizationId.status',
                    {
                        priorAuthorizationId: priorAuthorization.id,
                    }
                )
            );
        }
    }, [prescriberOrderId, navigate, priorAuthorization]);

    const assignedPharmacyProvider =
        priorAuthorization?.assignedPharmacyProvider;
    const nonDrxPriorAuthorization =
        priorAuthorization.requestOrigin ===
        PriorAuthorizationSource.VendorInitiated;

    return (
        <OutlinedCard>
            <Stack gap="xs">
                <Subtitle1>{t('orderDetails.lastActivity')}</Subtitle1>
                <HorizontalDivider />
                <Stack gap="xxs">
                    <Flex justify="space-between">
                        <Subtitle2>
                            {t('orderDetails.paStatus', { status: statusText })}
                        </Subtitle2>
                        {priorAuthorization?.updatedAt ? (
                            <Body2>
                                {formatToLocalizedDate(
                                    new Date(priorAuthorization?.updatedAt),
                                    { dateStyle: 'medium' },
                                    i18n.language
                                )}
                            </Body2>
                        ) : null}
                    </Flex>
                    <Box>
                        <TextButton
                            RightIcon={IconChevronRight}
                            onClick={navigateToPriorAuthStatus}
                        >
                            {priorAuthLinkText}
                        </TextButton>
                    </Box>
                </Stack>
                <ConditionalComponent
                    condition={
                        !!assignedPharmacyProvider && !nonDrxPriorAuthorization
                    }
                >
                    <Stack gap="xs">
                        <Flex justify="space-between">
                            <Subtitle2>
                                {t('orderDetails.sentToPharmacy')}
                            </Subtitle2>
                            {priorAuthorization?.createdAt ? (
                                <Body2>
                                    {formatToLocalizedDate(
                                        new Date(priorAuthorization?.createdAt),
                                        { dateStyle: 'medium' },
                                        i18n.language
                                    )}
                                </Body2>
                            ) : null}
                        </Flex>
                        <Body1>
                            {getFragment(assignedPharmacyProvider)?.name}
                        </Body1>
                    </Stack>
                </ConditionalComponent>
            </Stack>
        </OutlinedCard>
    );
};
