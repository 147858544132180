import { useFlags } from '../../../hooks/use-flags';
import { formatToLocalizedDate } from '../../../util';
import { getCurrentTimeWindowStatus } from '../util/get-current-time-window-status/get-current-time-window-status';
import { isSameDay } from '../util/is-same-day/is-same-day';

export interface MaintenanceWindow {
    show: boolean;
    timeFrame?: {
        start: string | null;
        end: string | null;
    };
}

/**
 *
 * This hook encapsulates the logic for determining the maintenance window status,
 * including whether the maintenance banner should be shown and if the maintenance window
 * is currently active.
 *
 * @returns An object containing the following properties:
 * - `showBanner` {boolean}: Indicates whether the maintenance banner should be shown.
 * - `isMaintenanceActive` {boolean}: Indicates whether the maintenance is currently active.
 * - `window` {Object}: The maintenance window object containing formatted `start` and `end` times.
 * - `windowStatus` {'within' | 'past' | 'future' | 'invalid'}: The status of the current time relative to the maintenance window.
 * - `displaySameDate` {string | null}: If the start and end dates fall on the same day, this formats the date once and displays the time range within that same date
 */
export const useMaintenanceStatus = () => {
    const { myPhxMaintenanceWindow } = useFlags();
    const { show, timeFrame } = myPhxMaintenanceWindow as MaintenanceWindow;

    const timeFrameStatus = getCurrentTimeWindowStatus(
        timeFrame?.start,
        timeFrame?.end
    );

    const formatDateOptions: Intl.DateTimeFormatOptions = {
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timeZoneName: 'short',
    };
    const formattedStart =
        timeFrame?.start &&
        formatToLocalizedDate(new Date(timeFrame.start), formatDateOptions);
    const formattedEnd =
        timeFrame?.end &&
        formatToLocalizedDate(new Date(timeFrame.end), formatDateOptions);

    const sameDay = isSameDay(timeFrame?.start, timeFrame?.end);
    const shortFormatOptions: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timeZoneName: 'short',
    };

    const displaySameDate =
        sameDay && timeFrame?.end
            ? `${formattedStart} - ${formatToLocalizedDate(new Date(timeFrame.end), shortFormatOptions)}`
            : null;

    return {
        showBanner: show,
        isMaintenanceActive:
            (show && timeFrameStatus === 'within') || (show && !timeFrame),
        timeFrame: { start: formattedStart, end: formattedEnd },
        timeFrameStatus,
        displaySameDate,
    };
};
