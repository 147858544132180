import { SpendSummaryCard } from '@phx/myphx-lib';
import { useTranslation } from 'react-i18next';

import { getFragment } from '../../../graphql';
import { useFlags } from '../../../hooks/use-flags';
import { isActiveDateRange } from '../../../util/is-active-date-range';
import type { CoverageInfo } from '../PlanSummary';

type IndividualSummaryCardProps = {
    coverages: CoverageInfo[];
};

export const IndividualSummaryCard = ({
    coverages,
}: IndividualSummaryCardProps) => {
    const { memberClaimHistoryEnabled } = useFlags();
    const { t } = useTranslation();
    const personCodeMappings = {
        '01': t('member.relationship.primary'),
        '02': t('member.relationship.spouse'),
        default: t('member.relationship.dependent'),
    };

    const renderableCoverages = coverages.filter((coverage) => {
        if (!memberClaimHistoryEnabled && coverage.individualAccum) {
            return false;
        }

        return true;
    });

    const isIndividual = renderableCoverages.length === 1;

    return coverages.map((coverage, id) => {
        const active = isActiveDateRange(coverage.startDate, coverage.endDate);

        if (!memberClaimHistoryEnabled && coverage.individualAccum) return;

        let subtitle = coverage.memberRelationship;

        if (coverage.memberRelationship === t('member.relationship.self')) {
            const relation =
                personCodeMappings[
                    coverage.personCode as keyof typeof personCodeMappings
                ] || personCodeMappings.default;
            subtitle = t('planSummary.card.myself', {
                relationship: relation,
            });
        }

        if (!active) {
            subtitle = t('planSummary.card.inactive', {
                relationship: subtitle,
            });
        }

        return (
            <SpendSummaryCard
                key={`individual-accumulator-card-${id}`}
                data-testid={`individual-accumulator-card-${id}`}
                variant="individual"
                deductible={{
                    max: coverage.individualDeductibleMax ?? 0,
                    spent:
                        getFragment(coverage.individualAccum)
                            ?.towardsDeductible ?? 0,
                }}
                outOfPocket={{
                    max: coverage.individualOutOfPocketMax ?? 0,
                    spent:
                        getFragment(coverage.individualAccum)
                            ?.towardsOutOfPocketMax ?? 0,
                }}
                inactive={memberClaimHistoryEnabled ? !active : undefined}
                title={!isIndividual ? coverage.memberName : undefined}
                subtitle={subtitle}
            />
        );
    });
};
