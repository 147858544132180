import { Checkbox } from '@phx/design-system';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAcceptInviteContext } from '../../../hooks/care-circle/use-accept-invite-context';

export const PermissionCheckbox = () => {
    const { t } = useTranslation();

    const { form, isAccepting, isDeclining } = useAcceptInviteContext();

    const handleChange = useCallback(
        (isChecked: boolean) => {
            form.setFieldValue('hasPermission', isChecked);
        },
        [form]
    );

    return (
        <Checkbox
            disabled={isAccepting || isDeclining}
            onChange={handleChange}
            label={t('careCircle.invite.respond.confirm')}
        />
    );
};
