import {
    Body1,
    Flex,
    IconButton,
    IconControlledSubstance,
    Stack,
    Subtitle1,
    Subtitle2,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getFragment } from '../../../graphql';
import { getAbsoluteRoute } from '../../../util';
import { RxForUser } from '../../cabinet/rx-for-user/RxForUser';
import { DrugCoverageAlerts } from '../../common/drug-coverage-alerts/DrugCoverageAlerts';
import { ConditionalComponent } from '../../utility/ConditionalComponent';

import type { PatientProductDetailsProps } from './PatientProductDetails';

export const PatientProductDetailsHeader = ({
    patientName,
    productDescription,
    quantity,
    prescriberOrder,
    coverageFlags,
    togglePARequiredModal,
    toggleNotCoveredModal,
}: PatientProductDetailsProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const controlledSubstanceCode = getFragment(
        prescriberOrder?.authorizedMedication
    )?.controlledSubstanceCode;

    return (
        <Stack gap="xxs">
            <ConditionalComponent condition={!!patientName}>
                {prescriberOrder ? (
                    <RxForUser patient={{ fullName: patientName }} />
                ) : (
                    <Subtitle2>
                        {t('priorAuth.paForUser.header', { patientName })}
                    </Subtitle2>
                )}
            </ConditionalComponent>
            <Flex gap="xxs">
                <Subtitle1 data-testid="prescriber-order-product-details-header">
                    {productDescription}
                </Subtitle1>
                {controlledSubstanceCode ? (
                    <IconButton
                        Icon={IconControlledSubstance}
                        withStyles={false}
                        onClick={() =>
                            navigate(getAbsoluteRoute('helpCenter.medications'))
                        }
                    />
                ) : null}
            </Flex>
            <ConditionalComponent condition={!!quantity}>
                <Body1>
                    {t('orderDetails.quantity', {
                        quantity,
                    })}
                </Body1>
            </ConditionalComponent>
            <Flex>
                {coverageFlags && (
                    <DrugCoverageAlerts
                        coverageFlags={coverageFlags}
                        togglePARequiredModal={togglePARequiredModal}
                        toggleNotCoveredModal={toggleNotCoveredModal}
                    />
                )}
            </Flex>
        </Stack>
    );
};
