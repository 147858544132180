import { Notification } from '@phx/design-system';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { ConditionalComponent } from '../utility/ConditionalComponent';

export const CareCircleNotification = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const clearQueryString = useCallback(() => {
        setSearchParams({}, { replace: true });
    }, []);

    const invitedCaregiver = searchParams.get('invitedCaregiver');
    const declinedInviteFrom = searchParams.get('declinedInviteFrom');
    const acceptedInviteFrom = searchParams.get('acceptedInviteFrom');

    let notificationMessage = null;
    switch (true) {
        case !!invitedCaregiver:
            notificationMessage = t('careCircle.invite.success', {
                caregiverName: invitedCaregiver,
            });
            break;
        case !!declinedInviteFrom:
            notificationMessage = t('careCircle.invite.declined', {
                careRecipientName: declinedInviteFrom,
            });
            break;
        case !!acceptedInviteFrom:
            notificationMessage = t('careCircle.invite.accepted', {
                careRecipientName: acceptedInviteFrom,
            });
            break;
    }

    return (
        <ConditionalComponent condition={!!notificationMessage}>
            <Notification onClose={clearQueryString}>
                {notificationMessage}
            </Notification>
        </ConditionalComponent>
    );
};
