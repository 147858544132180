import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { getFragments } from '../graphql';
import type {
    MedicationFamilyInfoFragment,
    MedicationInfoFragment,
    MedicationPackageInfoFragment,
} from '../graphql/generated/graphql';
import { getTotalQuantityDescriptionV2 } from '../util';

export type UseMedicationConfigProps = {
    medicationFamily: MedicationFamilyInfoFragment;
    medication: MedicationInfoFragment;
};

export const useMedicationConfig = ({
    medicationFamily,
    medication,
}: UseMedicationConfigProps) => {
    const [customQuantity, setCustomQuantity] = useState('');
    const [selectedPackage, setSelectedPackage] = useState(medication.id);
    const [selectedStrength, setSelectedStrength] = useState(
        medication.strengthValue ?? '-'
    );
    const [selectedQuantity, setSelectedQuantity] = useState(
        medication?.defaultQuantity?.toString() ?? ''
    );
    const [daysSupply, setDaysSupply] = useState(
        medication.defaultDaysSupply ?? 30
    );
    const [medicationId, setMedicationId] = useState(medication.id);

    const inputRef = useRef<HTMLInputElement>(null);

    const [searchParams, setSearchParams] = useSearchParams();

    const { t } = useTranslation(['myphx']);

    const chosenQuantity =
        selectedQuantity === 'Custom'
            ? Number(customQuantity)
            : Number(selectedQuantity);

    const forms = getFragments(medicationFamily.forms);

    // this is a temporary work around until we implement routeOfAdministration work
    const selectedFormInfo = forms.find((form) =>
        getFragments(form.strengths).some((strength) =>
            getFragments(strength.packages).some(
                (pkg) => pkg.id === medicationId
            )
        )
    );

    const [selectedForm, setSelectedForm] = useState(selectedFormInfo);

    const strengthSortedForm = useMemo(() => {
        const formStrengths = getFragments(selectedFormInfo?.strengths);

        const sortedStrengths = formStrengths
            ?.map((strength) => strength)
            .sort((a, b) => Number(a.strengthValue) - Number(b.strengthValue));

        return {
            ...selectedFormInfo,
            strengths: sortedStrengths,
        };
    }, [selectedForm, forms]);

    const selectedStrengthQuery =
        selectedStrength === '-' ? null : selectedStrength;

    const selectedFormStrength = strengthSortedForm.strengths
        ?.flat()
        .find((strength) => strength.strengthValue === selectedStrengthQuery);

    const packageOptions = useMemo(() => {
        const packages = getFragments(selectedFormStrength?.packages);

        // Select first strength if not found
        if (!selectedFormStrength) {
            setSelectedStrength(
                strengthSortedForm?.strengths?.[0]?.strengthValue ?? '-'
            );
        }

        if (selectedFormStrength && packages) {
            const packageMap = new Map<string, MedicationPackageInfoFragment>();

            packages.forEach((pkg) => {
                packageMap.set(pkg.packageFormatDescription, pkg);
            });

            // If a selected package exists, keep it in the options list
            const selectedFragment = packages.find(
                (pkg) => pkg.id === selectedPackage
            );

            if (selectedFragment) {
                packageMap.set(
                    selectedFragment.packageFormatDescription,
                    selectedFragment
                );
            }

            return [...packageMap.values()];
        }
        return [];
    }, [selectedStrength, selectedForm, strengthSortedForm]);

    const quantityOptions = useMemo(() => {
        const packageOption = packageOptions.find(
            (option) => option.id === selectedPackage
        );

        // If no package option is selected, pick the first one
        if (!packageOption && packageOptions.length) {
            setSelectedPackage(packageOptions[0].id);
        }

        const options = packageOption ? packageOption.quantityOptions : [];

        const currentlySelectedQuantity = options.find(
            (option) => option.value === selectedQuantity
        );

        if (
            !currentlySelectedQuantity &&
            selectedQuantity !== 'Custom' &&
            options[0]?.value
        ) {
            setSelectedQuantity(options[0]?.value);
        }

        return [
            ...options,
            { value: 'Custom', displayText: t('drugConfig.customQuantity') },
        ];
    }, [packageOptions, selectedPackage, selectedForm, selectedStrength]);

    const totalQuantity = useMemo(() => {
        if (strengthSortedForm) {
            const packages = getFragments(selectedFormStrength?.packages);

            const formPackage = packages?.find(
                (pkg) => pkg.id === selectedPackage
            );

            if (formPackage) {
                return getTotalQuantityDescriptionV2(
                    chosenQuantity,
                    formPackage
                );
            }
        }

        return {
            shortText: chosenQuantity.toString(),
            formulaText: chosenQuantity.toString(),
            quantityDescriptor: t('drugConfig.quantity'),
            value: chosenQuantity,
        };
    }, [strengthSortedForm, selectedForm, selectedPackage, selectedStrength]);

    useEffect(() => {
        const quantity =
            searchParams.get('qInput') ?? searchParams.get('quantity');

        if (quantity && quantity !== selectedQuantity) {
            const option = quantityOptions.find(
                (option) => option.value === quantity
            );

            if (option?.value) {
                setSelectedQuantity(quantity);
            } else {
                let quantitySet = false;

                const packages = getFragments(selectedFormStrength?.packages);

                const packageOption =
                    strengthSortedForm &&
                    packages?.find((option) => option.id === selectedPackage);

                // Check if any of the total values match our quantity
                if (packageOption) {
                    for (const quantityOption of packageOption.quantityOptions) {
                        const total = getTotalQuantityDescriptionV2(
                            Number(quantityOption.value),
                            packageOption
                        ).value.toString();

                        if (total === quantity) {
                            setSelectedQuantity(
                                String(packageOption.defaultQuantity)
                            );
                            quantitySet = true;
                            break;
                        }
                    }
                }

                if (!quantitySet) {
                    setSelectedQuantity('Custom');
                    setCustomQuantity(quantity);
                }
            }
        }
    }, [searchParams]);

    useEffect(() => {
        if (selectedQuantity === 'Custom') {
            inputRef.current?.focus();
        }
    }, [selectedQuantity]);

    return {
        daysSupply,
        setDaysSupply,
        medicationFamily,
        medication,
        chosenQuantity,
        customQuantity,
        setCustomQuantity,
        forms,
        strengthSortedForm,
        selectedForm,
        setSelectedForm,
        packageOptions,
        quantityOptions,
        selectedQuantity,
        setSelectedQuantity,
        totalQuantity,
        selectedPackage,
        setSelectedPackage,
        selectedStrength,
        setSelectedStrength,
        searchParams,
        setSearchParams,
        medicationId,
        setMedicationId,
    };
};
