import { Center, IconInfo, TextButton } from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { useTranslation } from 'react-i18next';

import { CareCircleLearnMoreDialog } from './CareCircleLearnMoreDialog';

export const CareCircleLearnMoreButton = () => {
    const { t } = useTranslation();
    const [showDialog, { open, close }] = useDisclosure(false);

    return (
        <>
            <Center>
                <TextButton LeftIcon={IconInfo} onClick={open}>
                    {t('careCircle.footer.learnMore')}
                </TextButton>
            </Center>
            <CareCircleLearnMoreDialog isOpen={showDialog} onClose={close} />
        </>
    );
};
