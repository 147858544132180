import { formatToLocalizedDate } from './format-to-localized-date';

export const formatDateString = (
    dateString: string,
    locale: string = 'en-US',
    options: Intl.DateTimeFormatOptions = { dateStyle: 'short' }
) => {
    const date = new Date(dateString);

    return formatToLocalizedDate(date, options, locale);
};

export const formatIsoDateString = (dateString: string) => {
    const date = new Date(dateString);
    const iso = date.toISOString();

    return iso.split('T')[0];
};
