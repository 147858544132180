import { useAuthContext } from '@phx/auth/client';
import {
    Body1,
    Box,
    Button,
    Caption,
    FilledCard,
    Heading1,
    Image,
    Stack,
} from '@phx/design-system';
import { constants, useTheme } from '@phx/design-system/framework';
import { useIsMobile } from '@phx/design-system/hooks';
import { useTranslation } from 'react-i18next';

import styles from './cash-price.module.css';
import { useMaintenanceStatus } from './hooks/use-maintenance-status';

export const ShopTheCashPrice = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const isMobile = useIsMobile('md');
    const { signUp } = useAuthContext();
    const { isMaintenanceActive } = useMaintenanceStatus();

    return (
        <FilledCard color="gray">
            <Stack
                gap="xxs"
                py={{ xs: 'xxxl', md: '0' }}
                classNames={isMobile ? {} : styles}
            >
                <Stack gap="xs">
                    <Heading1>
                        {t('unauth.shopCashPrice.header', {
                            tm: constants.unicode.TRADEMARK,
                        })}
                    </Heading1>
                    <Body1>
                        {t('unauth.shopCashPrice.subtitle', {
                            tm: constants.unicode.TRADEMARK,
                        })}
                    </Body1>
                </Stack>
                <Stack align="center" justify="center">
                    <picture>
                        <source
                            srcSet="/static/img/unauth-home-page-cash-lg.png"
                            media={`(min-width: ${theme.breakpoints.md})`}
                        />
                        <Image
                            radius="sm"
                            src="/static/img/unauth-home-page-cash.jpg"
                            alt={t('unauth.shopCashPrice.altImage')}
                            fit="contain"
                            h={{ md: '320px' }}
                        />
                    </picture>
                </Stack>
                <Box mb={isMobile ? 'xxs' : undefined}>
                    <Caption>{t('unauth.shopCashPrice.disclaimer')}</Caption>
                </Box>
                <Button
                    disabled={isMaintenanceActive}
                    size="lg"
                    onClick={() => signUp()}
                >
                    {t('unauth.shopCashPrice.signUp')}
                </Button>
            </Stack>
        </FilledCard>
    );
};
