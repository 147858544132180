import { IconButton, IconInfo } from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';

import { CareCircleInfoDialog } from './CareCircleInfoDialog';

export const CareCircleInfoButton = () => {
    const [showDialog, { open, close }] = useDisclosure(false);

    return (
        <>
            <IconButton withStyles={false} Icon={IconInfo} onClick={open} />
            <CareCircleInfoDialog isOpen={showDialog} onClose={close} />
        </>
    );
};
