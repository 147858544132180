import { useQuery } from '@apollo/client';
import { type PropsWithChildren as PWC, createContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    GetCareCircleDocument,
    type PatientNetwork,
} from '../graphql/generated/graphql';
import { useFlags } from '../hooks/use-flags';
import { usePatientContext } from '../hooks/use-patient-context';
import { getAbsoluteRoute } from '../util';

import { ProfileProvider } from './ProfileProvider';

export type CareCircleCtx = {
    loading: boolean;
    network: PatientNetwork | null;
};

export const CareCircleContext = createContext<CareCircleCtx | null>(null);

export const CareCircleProvider = ({ children }: PWC) => {
    const { primaryPatientId, scopedPatientId } = usePatientContext();
    const { enableCareCircleUi } = useFlags();
    const navigate = useNavigate();

    const { data, loading, previousData } = useQuery(GetCareCircleDocument, {
        variables: {
            patientId: scopedPatientId ?? primaryPatientId,
        },
        errorPolicy: 'all',
        skip: !enableCareCircleUi,
        refetchWritePolicy: 'overwrite',
    });

    const network =
        (data?.patient?.network || previousData?.patient?.network) ?? null;

    if (!enableCareCircleUi) {
        navigate(getAbsoluteRoute('home'));
        return null;
    }

    return (
        <CareCircleContext.Provider value={{ loading, network }}>
            <ProfileProvider>{children}</ProfileProvider>
        </CareCircleContext.Provider>
    );
};
