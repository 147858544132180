/**
 * @param start - The start date in ISO-8601 string format
 * @param end - The end date in ISO-8601 string format
 * @returns - Returns true if the start and end dates fall on the same day in the specified timezone, otherwise false
 */
export const isSameDay = (start?: string | null, end?: string | null) => {
    if (!start || !end) return false;

    const startDate = new Date(start);
    const endDate = new Date(end);

    return (
        startDate.getUTCFullYear() === endDate.getUTCFullYear() &&
        startDate.getUTCMonth() === endDate.getUTCMonth() &&
        startDate.getUTCDate() === endDate.getUTCDate()
    );
};
