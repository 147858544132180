import { fieldToLowerCase } from '../util';

import { readPackageFormatDescriptionV2 } from './read-package-format-description';
import { readPackageQuantityOptionsV2 } from './read-package-quantity-options';

export const medicationPackageFields = {
    packageFormatDescription: {
        read: readPackageFormatDescriptionV2,
    },
    quantityOptions: {
        read: readPackageQuantityOptionsV2,
    },
    packageSizeUnit: { read: fieldToLowerCase },
};
