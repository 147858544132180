import { AuthorizedRoute } from '@phx/auth/client';
import { Outlet, type RouteObject } from 'react-router-dom';

import { CareCircleProvider } from '../../contexts/CareCircleProvider';
import routes from '../app-routes';

import { CareCircleRoute } from './CareCircleRoute';
import { InviteByIdRoute } from './InviteByIdRoute';
import { InviteCaregiverRoute } from './InviteCaregiverRoute';
import { SendInviteRoute } from './SendInviteRoute';

const careCircleRoutes: RouteObject[] = [
    {
        path: routes.careCircle.root,
        element: (
            <AuthorizedRoute>
                <CareCircleProvider>
                    <Outlet />
                </CareCircleProvider>
            </AuthorizedRoute>
        ),
        children: [
            {
                index: true,
                element: <CareCircleRoute />,
            },
            {
                path: routes.careCircle.invite.root,
                element: <Outlet />,
                children: [
                    {
                        index: true,
                        element: <InviteCaregiverRoute />,
                    },
                    {
                        path: routes.careCircle.invite.send.root,
                        element: <Outlet />,
                        children: [
                            {
                                index: true,
                                element: <SendInviteRoute />,
                            },
                        ],
                    },
                ],
            },
            {
                path: routes.careCircle.inviteById,
                element: <InviteByIdRoute />,
            },
        ],
    },
];

export default careCircleRoutes;
