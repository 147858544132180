const routes = {
    root: '/',
    cabinet: {
        root: 'cabinet',
        history: 'history',
        rxId: {
            root: ':prescriptionId',
            alternatives: 'alternatives',
            verify: 'verify',
            verifyPatient: 'verify-patient',
            providerOffersId: {
                root: ':providerOffersId',
                offer: {
                    root: ':offerId',
                },
            },
        },
        patientId: {
            root: ':patientId',
            digitalRx: {
                root: 'digitalrx',
                rxId: ':prescriptionId',
            },
        },
    },
    claims: {
        root: 'claims',
        history: 'history',
        claimId: ':claimId',
    },
    medication: {
        root: 'medication',
        familyId: {
            root: ':familyId',
            providerOffers: {
                root: ':providerOffersId',
            },
        },
    },
    drug: {
        root: 'drug',
        drugId: {
            root: ':drugId',
            providerOffers: {
                root: ':providerOffersId',
            },
        },
        search: {
            root: 'search',
            drugId: ':drugId',
        },
    },
    transfer: {
        root: ':providerOffersId/:offerId/transfer',
        location: {
            root: 'location',
            verify: {
                root: ':locationId',
                submitted: 'submitted',
            },
        },
    },
    error: 'error',
    forbidden: 'forbidden',
    home: 'home',
    language: 'language',
    notFound: 'not-found',
    logout: 'logout',
    orders: {
        root: 'orders',
        prescriberOrderId: {
            root: ':prescriberOrderId',
            confirmation: 'confirmation',
            details: 'details',
            priorAuthorization: 'prior-authorization',
        },
    },
    pharmacy: {
        root: 'pharmacy',
        pharmacyId: ':pharmacyId',
        favorites: 'favorites',
        inNetwork: 'in-network',
    },
    plan: {
        root: 'plan',
        summary: 'summary',
    },
    priorAuthorization: {
        root: 'prior-authorization',
        priorAuthorizationId: {
            root: ':priorAuthorizationId',
            details: 'details',
            status: 'status',
        },
    },
    profile: {
        root: 'profile',
        benefit: 'benefit',
        verify: 'verify',
    },
    careCircle: {
        root: 'care-circle',
        invite: {
            root: 'invite',
            other: 'other',
            send: {
                root: 'send',
                complete: 'complete',
            },
        },
        inviteById: 'invites/:inviteId',
        request: {
            root: 'request',
            other: 'other',
            send: {
                root: 'send',
                complete: 'complete',
            },
        },
        requestById: 'requests/:requestId',
    },
    startSaving: 'start-saving',
    loginCallback: {
        root: 'login/callback',
    },
    helpCenter: {
        root: 'help-center',
        sendrx: 'sendrx',
        whatsnew: {
            root: 'whats-new',
            //adding what's new announcements here
        },
        about: 'about',
        settings: 'account-settings',
        employer: 'employer-plan',
        prescriptionManagement: 'prescription-management',
        familyCare: 'family-care',
        medications: 'medications',
        costsAndSavings: 'costs-and-savings',
        privacySecurity: 'privacy-and-security',
    },
};

export default routes;
