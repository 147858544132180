import {
    Body1,
    ElevatedCard,
    HorizontalDivider,
    Stack,
    Subtitle1,
} from '@phx/design-system';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type {
    IndividualCareRecipient,
    PatientNetwork,
    PendingCareRecipientInvite,
} from '../../../graphql/generated/graphql';

import { CareRecipient } from './CareRecipient';
import { PendingCareRecipient } from './PendingCareRecipient';

export type CareRecipientListProps = {
    network: PatientNetwork;
};

const CardHeader = () => {
    const { t } = useTranslation();

    return (
        <Stack gap="xxs">
            <Subtitle1>{t('careCircle.recipients.heading')}</Subtitle1>
            <Body1>{t('careCircle.recipients.subHeading')}</Body1>
        </Stack>
    );
};

export const CareRecipientList = ({ network }: CareRecipientListProps) => {
    const careRecipients = useMemo(() => {
        return network.grants.filter(
            (grant) => grant.__typename === 'IndividualCareRecipient'
        ) as IndividualCareRecipient[];
    }, [network]);

    const pendingCareRecipients = useMemo(() => {
        return network.pendingGrants.filter(
            (grant) =>
                grant.__typename === 'PendingCareRecipientInvite' &&
                grant.isIncoming
        ) as PendingCareRecipientInvite[];
    }, [network]);

    if (careRecipients.length === 0 && pendingCareRecipients.length === 0) {
        return null;
    }

    return (
        <ElevatedCard header={<CardHeader />}>
            <Stack gap="sm">
                <HorizontalDivider />
                {pendingCareRecipients.map((invite) => (
                    <PendingCareRecipient key={invite.id} invite={invite} />
                ))}
                {careRecipients.map((recipient) => (
                    <CareRecipient key={recipient.id} member={recipient} />
                ))}
            </Stack>
        </ElevatedCard>
    );
};
