import {
    Avatar,
    Body1,
    Body2,
    Box,
    Flex,
    IconButton,
    IconMoreVert,
} from '@phx/design-system';
import { getInitials } from '@phx/design-system/framework';
import { useTranslation } from 'react-i18next';

import { PendingCaregiverDropdown } from '../../../components/care-circle/PendingCaregiverDropdown';
import type { PendingCareRecipientInvite } from '../../../graphql/generated/graphql';

export type PendingCaregiverProps = {
    invite: PendingCareRecipientInvite;
};

export const PendingCaregiver = ({ invite }: PendingCaregiverProps) => {
    const { t } = useTranslation();

    if (invite.isIncoming) {
        return (
            <Flex gap="xs">
                <Avatar initials={getInitials(invite.caregiverName)} />
                <Box flex={1}>
                    <Body1 bold>{invite.caregiverName}</Body1>
                    <Body2>{t('careCircle.access.requestReceived')}</Body2>
                </Box>
                <IconButton size="sm" color="gray" Icon={IconMoreVert} />
            </Flex>
        );
    }

    return (
        <Flex gap="xs">
            <Avatar initials={getInitials(invite.caregiverName)} />
            <Box flex={1}>
                <Body1 bold>{invite.caregiverName}</Body1>
                <Body2>{t('careCircle.access.invitationSent')}</Body2>
            </Box>
            <PendingCaregiverDropdown />
        </Flex>
    );
};
