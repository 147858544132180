import { HorizontalDivider, Stack } from '@phx/design-system';
import { Fragment } from 'react';

import type { PriorAuthorizationInfoFragment } from '../../../graphql/generated/graphql';
import type { BasicPrescriberOrderFragment } from '../../../util/cabinet/prescriber-orders';

import { MedicationOrder } from './MedicationOrder';

export type MedicationOrdersProps = {
    orders: (BasicPrescriberOrderFragment | PriorAuthorizationInfoFragment)[];
    showAll?: boolean;
    visibleCount?: number;
};

export const MedicationOrders = ({
    orders,
    showAll,
}: MedicationOrdersProps) => {
    return (
        <Stack gap="sm" role="list">
            {orders
                .slice(0, showAll ? orders.length : 3)
                .map((medication, idx) => {
                    return (
                        <Fragment key={medication.id}>
                            {idx > 0 && <HorizontalDivider />}
                            <MedicationOrder order={medication} />
                        </Fragment>
                    );
                })}
        </Stack>
    );
};
