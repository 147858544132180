import type { UseFormReturnType } from '@phx/design-system/hooks';
import { createContext } from 'react';

import type { PendingCareRecipientDetails } from '../../components/care-circle/care-circle-types';
import type {
    PatientInfoFragment,
    PendingCareRecipientInvite,
} from '../../graphql/generated/graphql';

export type AcceptInviteCtx = {
    accept: () => void;
    clearError: () => void;
    decline: () => void;
    error: string | null;
    form: UseFormReturnType<PendingCareRecipientDetails>;
    isAccepting: boolean;
    isDeclining: boolean;
    relatedCareRecipient: PatientInfoFragment | undefined;
    selectedInvite: PendingCareRecipientInvite | null;
    setError: (err: string) => void;
};

export const AcceptInviteContext = createContext<AcceptInviteCtx | null>(null);
