import type { CoverageFlags } from '../../../graphql/generated/graphql';

import { NotCoveredAlert } from './NotCoveredAlert';
import { PARequiredAlert } from './PARequiredAlert';

type DrugCoverageAlertsProps = {
    coverageFlags: CoverageFlags;
    toggleNotCoveredModal?: () => void;
    togglePARequiredModal?: () => void;
};

/**
 * Assumes that if `requiresPriorAuthorization` is true, then `isCovered` will be false, and vice versa.
 *
 * This relationship is based on the assumption that a coverage requiring prior authorization cannot be covered simultaneously,
 * and a covered case doesn't require prior authorization.
 *
 * However, further investigation is needed to verify if there are any edge cases or scenarios where both `requiresPriorAuthorization`
 * and `isCovered` might be true at the same time. This will help ensure the correctness of the logic and handle any special cases appropriately.
 */
export const DrugCoverageAlerts = ({
    coverageFlags,
    togglePARequiredModal,
    toggleNotCoveredModal,
}: DrugCoverageAlertsProps) => {
    return coverageFlags.requiresPriorAuthorization ? (
        <PARequiredAlert toggle={togglePARequiredModal} />
    ) : !coverageFlags.isCovered ? (
        <NotCoveredAlert toggle={toggleNotCoveredModal} />
    ) : null;
};
