import {
    PrescriberOrderStatus,
    type PriorAuthorizationInfoFragment,
    PriorAuthorizationStatus,
} from '../../graphql/generated/graphql';
import { usePriorAuthorizationDetails } from '../../hooks/use-prior-authorization-details';
import type { BasicPrescriberOrderFragment } from '../cabinet/prescriber-orders';

import { isBasicPrescriberOrder } from './is-basic-prescriber-order';

export const isNewOrder = (
    order: BasicPrescriberOrderFragment | PriorAuthorizationInfoFragment
) => {
    const { isPriorAuthorization, priorAuthorizationDetails } =
        usePriorAuthorizationDetails(order);

    if ('status' in order) {
        if (isBasicPrescriberOrder(order)) {
            return (
                order.status === PrescriberOrderStatus.New ||
                order.status === PrescriberOrderStatus.Pending
            );
        } else if (isPriorAuthorization) {
            return (
                priorAuthorizationDetails?.status ===
                    PriorAuthorizationStatus.New ||
                priorAuthorizationDetails?.status ===
                    PriorAuthorizationStatus.Received ||
                priorAuthorizationDetails?.status ===
                    PriorAuthorizationStatus.InReview ||
                priorAuthorizationDetails?.status ===
                    PriorAuthorizationStatus.Appealed ||
                priorAuthorizationDetails?.status ===
                    PriorAuthorizationStatus.Redetermination
            );
        }
    } else if (order.__typename === 'UnclaimedPrescriberOrder') {
        return true;
    }
    return false;
};
