import { Navigate, useNavigate } from 'react-router-dom';

import { displayCoveredMembers } from '../../components/claim-history/members/display-members';
import PlanSummary, {
    type CoverageInfo,
} from '../../components/plan-summary/PlanSummary';
import { getFragment } from '../../graphql';
import {
    GetPatientCoverageAccumsDocument,
    type GetPatientCoverageAccumsQuery,
} from '../../graphql/generated/graphql';
import { usePatientContext } from '../../hooks/use-patient-context';
import { type DataHandlerType, QueryLoader } from '../../loaders';
import { getAbsoluteRoute } from '../../util';
import { isActiveDateRange } from '../../util/is-active-date-range';

const PlanSummaryRouteV2 = () => {
    const Loader = QueryLoader<typeof GetPatientCoverageAccumsDocument>;
    const { primaryPatientId: patientId } = usePatientContext();

    return (
        <Loader
            query={GetPatientCoverageAccumsDocument}
            component={DataHandler}
            variables={{ patientId }}
        />
    );
};

const DataHandler: DataHandlerType<typeof GetPatientCoverageAccumsDocument> = ({
    data,
}: {
    data: GetPatientCoverageAccumsQuery;
}) => {
    const navigate = useNavigate();
    const coverageEdges: CoverageInfo[] = [];
    let planDetailsUrl;
    data.patient?.coveragesConnection.edges?.forEach((edge) => {
        const coverage = getFragment(edge.coverage);
        const coverageInfo = getFragment(coverage);
        if (isActiveDateRange(coverageInfo.startDate, coverageInfo.endDate)) {
            const plan = getFragment(edge.node);
            if (plan.__typename == 'PhxBenefitsPlan' && plan.planDetails) {
                planDetailsUrl = plan.planDetails;
            }

            const sortedMembers = displayCoveredMembers(
                coverage.coveredMembers
            );

            sortedMembers.forEach((member) => {
                const matchingCoverages =
                    member.patient.coveragesConnection.edges
                        .filter((edge) => {
                            const memberCoverage = getFragment(edge.coverage);
                            return (
                                memberCoverage.familyId ===
                                coverageInfo.familyId
                            );
                        })
                        .sort((a, b) => {
                            const coverageA = getFragment(a.coverage);
                            const coverageB = getFragment(b.coverage);

                            if (!coverageA.endDate) return 1;
                            if (!coverageB.endDate) return -1;

                            return (
                                new Date(coverageB.endDate).getTime() -
                                new Date(coverageA.endDate).getTime()
                            );
                        });

                const latestCoverage =
                    matchingCoverages.length > 0
                        ? getFragment(matchingCoverages[0].coverage)
                        : undefined;
                if (!latestCoverage) return;

                coverageEdges.push({
                    ...latestCoverage,
                    memberName: member.patient.fullName,
                    memberRelationship: member.relationship.relationshipType,
                });
            });
        }
    });

    if (!coverageEdges.length) {
        return <Navigate to="/" />;
    }

    return (
        <PlanSummary
            coverageEdges={coverageEdges}
            onClaimHistoryClick={() =>
                navigate(getAbsoluteRoute('claims.history'))
            }
            planDetailsUrl={planDetailsUrl}
        />
    );
};

export default PlanSummaryRouteV2;
