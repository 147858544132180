import { useQuery } from '@apollo/client';
import { IconPaid, IconSavings, NavLink } from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getFragment } from '../../../graphql';
import {
    GetAltProductOffersDocument,
    type ProviderOffersInfoFragment,
} from '../../../graphql/generated/graphql';
import { useNumericSearchParam } from '../../../hooks/use-numeric-search-param';
import { usePatientContext } from '../../../hooks/use-patient-context';
import { usePrescriptionInputVariables } from '../../../routes/cabinet/prescription/use-prescription-variables';
import { formatToLocalizedCurrency } from '../../../util';
import { getAltProducts } from '../../../util/alternatives/get-alt-products';
import { getCheapestOffer } from '../../../util/alternatives/get-cheapest-offer';

type AlternativesLinkProps = {
    onAltsClick: () => void;
    cheapestOffers: ProviderOffersInfoFragment | undefined;
};

export const AlternativesLink = (props: AlternativesLinkProps) => {
    const { onAltsClick, cheapestOffers } = props;
    const { prescriptionId } = useParams();
    const { scopedPatientId } = usePatientContext();
    const distance = useNumericSearchParam('within');
    const { t } = useTranslation();

    const cheapestOffer = cheapestOffers && getCheapestOffer(cheapestOffers);

    const input = usePrescriptionInputVariables(distance, scopedPatientId);
    const { data, loading } = useQuery(GetAltProductOffersDocument, {
        variables: {
            prescriberOrderId: prescriptionId ?? '',
            input,
        },
        skip: !prescriptionId,
    });

    if (loading || !data || !cheapestOffer) {
        return null;
    }

    const { products, bestPatientSavings, bestPlanSavings } = getAltProducts(
        data.prescriberOrder?.fillRecommendationsConnection,
        cheapestOffer,
        getFragment(cheapestOffer.pharmacyProvider).id
    );

    const lowPatientSavings = bestPatientSavings <= 1;
    const planSavingsThreshold = 20;

    if (
        products.length === 0 ||
        (lowPatientSavings && bestPlanSavings < planSavingsThreshold)
    ) {
        return null;
    }

    const savePlanWithAlternative =
        lowPatientSavings && bestPlanSavings >= planSavingsThreshold;

    const { savingsText, Icon } = savePlanWithAlternative
        ? {
              savingsText: t('pickYourPharmacy.altPlanSavings', {
                  value: formatToLocalizedCurrency(bestPlanSavings, {
                      maximumFractionDigits: 0,
                  }),
              }),
              Icon: IconPaid,
          }
        : {
              savingsText: t('pickYourPharmacy.altSavings', {
                  value: formatToLocalizedCurrency(bestPatientSavings, {
                      maximumFractionDigits: 0,
                  }),
              }),
              Icon: IconSavings,
          };
    return (
        <NavLink
            active
            label={savingsText}
            color={savePlanWithAlternative ? 'info' : 'saving'}
            variant="light"
            Icon={Icon}
            onClick={onAltsClick}
        />
    );
};
