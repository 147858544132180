import { Anchor, Body1 } from '@phx/design-system';
import { Trans, useTranslation } from 'react-i18next';

import { useMedicationOrderDetails } from '../../../hooks/use-medication-order-details';
import { ConditionalComponent } from '../../utility/ConditionalComponent';

import type { MedicationOrderProps } from './MedicationOrder';

export const MedicationOrderDetails = ({ order }: MedicationOrderProps) => {
    const {
        patientName,
        shouldShowPaStatus,
        status,
        navigateToPriorAuthStatus,
        isUnclaimed,
    } = useMedicationOrderDetails(order);

    const { t } = useTranslation();

    return (
        <>
            {isUnclaimed ? (
                <Body1 data-testid="medication-entry-verify-medication-text">
                    {t('cabinet.medicationOrder.verifyPatient')}
                </Body1>
            ) : patientName ? (
                <>
                    <Body1 data-testid="medication-entry-patient-name">
                        {t('cabinet.medicationOrder.forName', {
                            name: patientName,
                        })}
                    </Body1>
                    <ConditionalComponent condition={shouldShowPaStatus}>
                        <Body1>
                            <Trans
                                i18nKey="cabinet.medicationOrder.paStatus"
                                values={{
                                    status,
                                }}
                                components={{
                                    1: (
                                        <Anchor
                                            onClick={navigateToPriorAuthStatus}
                                        />
                                    ),
                                }}
                            />
                        </Body1>
                    </ConditionalComponent>
                </>
            ) : null}
        </>
    );
};
