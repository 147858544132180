import { useQuery } from '@apollo/client';
import { Stack } from '@phx/design-system';
import { useDebouncedState } from '@phx/design-system/hooks';
import { useTelemetryContext } from '@phx/instrumentation/react';
import { getPackageRelevance } from '@phx/rx-common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useSearchParams } from 'react-router-dom';

import { getFragment } from '../../graphql';
import {
    MedicationFamilyDocument,
    type MedicationInfoFragment,
} from '../../graphql/generated/graphql';
import { getTotalQuantityDescriptionV2 } from '../../util';

import { DrugSearchBarV2 } from './DrugSearchBarV2';
import { DrugSearchResultsV2 } from './DrugSearchResultsV2';

const autocompleteService = import.meta.env.VITE_AUTOCOMPLETE_URL;

type DrugSearchProps = {
    onDrugSelect: (drugId: string, searchParams?: string) => void;
    onFocus?: () => void;
    shouldAutoFocus?: boolean;
};

type DrugSearchResponse = {
    results: DrugResult[];
};

export type DrugResult = {
    name: string;
    gpidTen: string;
    brandName: string;
    genericName: string;
    displayName: string;
    gpidEights: string[];
    gpidTwelves: string[];
    rank: number;
    id: string;
};

export const DrugSearchV2 = ({
    onFocus,
    onDrugSelect,
    shouldAutoFocus,
}: DrugSearchProps) => {
    const { t } = useTranslation();
    const { telemetryInstance } = useTelemetryContext();

    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState<DrugResult[]>([]);

    const [searchParams, setSearchParams] = useSearchParams();
    const paramKey = 'q';
    const [searchInput, setSearchInput] = useDebouncedState(
        searchParams.get(paramKey) ?? '',
        250
    );

    useEffect(() => {
        handleSearch(searchInput);
    }, [searchInput]);

    const isValidInputLength = searchInput.length >= 1;

    const handleSearch = async (searchTerm: string) => {
        try {
            setLoading(true);
            const res = await fetch(
                `${autocompleteService}/search/v2?q=${searchTerm}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            const parsed = await res.json();
            const parsedRes = parsed as DrugSearchResponse;
            setResults(parsedRes.results);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const onDrugSearch = (input: string) => {
        setSearchParams({ [paramKey]: input }, { replace: true });
        setSearchInput(input);
    };

    const { refetch } = useQuery(MedicationFamilyDocument, {
        skip: true,
    });

    const fetchDefaultMedicationPackageRelevance = async (id: string) => {
        const { data } = await refetch({ id });

        const medicationFamily = getFragment(data.medicationFamilyV2);
        const medication = getFragment(medicationFamily?.defaultMedication);

        if (!medication || !medicationFamily) {
            throw new Error(
                'Medication or MedicationFamily is missing from fetchDefaultMedicationPackageRelevance'
            );
        }

        return medication;
    };

    const generateDefaultSearchParams = ({
        packageRelevance,
        medication,
        searchInput,
    }: {
        packageRelevance:
            | { isSimpleQuantity: boolean; isPackageRelevant: boolean }
            | undefined;
        medication: MedicationInfoFragment;
        searchInput: string;
    }) => {
        const { defaultDaysSupply, defaultQuantity, id } = medication;

        let initialParams: {
            medicationId: string;
            qInput?: string;
            quantity: string;
            daysSupply: string;
        } = {
            medicationId: id,
            quantity: defaultQuantity.toString(),
            daysSupply: defaultDaysSupply.toString(),
        };

        if (
            !(
                packageRelevance?.isSimpleQuantity &&
                !packageRelevance.isPackageRelevant
            )
        ) {
            const total = getTotalQuantityDescriptionV2(1, medication).value;

            initialParams = {
                medicationId: medication.id,
                qInput: '1',
                quantity: total ? total.toString() : defaultQuantity.toString(),
                daysSupply: defaultDaysSupply.toString(),
            };
        }

        const params = createSearchParams(initialParams);
        params.set('q', searchInput);

        return createSearchParams(params).toString();
    };

    const getDefaultSearchParams = async (drugId: string) => {
        try {
            const medication =
                await fetchDefaultMedicationPackageRelevance(drugId);
            const { formCode, packageCode, packageSizeUnit, packageQuantity } =
                medication;

            const packageRelevance = getPackageRelevance({
                formCode,
                packageCode,
                packageSizeUnit,
                packageQuantity,
            });

            const params = generateDefaultSearchParams({
                packageRelevance,
                medication,
                searchInput,
            });

            return params;
        } catch (err) {
            console.error(err);

            telemetryInstance.logEvent({
                name: `fetchDefaultMedicationPackageRelevance ${err}`,
                source: 'DrugSearchV2',
            });
        }
    };

    const handleDrugSelect = async (drugId: string) => {
        const defaultSearchParams = await getDefaultSearchParams(drugId);
        onDrugSelect(drugId, defaultSearchParams?.toString());
    };

    const showResults = !loading && isValidInputLength;

    return (
        <Stack gap="lg">
            <DrugSearchBarV2
                onFocus={onFocus}
                autoFocus={shouldAutoFocus}
                label={t('drugSearch.searchLabel')}
                queryValue={searchParams.get(paramKey)}
                onChange={onDrugSearch}
            />
            {showResults ? (
                <DrugSearchResultsV2
                    results={results ?? []}
                    searchInput={searchInput}
                    onDrugSelect={handleDrugSelect}
                />
            ) : null}
        </Stack>
    );
};
