import { useBlocker } from 'react-router-dom';

export type UseLocationChange = (fn: () => void) => void;

/*
 * Yes, this is weird, but the idea is that we want to perform an action when
 * the user navigates away from their current path by some arbitrary method such
 * as clicking a link in the sidebar, clicking the logo, etc. The main purpose
 * of the useBlocker hook is to allow intercepting navigation for the purpose of
 * potentially prevent it, but in this case, we use it to detect when the user
 * is leaving the screen.
 */
export const useRouteChange: UseLocationChange = (fn) => {
    useBlocker(() => {
        fn();
        return false;
    });
};
