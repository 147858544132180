import { Trans } from 'react-i18next';

import { PriorAuthorizationStatus } from '../../../graphql/generated/graphql';
import i18n from '../../../i18n';

import { AppealRedeterminationDescription } from './status-descriptions/AppealRedeterminationDescription';
import { ApprovedDescription } from './status-descriptions/ApprovedDescription';
import { CancelledDescription } from './status-descriptions/CancelledDescription';
import { DeniedDescription } from './status-descriptions/DeniedDescription';

type PriorAuthStatusDescriptionProps = {
    status: PriorAuthorizationStatus;
    trackingNumber?: string | null;
    appealBeforeDate?: string | null;
    rationale?: string | null;
    isPrescriberOrder?: boolean;
    isSpecialty?: boolean;
};

export const PriorAuthStatusDescription = ({
    status,
    trackingNumber,
    appealBeforeDate,
    rationale,
    isPrescriberOrder,
    isSpecialty,
}: PriorAuthStatusDescriptionProps) => {
    switch (status) {
        case 'NEW':
            return <Trans i18nKey="priorAuthorization.statusPage.new" />;
        case 'RECEIVED':
            return isPrescriberOrder ? (
                <Trans i18nKey="priorAuthorization.statusPage.received.drx" />
            ) : (
                <Trans i18nKey="priorAuthorization.statusPage.received.nondrx" />
            );
        case 'IN_REVIEW':
            return <Trans i18nKey="priorAuthorization.statusPage.inReview" />;
        case 'APPROVED':
        case 'APPEAL_APPROVED':
            return (
                <ApprovedDescription
                    isSpecialty={isSpecialty}
                    isPrescriberOrder={isPrescriberOrder}
                />
            );
        case 'DENIED':
        case 'APPEAL_DENIED':
            return (
                <DeniedDescription
                    trackingNumber={trackingNumber}
                    appealBeforeDate={appealBeforeDate}
                    rationale={rationale}
                />
            );
        case 'REDETERMINATION':
            return (
                <AppealRedeterminationDescription
                    trackingNumber={trackingNumber}
                    appealBeforeDate={appealBeforeDate}
                    rationale={rationale}
                />
            );
        case 'APPEALED':
            return (
                <AppealRedeterminationDescription
                    appealed
                    trackingNumber={trackingNumber}
                    appealBeforeDate={appealBeforeDate}
                    rationale={rationale}
                />
            );
        case 'CANCELLED':
            return <CancelledDescription />;
        default:
            return null;
    }
};

export function getStatusTranslation(
    status?: PriorAuthorizationStatus
): string {
    switch (status) {
        case PriorAuthorizationStatus.New:
            return i18n.t('priorAuthorization.status.New');
        case PriorAuthorizationStatus.Received:
            return i18n.t('priorAuthorization.status.Received');
        case PriorAuthorizationStatus.InReview:
            return i18n.t('priorAuthorization.status.InReview');
        case PriorAuthorizationStatus.Approved:
            return i18n.t('priorAuthorization.status.Approved');
        case PriorAuthorizationStatus.Denied:
            return i18n.t('priorAuthorization.status.Denied');
        case PriorAuthorizationStatus.Redetermination:
            return i18n.t('priorAuthorization.status.Redetermination');
        case PriorAuthorizationStatus.Appealed:
            return i18n.t('priorAuthorization.status.Appealed');
        case PriorAuthorizationStatus.AppealDenied:
            return i18n.t('priorAuthorization.status.AppealDenied');
        case PriorAuthorizationStatus.AppealApproved:
            return i18n.t('priorAuthorization.status.AppealApproved');
        case PriorAuthorizationStatus.Cancelled:
            return i18n.t('priorAuthorization.status.Cancelled');
        default:
            return '';
    }
}
