import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    DeclineInviteDocument,
    GetCareCircleDocument,
    type PendingCareRecipientInvite,
} from '../../graphql/generated/graphql';
import { getAbsoluteRoute } from '../../util';
import { usePatientContext } from '../use-patient-context';

export const useDeclineInviteMutation = (
    selectedInvite: PendingCareRecipientInvite | null,
    setError: (err: string | null) => void
) => {
    const { primaryPatientId, scopedPatientId } = usePatientContext();
    const navigate = useNavigate();

    const [declineInvite, { loading: isDeclining }] = useMutation(
        DeclineInviteDocument,
        {
            onCompleted: ({ declineCaregiverInvite }) => {
                if (declineCaregiverInvite.success) {
                    navigate({
                        pathname: getAbsoluteRoute('careCircle.root'),
                        search: new URLSearchParams({
                            declinedInviteFrom:
                                selectedInvite!.careRecipientName,
                        }).toString(),
                    });
                } else {
                    setError(declineCaregiverInvite.message);
                }
            },
            refetchQueries: [GetCareCircleDocument],
            awaitRefetchQueries: true,
        }
    );

    const decline = useCallback(() => {
        if (!selectedInvite) {
            return;
        }

        void declineInvite({
            variables: {
                inviteId: selectedInvite.id,
                caregiverId: scopedPatientId ?? primaryPatientId,
            },
        });
    }, [selectedInvite?.id, primaryPatientId, scopedPatientId]);

    return { decline, isDeclining };
};
